export const REQUEST_FAILED_PROGRAM_LOG = 'REQUEST_FAILED_PROGRAM_LOG';
export const REQUEST_PROGRAM_LOG = 'REQUEST_PROGRAM_LOG';
export const RECEIVE_PROGRAM_LOG = 'RECEIVE_PROGRAM_LOG';
export const SET_SORT_OPTIONS_PROGRAM_LOG= 'SET_SORT_OPTIONS_PROGRAM_LOG';
export const SET_PAGE_SIZE_PROGRAM_LOG = 'SET_PAGE_SIZE_PROGRAM_LOG';
export const SET_PAGE_NUMBER_PROGRAM_LOG = 'SET_PAGE_NUMBER_PROGRAM_LOG';
export const SET_FILTER_PROGRAM_LOG = 'SET_FILTER_PROGRAM_LOG';

export const REQUEST_FAILED_SYSTEM_LOG = 'REQUEST_FAILED_SYSTEM_LOG';
export const REQUEST_SYSTEM_LOG = 'REQUEST_SYSTEM_LOG';
export const RECEIVE_SYSTEM_LOG = 'RECEIVE_SYSTEM_LOG';
export const SET_SORT_OPTIONS_SYSTEM_LOG= 'SET_SORT_OPTIONS_SYSTEM_LOG';
export const SET_PAGE_SIZE_SYSTEM_LOG = 'SET_PAGE_SIZE_SYSTEM_LOG';
export const SET_PAGE_NUMBER_SYSTEM_LOG = 'SET_PAGE_NUMBER_SYSTEM_LOG';
export const SET_FILTER_SYSTEM_LOG = 'SET_FILTER_SYSTEM_LOG';
