import React from 'react';

const ContextMenu = ({ position, onClose, onSelect }) => {
    return (
        <div
        className="absolute z-40 bg-white border border-gray-300 rounded shadow-lg"
        style={{
            top: `${position?.y || 0}px`,
            left: `${position?.x || 0}px`,
        }}
        onMouseLeave={onClose}
    >
    
            <ul className="list-none m-0 p-2">
                <li
                    className=" py-1 px-2 hover:bg-gray-200 cursor-pointer "
                    onClick={() => {
                        onSelect(); // Handle selecting the file/folder
                        onClose(); // Close the menu
                    }}
                >
                   Details
                </li>
                {/* Add more options here if needed */}
            </ul>
        </div>
    );
};

export default ContextMenu;
