import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../webapplication/common/Loader";

const Recharge = () => {
    const [requestHold, setRequestHold] = useState(true); // Initially true to show the loader
    const [email, setEmail] = useState("");

    // Email validation regex
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
        return regex.test(email);
    };

    useEffect(() => {
        const currentURL = window.location.href; // Get the full URL
        const extractedEmail = currentURL.substring(currentURL.lastIndexOf("/") + 1); // Extract the part after the last '/'
        setEmail(extractedEmail);

        if (extractedEmail) {
            // Validate email
            if (!isValidEmail(extractedEmail)) {
                toast.error("Invalid email address.", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setRequestHold(false); // Stop loading if email is invalid
                return; // Skip API call if email is invalid
            }

            // Make the API call directly
            axios
                .post(
                    `${process.env.REACT_APP_HOST_NAME}/api/license/request/or/renewal`,
                    { email: extractedEmail }
                )
                .then((response) => {
                    toast.success(response?.data?.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
                .catch((error) => {
                    toast.error(
                        error?.response?.data?.message || error?.message,
                        {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                })
                .finally(() => {
                    setRequestHold(false); // Reset loading state
                });
        } else {
            setRequestHold(false); // Stop loading if no email is found
        }
    }, []); // Empty dependency array to run on mount only

    if (requestHold) return <Loader />; // Show loader while request is pending

    return (
        <>
            <Helmet>
                <title>Matisoft Website</title>
            </Helmet>
            <div className="bg-blue-100 h-screen flex justify-center items-center">
                <div>
                    {/* <h1 className="text-center text-lg font-bold">Recharge Page</h1> */}
                </div>
            </div>
        </>
    );
};

export default Recharge;
