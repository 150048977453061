import axios from 'axios';
import React, { useEffect, useId, useState } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar1 from '../../common/Sidebar1';
import { setUserId } from '../../../Redux/companyid/companyid.action';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import { toast } from 'react-toastify';
import Loader from '../../common/Loader';
import { FaInfo } from 'react-icons/fa';
import { FaCircleInfo } from "react-icons/fa6";
import Error from '../../../pages/Error';

const OrganisationSettings = () => {
    const { userAuthData, userId, permissions, errorUserAuth } = useSelector((state) => ({
        userAuthData: state.userAuth.userAuthData,
        userId: state.companyid.userId,
        permissions: state.companyid.permissions,
        errorUserAuth: state.userAuth.errorUserAuth
    }))
    const [updateData, setUpdateData] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(null);

    const [tabIndex, setTabIndex] = useState(0);
    const [deleteOrgPending, setDeleteOrgPending] = useState(false);
    const dispatch = useDispatch();
    const [isSidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };
    const [orgSettings, setOrgSettings] = useState({
        orgName: '',
        country: '',
        current_antivirus: '',
        state: ''
    });
    const handleInputChange = (e, section) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        switch (section) {

            case 'orgSettings':
                setOrgSettings((prevState) => ({
                    ...prevState,
                    [name]: newValue
                }));
                break;
            default:
                break;
        }

    }
    let userid;
    useEffect(() => {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('/');
        const fileid = null;
        userid = parts[parts?.length - 1]
        dispatch(setUserId(userid))
        // API call to fetch organisation settings
        const fetchOrgSettings = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_NAME}/api/view/user/org/${userid}`);

                setOrgSettings({
                    orgName: response?.data?.data?.name,
                    country: response?.data?.data?.country,
                    state: response?.data?.data?.state,
                    current_antivirus: response?.data?.data?.current_antivirus
                });
            } catch (error) {
                // console.error('Error fetching organisation settings:', error);
            }
        };
        // Call all three functions to prefill data
        fetchOrgSettings();
    }, [dispatch])

    // Submit handler for Organisation Settings
    const handleOrgSettingsSubmit = async (e) => {
        const payload = {
            userId: userId,
            name: orgSettings.orgName,
            country: orgSettings.country,
            current_antivirus: orgSettings.current_antivirus,
            state: orgSettings.state
        };
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/update/org`, { ...payload });
            const successMessage = response?.data?.message; // Extract the success message from the response
            toast.success(successMessage); // Show the message using toast
            setUpdateData(!updateData);
        } catch (error) {
            toast.error(error?.response?.data || error?.message);
        }
    };
    const handleOrgSettingsChange = (name, value) => {
        setOrgSettings((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleDeleteAccount = async (e) => {
        e.preventDefault(); // Prevent default form submission, if applicable

        // Check if userId is defined before making the request
        if (!userId) {
            alert('User ID is missing. Unable to delete the account.');
            return;
        }

        setDeleteOrgPending(true); // Set to true after confirmation
        try {
            // Send a POST request to delete the account
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/account/delete`, {
                userId: userId
            });

            setDeleteOrgPending(false); // Reset the loading state

            // Show success message from response
            toast.success(response?.data?.message || 'Account deleted successfully.');

            // Redirect to the home page or login page after deletion
            window.location.href = '/';
        } catch (error) {
            setDeleteOrgPending(false);
            toast.error(error?.response?.data || error?.message)
        }

    };

    if (deleteOrgPending === true) return <Loader />;
    if (errorUserAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | Account Settings</title>
            </Helmet>
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed`}>

                <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />

            </div>
            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%] z-40'} px-[1vw] py-[1vh] relative overflow-x-auto 2xl:font-[30px] licenseCreation`}>
                {/* <h1 className="text-[1.5em] font-[500] py-[2vh] border-b-[2px] border-b-[#4fd0ff] shadow-md pl-[1vw]">
                    Organisation Settings</h1> */}
                {/* <h1 className="text-[1.5em]  font-[500] py-[2vh] border-b-[2px] border-b-[#4fd0ff] shadow-md pl-[1vw]">Account Settings</h1> */}

                <div className='px-[1vw] pt-[2vh] '>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList className="flex border-b  shadow-md ">

                            <Tab className={`py-[2vh]  px-[1vw] text-[1.2em] font-[500] cursor-pointer ${tabIndex === 0 ? 'border-b-[3px]   !border-b-[#4fd0ff]' : 'text-gray-700'}`}>Organization Settings</Tab>
                            <Tab className={`py-[2vh] px-[1vw] text-[1.2em] font-[500] cursor-pointer ${tabIndex === 1 ? ' border-b-[3px]   !border-b-[#4fd0ff]' : 'text-gray-700'}`}>Delete Account</Tab>
                        </TabList>
                        <TabPanel>
                            <div>
                                <form onSubmit={handleOrgSettingsSubmit} className="py-[2vh]  pr-[10vw]">
                                    <div className='grid grid-cols-2 gap-x-[4vw] gap-y-[1vh]'>
                                        <div className="py-[2vh] flex flex-col">
                                            <label className=' font-[500] mb-2'> Organization name:</label>
                                            <input
                                                type="text"
                                                name='orgName'
                                                value={orgSettings.orgName}
                                                onChange={(e) => handleInputChange(e, 'orgSettings')}
                                                className=" px-[1vw] py-[1.2vh] border rounded-lg bg-gray-100"
                                                placeholder="Organization name"
                                                required
                                            />
                                        </div>

                                        <div className='py-[2vh] flex flex-col'>
                                            <label className='mb-2 font-[500]'>Country:</label>
                                            <CountryDropdown
                                                classes='border p-2 rounded  bg-[#f3f4f6]'
                                                value={orgSettings.country}
                                                onChange={(value) => handleOrgSettingsChange('country', value)}
                                            />
                                        </div>
                                        <div className='flex flex-col py-[2vh]'>
                                            <label className='mb-2 font-[500]'>State:</label>
                                            {/* <input type='text' name='state'
                                        value={signUpData.state} onChange={handleChange}
                                        required className='border p-2 rounded' /> */}
                                            <RegionDropdown
                                                classes='border p-2 rounded  bg-[#f3f4f6]'
                                                country={orgSettings.country}
                                                name='state'
                                                value={orgSettings.state}
                                                onChange={(value) => handleOrgSettingsChange('state', value)}
                                            />

                                        </div>
                                    </div>
                                    <button type="submit"
                                        // title={permissions[0]?.permissionLevel === 'read_write'
                                        //     ? '' // Empty title if permission is 'read_write'
                                        //     : "You don't have permissions"}
                                        // disabled={!(userAuthData?.permissions?.length > 0 && userAuthData.permissions[0]?.permissionLevel === 'read_write')
                                        // }
                                        title={!permissions.some(permission => permission.pagePath === '/cms/admin/organisation/' && permission.permissionLevel === 'read_write') && "You don't have permissions"}
                                        disabled={!permissions.some(permission => permission.pagePath === '/cms/admin/organisation/' && permission.permissionLevel === 'read_write')}
                                        className="mt-[2vh] bg-blue-500 w-[15vw] text-white py-2 px-4 rounded hover:bg-blue-600">Save Changes</button>

                                </form>

                            </div>
                        </TabPanel>
                        <TabPanel>
                            <form className="pt-[1vh]">
                                <div className={` ${showConfirmationModal ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  `} ></div>

                                <div className="py-[2vh]">
                                    <h2 className="text-[1.3em] font-semibold text-center text-[#1a1a22]  shadow-sm text-shadow">Delete Account</h2>
                                    <p className="mt-2">
                                        This operation will delete your account and all associated personal and payment data from the Matisoft's cloud platform. This operation cannot be canceled. Make sure that you want to perform this operation.
                                    </p>
                                    <div className="bg-white py-[1vh] pt-[2vh] rounded-lg ">
                                        <h2 className="text-[1.2em] font-[500] mb-4">The following information will be lost:</h2>
                                        <ul className="list-none text-gray-900 space-y-2">
                                            <li className="before:content-['➤'] before:mr-3">Order History</li>
                                            <li className="before:content-['➤'] before:mr-3">Devices</li>
                                            <li className="before:content-['➤'] before:mr-3">Reports</li>
                                            <li className="before:content-['➤'] before:mr-3">Licenses</li>
                                            <li className="before:content-['➤'] before:mr-3">Used Software</li>
                                            <li className="before:content-['➤'] before:mr-3">
                                                Licenses with Auto-Renewal Plan (will be canceled)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => setShowConfirmationModal("deleteOrg")}
                                    title={!permissions.some(permission => permission.pagePath === '/cms/admin/organisation/' && permission.permissionLevel === 'read_write') && "You don't have permissions"}
                                    disabled={!permissions.some(permission => permission.pagePath === '/cms/admin/organisation/' && permission.permissionLevel === 'read_write')}
                                    className="bg-red-500 border border-red-500 font-[500] text-white py-2 px-4 rounded hover:text-red-700 hover:bg-white hover:shadow-lg"

                                >
                                    Delete Account
                                </button>
                                {showConfirmationModal === 'deleteOrg' && (
                                    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                                        <div className="modal border-[1px] border-gray-400 min-w-[30vw] " >
                                            <p className='py-[1vh] text-[1.3em]'>
                                                Are you sure you want to delete the account for <span className='text-blue-700 font-[500] text-[1.1em]'>{userAuthData?.company_name}</span>?
                                            </p>
                                            <p className='text-red-600 py-[1vh] flex flex-row gap-x-1 items-start'><FaCircleInfo className='text-[1.2em]' />  This operation will delete your account and all associated personal and payment data from the Matisoft's cloud platform.</p>
                                            <div className="modal-buttons pr-[1vw] gap-x-1 py-[2vh] flex flex-row justify-end">
                                                <button className='' onClick={handleDeleteAccount}>Yes</button>
                                                <button onClick={() => setShowConfirmationModal(null)}>No</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* <ConfirmationDialog
                                    isOpen={showConfirmation}
                                    onClose={handleCloseDialog}
                                    onConfirm={handleConfirmCancel}
                                /> */}
                            </form>
                        </TabPanel>

                    </Tabs></div>

            </div></div>
    )
}

export default OrganisationSettings;