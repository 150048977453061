

import React, { useEffect, useRef, useState, useMemo } from 'react';
// import Sidebar from '../common/Sidebar';
import '../../../css/index.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaCheck, FaCheckDouble, FaClipboardCheck, FaFilter, FaShieldVirus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { HiDocumentRemove } from 'react-icons/hi';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { ImCross } from 'react-icons/im';
import { MdDelete, MdEmail } from 'react-icons/md';
import { LuUpload } from "react-icons/lu";
import 'react-toastify/dist/ReactToastify.css';
import { LuRefreshCw } from "react-icons/lu";
import { setCompanyId, setFileId, setOrgId, setUserId } from '../../../../Redux/companyid/companyid.action';
import PageNumber from '../../../components/pagination/PageNumber';
import DataEntriesNo from '../../../components/pagination/DataEntriesNo';
import FilterSection from '../../../common/FilterSection';
import { setInstanceActionStatus } from '../../../../Redux/actionCenter/actionCenterInstance.action';
import ApplyCS from '../../../components/customSolution/ApplyCS';
import CustomSolution from '../../../components/CustomSolution';
import { BiSolidErrorCircle } from 'react-icons/bi';
import { BsShieldFillExclamation } from 'react-icons/bs';
import { setPageSize, setSortOptions, verifyUserList } from '../../../../Redux/verifyUser/verifyUser.action';
import { useSortedData } from '../../../../utils/sortUtils';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

import { toast } from 'react-toastify';
import Loader from '../../../common/Loader';
import LoaderForTab from '../../../common/LoaderForTab';
const VerifyChannelPartner = ({handleUserAction,actionStatuses,setActionStatuses,updateData,setupdateData}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const columnsVerifyUser = [
        { id: 0, name: "Status", value: "verification_status", type: 'string' },
        { id: 1, name: "Business email", value: "email", type: 'string' },

        { id: 2, name: "Organization name", value: "company_name", type: 'string' },
        { id: 3, name: "Organization size", value: "company_size", type: 'number' },

        { id: 4, name: "Channel partner type", value: "channel_partner_type", type: 'string' },
        { id: 5, name: "First name", value: "first_name", type: 'string' },
        { id: 6, name: "Last name", value: "last_name", type: 'string' },

        { id: 7, name: "Country", value: "country", type: 'string' },
        { id: 8, name: "Phone number", value: "phone", type: 'string' },

        { id: 9, name: "Created at", value: "created_at", type: 'date' },
        { id: 10, name: "Updated at", value: "updated_at", type: 'date' },
        
        { id: 11, name: "Current antivirus", value: "current_antivirus", type: 'string' },
        { id: 12, name: "Current DLP provider", value: "current_dlp_provider", type: 'string' },


    ]
    const getValueByPath = (obj, path) => {
        if (!path) return undefined;
        return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
    };
    const [openShowHide, setOpenShowHide] = useState(false);
    // const initialLocation = useSelector((state) => state.companyid.initialLocation);
    const [errorMessage, setErrorMessage] = useState(""); // Error message state

    const [showConfirmationModal, setShowConfirmationModal] = useState(null);
    const [showConfirmationModal1, setShowConfirmationModal1] = useState(null);
    const [updatedData, setUpdatedData] = useState(false);

    const {
        userId, verifyUserData, pageNumber, totalRecords, sortOrder, sortBy, filters, loading, errorAdminAuth } = useSelector((state) => ({
            userId: state.companyid.userId,
            verifyUserData: state.verifyUser.verifyUserList,
            sortOrder: state.verifyUser.sortOrder, // Removed duplicate
            pageNumber: state.verifyUser.pageNumber,
            totalRecords: state.verifyUser.totalRecords,
            sortBy: state.verifyUser.sortBy,
            filters: state.verifyUser.filters,
            loading: state.verifyUser.loading,
            errorAdminAuth: state.adminAuth.errorAdminAuth
        }));


    const getInitialVisibleColumns = () => {
        const params = new URLSearchParams(location.search);
        const visibleIds = params.get('visibleColumns');
        if (visibleIds) {
            const idsArray = visibleIds.split(',').map(Number);
            return columnsVerifyUser.filter((col) => idsArray.includes(col.id));
        }
        return columnsVerifyUser; // Default to all columns visible
    };

    // Initialize checkedColumns based on URL query parameters
    const getInitialCheckedColumns = () => {
        const params = new URLSearchParams(location.search);
        const visibleIds = params.get('visibleColumns');
        if (visibleIds) {
            const idsArray = visibleIds.split(',').map(Number);
            return columnsVerifyUser.map((col) => idsArray.includes(col.id));
        }
        return columnsVerifyUser.map(() => true); // Default to all columns checked
    };
    const [visibleColumns, setVisibleColumns] = useState(getInitialVisibleColumns);
    const [checkedColumns, setCheckedColumns] = useState(getInitialCheckedColumns);
    // const [actionStatuses, setActionStatuses] = useState({});
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    // Function to handle checkbox changes for products
    const handleCheckboxProductChange = (itemId, productId) => {
        // console.log(itemId, productId, "checkbox tracking");

        setSelectedCheckboxes((prevState) => ({
            ...prevState,
            [itemId]: {
                ...prevState[itemId],
                [productId]: !prevState[itemId]?.[productId] || false, // Toggle the checkbox value; default to false if undefined
            },
        }));
    };
   
    let newSize;
    const fieldParam = new URLSearchParams(location.search).get('field');
    const operandParam = new URLSearchParams(location.search).get('operand');
    const valueParam = new URLSearchParams(location.search).get('value');
    const excludeParam = new URLSearchParams(location.search).get('exclude');
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const showColumnParam = new URLSearchParams(location.search).get('shownColumn');

    const sortByParam = new URLSearchParams(location.search).get('sortBy');
    const sortOrderParam = new URLSearchParams(location.search).get('sortOrder');
    const sortByCol = sortByParam ? JSON.parse(sortByParam) : [];
    const sortOrderCol = sortOrderParam ? JSON.parse(sortOrderParam) : [];
    // Assuming the values are stored as JSON strings in the URL, parse them
    const field = fieldParam ? JSON.parse(fieldParam) : [];
    const operand = operandParam ? JSON.parse(operandParam) : [];
    const value = valueParam ? JSON.parse(valueParam) : [];
    const exclude = excludeParam ? JSON.parse(excludeParam) : [];
    const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
    const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];
    const columns = showColumnParam ? JSON.parse(showColumnParam) : visibleColumns
    const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
    const [currPage, setCurrPage] = useState(1);
    const [filterChange, setFilterChange] = useState();
    // Parse pageSize from URL query params

    // Set initial states based on URL query parameters

    const [actionTaken, setActionTaken] = useState();
    const [showMessage, setShowMessage] = useState();
    const [customAction, setCustomAction] = useState(false);
    useEffect(() => {
        const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
        const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
        setPageSizeFromURL(parsedSize);
        const pageNumberParam = new URLSearchParams(location.search).get('page');
        const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
        setCurrPage(parsedPage);
        // const columnParam= new URLSearchParams(location.search).get('shownColumn');
        // const parsedColumn= columnParam ? JSON.parse(columnParam) : visibleColumns;
        // setVisibleColumns(parsedColumn);
    }, [location.search]);
    // Assuming these arrays have the same length
    let filtersvalue = React.useMemo(() => {
        return {
            filters: {
                field,
                operand,
                value,
                exclude,
            },
        };
    }, [field, operand, value, exclude]);
    const [activeArrow, setActiveArrow] = useState({
        columnName: sortByCol.length > 0 ? sortByCol[0] : "created_at", // Extract first element or use default
        direction: sortOrderCol.length > 0 ? sortOrderCol[0] : -1, // Extract first element or use default
    });
    const [modalAction, setModalAction] = useState(null);
    const [selectedAlertId, setSelectedAlertId] = useState(null);
    const [comment, setComment] = useState('');

    // Function to handle opening modal for each action
    const openModal = (action, alertId) => {
        // console.log(action, "actionName");
        setModalAction(action);
        setSelectedAlertId(alertId);
    };

    const [showFilter, setShowFilter] = useState(false);

    const handleFilter = () => {
        setShowFilter(!showFilter);
    }
    const filtersValueRef = React.createRef(filtersvalue);
    const pageSizeRef = React.createRef(pageSize);

    const checkAll = (ele) => {
        setCheckedColumns(columnsVerifyUser.map(() => true)); // Mark all columns as checked
        setVisibleColumns(columnsVerifyUser);
    };
    const [showCustomWindow, setShowCustomWindow] = useState(false);
    const [showCustomApply, setShowCustomApply] = useState(false);
    const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time
    const [confirmAction, setConfirmAction] = useState(false);

    // Function to get current time
    function getCurrentTime() {
        const now = new Date();
        return now.toLocaleString();
    }

    // Function to update current time
    function updateTime() {
        setCurrentTime(getCurrentTime());
    }
    const sortedData = useSortedData(verifyUserData, sortBy, sortOrder);

    const [alerts, setAlerts] = useState([]);
    const [confirmProductWindow, setConfirmProductWindow] = useState({ id: false, products: [] });

    useEffect(() => {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('?')[0].split('/');
        const userid = parts[parts?.length - 1]
        dispatch(setUserId(userid))

        if (userid) {
            dispatch(verifyUserList(userid, pageSizeFromURL, currPage, sortBy, sortOrder, filtersvalue, 'CHANNEL_PARTNER'));
        }
        setCurrentTime(getCurrentTime());
    }, [pageNumber, pageSizeFromURL, filterChange, currentTime, updatedData, currPage,updateData]);

    useEffect(() => {

        setAlerts(verifyUserData && sortedData ? sortedData : verifyUserData ? verifyUserData : []);
    }, [verifyUserData, sortedData])

    const [checkedRows, setCheckedRows] = useState([]);

    // console.log(actionStatuses, "Action Status");

    const handleSort = (columnName, newSortOrder) => {
        setActiveArrow({
            columnName: columnName,
            direction: newSortOrder
        });

        dispatch(setSortOptions(columnName, newSortOrder));
        const searchParams = new URLSearchParams(location.search);

        // Update the sortBy and sortOrder parameters
        searchParams.set('sortBy', JSON.stringify([columnName])); // Save the sorted column
        searchParams.set('sortOrder', JSON.stringify([newSortOrder])); // Save the sort order

        // Use navigate to update the URL with the new query parameters
        navigate({
            pathname: location.pathname, // Keep the current pathname
            search: searchParams.toString(), // Apply the updated search parameters
        });
    };
  

    const customSolutionRef = useRef(null);
    const handlePageSizeChange = (event) => {
        event.preventDefault();
        newSize = parseInt(event.target.value);
        dispatch(setPageSize(newSize))
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };
    const handlePageNumberChange = (newPageNumber) => {
        setCurrPage(newPageNumber);
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };
    // Function to confirm the selected products
    const handleConfirmProductAction = (itemId) => {

        const updatedProducts = confirmProductWindow?.products?.map((product) => ({
            ...product,
            approve: selectedCheckboxes[itemId]?.[product._id] || false, // Save the updated approval status
        }));

        // Call the normal action with updated products (you can modify as per your needs)
        handleUserAction(itemId, "APPROVED", false, updatedProducts);

        // Close the popup window
        setConfirmProductWindow({ id: null, products: [] });
    };
    const handleDeleteUser = (Id) => {
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/register/request/delete`, {

            "userId": Id,
            "adminId": userId
        })
            .then(response => {

                toast.success(response?.data);
                setUpdatedData(!updatedData);

            })
            .catch(error => {

                console.error('Error:', error?.message);
                toast.error(error?.response?.data || error?.message);
            });
        setShowConfirmationModal(false);
    }
    // const filtersvalue = filterStateFromURL;
   
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [expandedStates, setExpandedStates] = useState({});

    // Function to toggle show more/less state for 'details'
    const toggleExpand = (rowIndex, colIndex) => {
        setExpandedStates((prev) => ({
            ...prev,
            [`${rowIndex}-${colIndex}`]: !prev[`${rowIndex}-${colIndex}`],
        }));
    };

    const onApplyFilters = (filters) => {
        filtersvalue = filters;
        // console.log(filters, "filternewVLUE");
        setFilterChange(filters);
        filtersValueRef.current = filters;
    }
    const [actionValues, setActionValues] = useState({});

    const updateURL = (columns) => {
        const visibleIds = columns.map((col) => col.id).join(",");
        const params = new URLSearchParams(location.search);
        params.set("visibleColumns", visibleIds);
        navigate({ search: params.toString() }, { replace: true }); // Use navigate for updating URL
    };
    // useEffect(() => {
    //     const params = new URLSearchParams(location.search);
    //     const visibleIds = params.get("visibleColumns");
    //     if (visibleIds) {
    //         const idsArray = visibleIds.split(",").map(Number);
    //         const visibleCols = columnsVerifyUser .filter((col) => idsArray.includes(col.id));
    //         setVisibleColumns(visibleCols);
    //         setCheckedColumns(columnsVerifyUser .map((col) => idsArray.includes(col.id)));
    //     } else {
    //         // Default state
    //         setVisibleColumns(columnsVerifyUser );
    //         setCheckedColumns(columnsVerifyUser .map(() => true));
    //     }
    // }, [columnsVerifyUser , location.search]);
    // Handle checkbox change
    const handleCheckboxChange = (event, index) => {
        const { checked } = event.target;
        const column = columnsVerifyUser[index];

        setCheckedColumns((prevState) => {
            const updatedCheckedColumns = [...prevState];
            updatedCheckedColumns[index] = checked;
            return updatedCheckedColumns;
        });

        setVisibleColumns((prevState) => {
            let updatedVisibleColumns = [...prevState];
            if (checked) {
                if (!updatedVisibleColumns.find((col) => col.id === column.id)) {
                    updatedVisibleColumns.push(column);
                    updatedVisibleColumns.sort((a, b) => a.id - b.id);
                }
            } else {
                updatedVisibleColumns = updatedVisibleColumns.filter((col) => col.id !== column.id);
            }

            updateURL(updatedVisibleColumns); // Update the URL with new visible columns
            return updatedVisibleColumns;
        });
    };

    // Select all columns
    const selectAllColumns = () => {
        setCheckedColumns(columnsVerifyUser.map(() => true));
        setVisibleColumns(columnsVerifyUser);
        updateURL(columnsVerifyUser); // Update URL
    };

    // Deselect all columns
    const deselectAllColumns = () => {
        setCheckedColumns(columnsVerifyUser.map(() => false));
        setVisibleColumns([]);
        updateURL([]); // Update URL
    };

    const handleRefresh = () => {

    }

    if (loading) return <LoaderForTab />;
    return (
        <div className >
            <>
                <div className='fixed h-[8vh] bottom-[79vh] z-[40]'>
                    <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row gap-x-[1%]'>

                        <div>
                            <div className='cursor-pointer hover:text-blue-600' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
                            {openShowHide && (
                                <>
                                    <div className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 "></div>
                                    <div className='showhide-section-container z-40'>
                                        <div className="modal-body filter-section-content  ">
                                            <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                                                <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                                            </button>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    {columnsVerifyUser.map((item, index) => (
                                                        <label className="cont flex flex-row gap-x-[1%] text-white" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                className="toggle-vis"
                                                                data-column={index + 1}
                                                                checked={checkedColumns[index]}
                                                                defaultChecked={item.value !== 'SHA1'}
                                                                onChange={(event) => handleCheckboxChange(event, index)}
                                                            />
                                                            <span className="checkmark"></span> <p>{item.name}</p>
                                                        </label>
                                                    ))}
                                                </div>
                                                <div></div>
                                            </div>
                                            <div className="row" style={{ marginTop: "20px" }}>
                                                <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                                                <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={` ${showFilter ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  `} onClick={handleFilter}></div>
                        <div className='flex flex-row items-center cursor-pointer hover:text-blue-500' onClick={handleFilter} >
                            <span>Filters &nbsp;</span>
                            <FaFilter className={` ${filtersvalue.filters.field.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer `} />
                            {filtersvalue.filters.field.length > 0 && <span>&nbsp; {filtersvalue.filters.field.length} applied</span>}
                        </div>

                        {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsVerifyUser} onApplyFilters={onApplyFilters} />}

                        <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCw /></div>
                        <div>{`Last Updated at : ${currentTime}`}</div>
                    </div>
                    <div className='flex flex-row justify-between items-center py-[0.5vh] w-[80vw] border-b-1 border-b-gray-500'>
                        <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
                        <PageNumber
                            pageSize={pageSizeFromURL}
                            currPage={currPage}
                            totalRecords={totalRecords}
                            handlePageNumberChange={handlePageNumberChange}
                        />
                    </div>
                </div>
                {/* comple1   */}
                <div className="overflow-auto" style={{ marginTop: '24vh', width: '100%', height: '76vh' }}>
                    <table className="w-full table-fixed">
                        {/* Table Head */}
                        <thead className="sticky top-0 bg-blue-200 text-[0.9em] z-20">
                            <tr className="text-sm font-semibold text-gray-800">
                                <th className="py-2 px-3 min-w-[280px] w-[280px] text-center">Take Action</th>

                                {visibleColumns.map((column, index) => (
                                    <th key={index} className="py-2 px-3 min-w-[280px] w-[280px] text-center">
                                        <div
                                            className="flex items-center justify-center gap-x-1 cursor-pointer"
                                            onClick={() => handleSort(column.value, activeArrow.columnName === column.value ? -activeArrow.direction : 1)}
                                        >
                                            <span>{column.name}</span>
                                            <p>
                                                 <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                                                                                                   <FaLongArrowAltUp title='Ascending' onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                                                                                                   <FaLongArrowAltDown title='Descending' onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                                                                                               </span>
                                            </p>
                                        </div>
                                    </th>
                                ))}

                                {/* <th className="py-2 px-3 min-w-[200px] w-[200px] text-center">Delete</th> */}
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="text-sm text-gray-800 border border-b-1 border-b-gray-300">
                            {sortedData.length > 0 ? (
                                sortedData.map((item, indexVal) => (
                                    <tr key={indexVal} className={`${indexVal % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {/* Action Column */}
                                        <td className="py-2 px-3 w-[280px]">
                                            <div className="w-fit mx-auto">
                                                <div className="flex flex-row justify-center items-center gap-x-2 relative mx-auto">
                                                    {/* Action Button Component */}
                                                    {[
                                                        {
                                                            title: "Approve",
                                                            icon: FaCheck,
                                                            onClick: () => setConfirmProductWindow({ id: item?._id, products: item?.products }),
                                                            condition:
                                                                actionStatuses[item?._id]?.actionStatus === "pending" ||
                                                                item?.registration_completed ||
                                                                ["REJECTED", "APPROVED"].includes(item?.verification_status),
                                                            loadingCondition:
                                                                actionStatuses[item?._id]?.actionStatus === "pending" &&
                                                                actionStatuses[item?._id]?.action === "APPROVED",
                                                        },
                                                        {
                                                            title: "Reject",
                                                            icon: ImCross,
                                                            onClick: () => handleUserAction(item?._id, "REJECTED", false, item?.products),
                                                            condition:
                                                                item?.registration_completed ||
                                                                item?.verification_status === "REJECTED" ||
                                                                actionStatuses[item?._id]?.actionStatus === "pending",
                                                            loadingCondition:
                                                                actionStatuses[item?._id]?.actionStatus === "pending" &&
                                                                actionStatuses[item?._id]?.action === "REJECTED",
                                                        },
                                                        {
                                                            title: "Delete",
                                                            icon: MdDelete,
                                                            onClick: () => setShowConfirmationModal(indexVal),
                                                            condition: actionStatuses[item?._id]?.actionStatus === "pending",
                                                            loadingCondition:
                                                                actionStatuses[item?._id]?.actionStatus === "pending" &&
                                                                actionStatuses[item?._id]?.action === "DELETED",
                                                        },
                                                        {
                                                            title: "Resend Email",
                                                            icon: MdEmail,
                                                            onClick: () => handleUserAction(item?._id, item?.verification_status, true),
                                                            condition:
                                                                actionStatuses[item?._id]?.actionStatus === "pending" ||
                                                                item?.registration_completed ||
                                                                ["REJECTED", "PENDING"].includes(item?.verification_status),
                                                            loadingCondition:
                                                                actionStatuses[item?._id]?.actionStatus === "pending" &&
                                                                actionStatuses[item?._id]?.action === "RESENDEMAIL",
                                                        },
                                                    ].map(({ title, icon: Icon, onClick, condition, loadingCondition }, idx) => (
                                                        <div
                                                            key={idx}
                                                            title={title}
                                                            className={`${title === 'Reject' && 'text-[0.8em]'} w-[30px] text-blue-700 hover:scale-150 transition-transform duration-300 ${condition ? "pointer-events-none opacity-50" : "cursor-pointer"
                                                                }`}
                                                            onClick={onClick}
                                                        >
                                                            {loadingCondition ? (
                                                                <div className="flex justify-center items-center">
                                                                    <Oval height="16" width="16" strokeWidth={8} color="#149211" ariaLabel="loading" />
                                                                </div>
                                                            ) : (
                                                                <Icon className="text-[1.5em] mx-auto" />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Confirmation Popup */}
                                            {confirmProductWindow.id === item?._id && (
                                                <>
                                                    {/* Background overlay */}
                                                    <div
                                                        className="confirmation-dialog"
                                                        onClick={() => setConfirmProductWindow({ id: null, products: [] })} // Close when clicking outside
                                                    />
                                                    {/* Popup window */}
                                                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-[100]">
                                                        <div className="bg-white p-6 rounded text-[1.2em] w-[50vw]">
                                                            <p className="text-[1.2em] font-[500]">
                                                                The following products have been selected by the client. Please review and select to
                                                                approve:
                                                            </p>
                                                            <div className="flex flex-col pt-[2vh] text-[0.8em]">
                                                                {confirmProductWindow?.products?.length > 0 ? (
                                                                    confirmProductWindow.products.map((product, index) => (
                                                                        <label key={index} className="text-[1.2em]">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectedCheckboxes[item._id]?.[product._id] || false}
                                                                                onChange={() => handleCheckboxProductChange(item?._id, product._id)}
                                                                                className="mr-[0.6vw]"
                                                                            />
                                                                            {product.name}
                                                                        </label>
                                                                    ))
                                                                ) : (
                                                                    <label className="text-[1.2em]">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedCheckboxes["default_product"]?.option2 || false}
                                                                            onChange={() => handleCheckboxProductChange("default_product", "option2")}
                                                                            className="mr-[0.6vw]"
                                                                        />
                                                                        Data Leak Prevention (DLP)
                                                                    </label>
                                                                )}
                                                            </div>
                                                            {errorMessage && <p className="text-red-500 text-[1.2em] mt-2">{errorMessage}</p>}
                                                            <div className="flex justify-end gap-x-1 pt-[1vh] mt-4">
                                                                <button
                                                                    className="bg-blue-600 text-white border border-blue-600 hover:bg-white hover:text-blue-600 text-[1.2em] px-[1vw] py-[1vh] rounded-sm"
                                                                    onClick={() => {
                                                                        if (Object.values(selectedCheckboxes[item._id] || {}).some((isChecked) => isChecked)) {
                                                                            handleConfirmProductAction(confirmProductWindow?.id);
                                                                            setErrorMessage("");
                                                                        } else {
                                                                            setErrorMessage("Please select at least one product before confirming.");
                                                                        }
                                                                    }}
                                                                >
                                                                    Confirm
                                                                </button>
                                                                <button
                                                                    className="bg-blue-600 text-white border border-blue-600 hover:bg-white hover:text-blue-600 rounded-sm px-[1vw] py-[1vh] text-[1.2em]"
                                                                    onClick={() => {
                                                                        setConfirmProductWindow({ id: null, products: [] });
                                                                        setErrorMessage("");
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {/* Delete Confirmation Modal */}
                                            {showConfirmationModal === indexVal && (
                                                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[60]">
                                                    <div className="bg-white text-[1.3em] border-gray-300 border-1 rounded-lg shadow-lg min-w-[40vw] max-h-[95vh] p-4 overflow-y-auto">
                                                        <p className='text-[1.2em]  py-[2vh] leading-[1.3]'>
                                                            Are you sure you want to delete this Organization{" "}
                                                            <span className="text-[1.1em] font-[500] text-blue-700">{item?.company_name}</span>?
                                                        </p>
                                                        <div className="modal-buttons pr-[1vw] gap-x-1 py-[2vh] flex flex-row justify-end">
                                                            <button
                                                                className="px-[1.2vw] py-[1vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                                onClick={() => handleDeleteUser(item?._id)}
                                                            >
                                                                Yes
                                                            </button>
                                                            <button
                                                                className="px-[1.2vw] py-[1vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                                onClick={() => setShowConfirmationModal(null)}
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        {/* Dynamic Columns */}
                                        {visibleColumns.map((column, colIndex) => {
                                            const value = column.path ? getValueByPath(item, column.path) : item[column.value];
                                            return (
                                                <td
                                                    key={`${indexVal}-${colIndex}`}
                                                    className="py-2 px-3 w-[280px] text-center text-[0.9em] break-words"
                                                >
                                                    {(() => {
                                                        if (column.type === "date" && value) {
                                                            // Format dates using toLocaleString()
                                                            return <span>{new Date(value).toLocaleString()}</span>;
                                                        }
                                                        // Handle arrays (current_dlp_provider, current_antivirus, etc.)
                                                        if (Array.isArray(value)) {
                                                            return <span>{value.join(", ")}</span>;
                                                        }
                                                        if (value && typeof value === "string" && value.length > 200) {
                                                            // Handle long text with "Show More/Show Less"
                                                            return (
                                                                <div className="text-container">
                                                                    {expandedStates[`${indexVal}-${colIndex}`] ? (
                                                                        <>
                                                                            <span>{value}</span>
                                                                            <button
                                                                                className="show-more-btn text-blue-600"
                                                                                onClick={() => toggleExpand(indexVal, colIndex)}
                                                                            >
                                                                                ...Show Less
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span className="text-truncated">{value.substring(0, 200)}...</span>
                                                                            <button
                                                                                className="show-more-btn text-blue-600"
                                                                                onClick={() => toggleExpand(indexVal, colIndex)}
                                                                            >
                                                                                ...Show More
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                        // Default case for all other columns
                                                        return value ? <span>{value}</span> : <span>-</span>; // Handle null/undefined cases
                                                    })()}
                                                </td>
                                            );
                                        })}



                                        {/* Delete Column */}
                                        {/* <td className="py-2 px-3 min-w-[200px] w-[200px] text-center mx-auto">
                                            <MdDelete
                                                className="text-red-600 text-lg cursor-pointer mx-auto hover:text-orange-500"
                                                onClick={() => setShowConfirmationModal1(indexVal)}
                                            />
                                        </td> */}


                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={visibleColumns.length + 3} className="py-2 pl-2">
                                        No matching data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* comple1 */}


            </>

        </div >
    )
}

export default VerifyChannelPartner;