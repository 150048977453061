import { SET_COMPANY_ID,SET_FILE_ID, SET_USER_ID,SET_INSTALLER_ID,SET_COMPUTER_NAME,SET_CUSTOMER_NAME, SET_CURRENT_KEY, SET_PERMISSIONS, SET_PRODUCTS, SET_ORG_ID} from './companyid.action_type';

export const setCompanyId = (location) => ({
  type: SET_COMPANY_ID,
  payload: location,
});
export const setFileId = (location) => ({
  type: SET_FILE_ID,
  payload: location,
});
export const setCurrentKey = (location) => ({
  type: SET_CURRENT_KEY,
  payload: location,
});
export const setUserId = (location) => ({
  type: SET_USER_ID,
  payload: location,
});
export const setPermissions = (location) => ({
  type: SET_PERMISSIONS,
  payload: location,
});
export const setProducts = (location) => ({
  type: SET_PRODUCTS,
  payload: location,
});
export const setInstallerId = (location) => ({
  type: SET_INSTALLER_ID,
  payload: location,
});
export const setcomputerName=(location)=>({
  type: SET_COMPUTER_NAME,
    payload:location,
})
export const setcustomerName=(location)=>({
  type: SET_CUSTOMER_NAME,
    payload:location,
});
export const setOrgId=(location)=>({
  type: SET_ORG_ID,
    payload:location,
})