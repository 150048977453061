
import { SET_AUTHENTICATE, ERROR_AUTHENTICATE, REQUEST_AUTHENTICATE } from "./auth.action_type";
import axios from "axios";

export const checkAuthentication = () => {

    try {
      // console.log(pageSize,'pagesize');
      return async (dispatch) => {
        dispatch({ type: REQUEST_AUTHENTICATE });
  
        try {
        //   console.log(pageSize, "pageSize inside try");

          const response = await axios.get(`${process.env.REACT_APP_HOST_NAME}/user/details/`);
            
           
          dispatch({ type: SET_AUTHENTICATE, payload: response.data });
          // dispatch({ type: SET_SORT_OPTIONS, payload: { sortBy, sortOrder } });
  
          // dispatch({ type: SET_PAGINATION_OPTIONS, payload: { pageNo, pageSize } });
          
          // console.log('Data from backend FOR AUTHENTICATE:', response);
          // Handle the response data
        } catch (error) {
          // console.error('Error fetching data:', error);
          dispatch({ type: ERROR_AUTHENTICATE, payload: error })
          // Handle errors
        }
      };
  
    } catch (error) {
      console.log(error, 'edrfdfdsfdsd');
  
    }
  
  };