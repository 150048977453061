// Loader.js

import React from 'react';
// Import the CSS file for Loader component

const LoaderForTab = () => {
  return (
    <div id="loader-outerForTab">
      <div id="loaderForTab"></div>
    </div>
    // <div className='text-center'>.....Loading</div>
  );
}

export default LoaderForTab;
