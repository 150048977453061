import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

const LoginWithOTP = ({ email, setEmail, otp, setOtp, otpRequest, setOtpRequest, isOtpSent, setIsOtpSent }) => {
    const countdownRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorMessageOTP, setErrorMessageOTP] = useState(null);

    // Format the time left for the OTP timer
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const COUNTER_KEY_LOGIN = 'my-timer-counter-login'; // Key to store timer value in localStorage
    const START_TIME_KEY_LOGIN = 'my-timer-start-time-login'; // Key to store the start time
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedStartTime = localStorage.getItem(START_TIME_KEY_LOGIN);
        if (savedStartTime) {
            const elapsed = Math.floor(Date.now() / 1000) - parseInt(savedStartTime, 10);
            const initialTimeLeft = Math.max(180 - elapsed, 0); // Calculate remaining time
            return initialTimeLeft;
        }
        return 180; // Default to 180 seconds if no start time exists
    });

    const [isActive, setIsActive] = useState(() => {
        const startTime = localStorage.getItem(START_TIME_KEY_LOGIN);
        return !!startTime; // If there's a saved start time, consider the timer active
    });

    useEffect(() => {
        let timer;

        const calculateTimeLeft = () => {
            const startTime = parseInt(localStorage.getItem(START_TIME_KEY_LOGIN), 10);
            if (!startTime) return;

            const currentTime = Math.floor(Date.now() / 1000);
            const elapsedTime = currentTime - startTime;
            const remainingTime = 180 - elapsedTime; // Assuming timer was initially 120 seconds

            if (remainingTime > 0) {
                setTimeLeft(remainingTime);
            } else {
                // If time has expired, stop the timer and clean up localStorage
                setTimeLeft(0);
                localStorage.removeItem(COUNTER_KEY_LOGIN);
                localStorage.removeItem(START_TIME_KEY_LOGIN);
                setIsActive(false);
            }
        };

        if (isActive && timeLeft > 0) {
            timer = setInterval(() => {
                calculateTimeLeft();
            }, 1000);
        }

        // Clean up timer on component unmount or when the timer is paused
        return () => clearInterval(timer);
    }, [isActive, timeLeft]);

    useEffect(() => {
        if (timeLeft <= 0 && isActive) {
            localStorage.removeItem(COUNTER_KEY_LOGIN);
            localStorage.removeItem(START_TIME_KEY_LOGIN);
            setIsActive(false);
        }
    }, [timeLeft, isActive]);

    const startTimer = () => {
        const startTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        localStorage.setItem(START_TIME_KEY_LOGIN, startTime);
        localStorage.setItem(COUNTER_KEY_LOGIN, 180); // Save initial time (120 seconds)
        setIsActive(true);
        setTimeLeft(180); // Reset the timer    
    };

    const resetTimer = () => {
        localStorage.removeItem(START_TIME_KEY_LOGIN);
        localStorage.setItem(COUNTER_KEY_LOGIN, 180); // Reset to 120 seconds
        setTimeLeft(120);
        setIsActive(false);
    };


    // Update the URL based on the step
    const updateStepInURL = (step) => {
        const params = new URLSearchParams(location.search);
        params.set('step', step); // Add or update the query parameter
        navigate({ search: params.toString() }); // Use navigate instead of history.push
    };

    // Handle OTP generation
    const handleGenerateOTP = (e) => {
        e.preventDefault();
        if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error("Please input a valid Email ID");
            return;
        }
        setOtpRequest(true);
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/otp/generate`, { email })
            .then((response) => {
                toast.success(response?.data?.msg);
                setIsOtpSent(true); // Mark OTP as sent
                const otpTimerDuration = 180; // Set timer for 3 minutes
                localStorage.setItem('otpTimerLogin', otpTimerDuration); // Save the initial timer value in local storage
                localStorage.setItem('otpStartTimeLogin', Math.floor(Date.now() / 1000)); // Save the current time in seconds
                localStorage.setItem('emailLogin', email); // Save email in local storage
                setTimer(otpTimerDuration); // Initialize the timer state
                updateStepInURL('verify'); // Update the URL to indicate that the OTP verification step has started
                setErrorMessage(null);
                startTimer();
                // const timestamp = Math.floor(Date.now() / 1000);
                // localStorage.setItem('otpRequestTime', timestamp);
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage(err?.response?.data?.error || err?.response?.data || err?.message)
                if (window.innerWidth >= 768) {
                    toast.error(err?.response?.data?.error || err?.response?.data || err?.message);
                }
            })
            .finally(() => {
                setOtpRequest(false);
            });
    };

    // Handle OTP verification
    const handleVerifyOTP = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/otp/validate`, {
            email,
            otp: otp.toString()
        })
            .then((response) => {
                // toast.success(response?.message);
                localStorage.removeItem('otpTimerLogin');
                localStorage.removeItem('otpStartTimeLogin');
                localStorage.removeItem('emailLogin');
                localStorage.removeItem(COUNTER_KEY_LOGIN);
                localStorage.removeItem(START_TIME_KEY_LOGIN);
                setErrorMessageOTP(null);
                if (response?.data?.data?.licenseId) {
                    window.location.href = '/cms/company/list';

                }
                else {
                    navigate(`/cms/licenseInfo/${response?.data?.data?.id}`)

                }
            })
            .catch((err) => {
                console.error(err);
                setErrorMessageOTP(err?.response?.data?.error || err?.message)
                if (window.innerWidth >= 768) {
                    toast.error(err?.response?.data?.error || err?.message);
                }
            });
    };

    // Handle Resend OTP
    const handleResendOTP = (e) => {
        e.preventDefault();
        handleGenerateOTP(e); // Call handleGenerateOTP to regenerate the OTP
    };

    // Timer update logic
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const storedEmail = localStorage.getItem('emailLogin');
        const storedTimer = parseInt(localStorage.getItem('otpTimerLogin'));
        const queryParams = new URLSearchParams(location.search);
        const step = queryParams.get('step'); // Get the step from the URL query parameter

        if (storedEmail) {
            setEmail(storedEmail);
        }
        if (storedTimer) {
            const elapsedTime = Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem('otpStartTimeLogin'));
            const remainingTime = storedTimer - elapsedTime;
            if (remainingTime > 0) {
                setTimer(remainingTime);
            } else {
                localStorage.removeItem('otpTimerLogin');
                localStorage.removeItem('otpStartTimeLogin');
                // localStorage.removeItem('emailLogin');
                setIsOtpSent(false);
            }
        }

        // If step is 'verify', set the OTP step as active
        if (step === 'verify') {
            setIsOtpSent(true);
        }

        // Decrement the timer every second
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer > 0) {
                    localStorage.setItem('otpTimerLogin', prevTimer - 1); // Save the updated time in localStorage
                    return prevTimer - 1;
                } else {
                    clearInterval(intervalId); // Stop the timer when it reaches zero
                    return 0;
                }
            });
        }, 1000);

        // Cleanup interval on unmount
        return () => clearInterval(intervalId);

    }, [location.search, setEmail, setIsOtpSent]);

    return (
        <div className='py-[1vh]'>
            {/* OTP Generation Form */}
            {!isOtpSent ? (
                <div>
                    <p className='text-gray-800 text-justify'>Enter the email address associated with you Matisoft account.</p>
                    {errorMessage && <div className='text-red-600 text-justify px-[0.5vw] md:hidden' >{errorMessage}</div>}

                    <form onSubmit={handleGenerateOTP} className='flex flex-col gap-y-[5vh]'>

                        <div className='my-2'>
                            <label className='block text-gray-800 py-[2vh] font-semibold'>Email Id</label>
                            <input
                                type='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter your email'
                                required
                                maxLength='254'
                                className='w-full px-2 py-2  mt-[1vh] border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                            />
                        </div>
                        <button
                            type='submit'
                            disabled={otpRequest}
                            className='w-full  flex flex-row items-center gap-x-1 justify-center bg-blue-500 text-white py-2 mb-[2vh] rounded-md hover:bg-blue-600 transition duration-200'
                        >
                            {otpRequest ? (
                                <>
                                    <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                    <span>Sending</span>
                                </>
                            ) : (
                                'Send OTP'
                            )}
                        </button>
                    </form>
                    {/* <p className='pt-[1vh]'>
                        <div className='font-semibold'>Has your email address changed?</div>
                        <div className='text-[0.9em]'>If you no longer have access to the email address linked to your Matisoft account, please reach out to <a className='text-blue-600 hover:underline' href='/contact' target='_blank'>Customer Service</a> for assistance in regaining access to your account.</div>
                    </p> */}
                </div>
            ) : (
                // OTP Verification Form
                <form onSubmit={handleVerifyOTP}>
                    <h1 className='py-[1vh] text-gray-800'>
                        For your security, we have sent the OTP to your email
                        <span className="font-semibold">{` ${email}`}</span>.
                        <span
                            className="text-blue-600 cursor-pointer"
                            onClick={() => {
                                setIsOtpSent(false); // Reset to the first step
                                updateStepInURL('generate'); // Update URL to indicate the first step
                            }}
                        >
                            {` Change`}
                        </span>
                    </h1>
                    {errorMessageOTP && <div className='text-red-600 text-justify px-[0.5vw] md:hidden' >{errorMessageOTP}</div>}

                    <div className='my-1'>
                        <label className='block font-[600] text-gray-900 py-[1vh]'>OTP</label>
                        <input
                            type='number'
                            name='otp'
                            value={otp}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow empty value or restrict input to 6 digits and ensure it does not exceed 999999
                                if (value === '' || (value.length <= 6 && parseInt(value, 10) <= 999999)) {
                                    setOtp(value);
                                }
                            }}
                            placeholder='Enter OTP'
                            required
                            maxLength='6'
                            onKeyDown={(e) => {
                                // Prevent '+' or '-' keys
                                if (e.key === '+' || e.key === '-') {
                                    e.preventDefault();
                                }
                            }}
                            className='w-full px-2 mt-[1vh] py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                        />
                    </div>
                    <div className='mb-4 text-[0.9em] flex items-center justify-between'>

                        {localStorage.getItem("my-timer-counter-login") ? <span className='text-xs'>
                            {`Resend OTP after ${formatTime(timeLeft)}`}
                        </span>
                            :
                            <div
                                onClick={timer > 0 || otpRequest === true ? null : handleResendOTP} // Only allow click if timer is 0
                                className={`pl-1 py-2  rounded-md  text-blue-600 text-center ${timer > 0 || otpRequest ? 'cursor-not-allowed opacity-90' : '  cursor-pointer'
                                    }`}
                            >
                                {otpRequest ?
                                    (
                                        <div className='flex flex-row gap-x-1 items-center text-gray-800'>
                                            Loading <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                        </div>
                                    )
                                    : 'Resend OTP'}
                            </div>
                        }
                    </div>
                    <button
                        type='submit'
                        className='w-full bg-blue-500 text-white py-2 mb-[2vh] rounded-md hover:bg-blue-600 transition duration-200'
                    >
                        Verify OTP
                    </button>
                </form>
            )}


        </div>
    );
};

export default LoginWithOTP;
