import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import IR from "./pages/IR";
import DLP from "./pages/DLP";
import Home from "./Home";
import { useDispatch, useSelector } from "react-redux";
import WorkQueue from "./webapplication/pages/WorkQueue";
import { Installer } from './webapplication/pages/Installer';
import { Dashboard } from './webapplication/pages/Dashboard';
import ThankYouSign from './pages/signUp/ThankYouSign';
import 'react-tabs/style/react-tabs.css'; // Import default styles for react-tabs
import Login from './pages/login/Login';
import Instances from './webapplication/pages/actionCenter/instances/Instances';
import ForgotPassword from './pages/login/ForgotPassword';
import PreSignUp1 from './pages/Particles';
import SubPage1 from './webapplication/pages/SubPage1';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Installer1 } from './webapplication/pages/Installer1';
import { Helmet } from 'react-helmet';
// import { useCookies } from 'react-cookie';
import CreateLicense2 from './webapplication/pages/licensing/CreateLicense2';
import LicenseDetail1 from './webapplication/pages/licensing/LicenseDetail1';
import LicenseHistoryCustomer from './webapplication/pages/licensing/LicenseHistoryCustomer';
import LicenseHistoryAdmin from './webapplication/pages/licensing/LicenseHistoryAdmin';
import { checkAdminAuth } from './Redux/adminAuth/adminAuth.action';
import { checkUserAuth, userCredential } from './Redux/userAuth/userAuth.action';
import { setUserId } from './Redux/companyid/companyid.action';
import Error from './pages/Error';
import LinkedDevices from './webapplication/pages/licensing/LinkedDevices';
import UserRoles from './webapplication/pages/UserRoles';
import NewUserSignup from './webapplication/pages/NewUserSignup';
import Account from './webapplication/pages/settings/Account';
import SignUp from './pages/signUp/SignUp';
import AllOrganisation from './webapplication/pages/adminPages/AllOrganisation';
import AllCompanies from './webapplication/pages/adminPages/AllCompanies';
import Notification from './webapplication/pages/administrator/Notification';
import OrganisationSettings from './webapplication/pages/administrator/OrganisationSettings';
import Release from './webapplication/pages/adminPages/Release';
import Rough from './webapplication/pages/adminPages/Rough';
import Login12 from './pages/login/Login12';
import ForgotPasswordNew from './pages/login/ForgotPasswordNew';
import Account1 from './webapplication/pages/settings/Account1';
import ProgramLogs from './webapplication/pages/adminPages/logs/ProgramLogs';
import SystemLogs from './webapplication/pages/adminPages/logs/SystemLogs';
import Recharge from './pages/login/Recharge';
import SignUp1 from './pages/signUp/Signup1';
import VerifyUser from './webapplication/pages/adminPages/verify/VerifyUser';
import CompleteSignUp from './pages/signUp/CompleteSignUp';
import AddUser from './webapplication/pages/administrator/AddUser';
import ErrorPage from './pages/ErrorPage';
import DlpHero2 from './components/DlpHero2';
import Home1 from './pages/DLP1';
function App() {
  const dispatch = useDispatch();
  const { companyId, userId, adminAuthData, userAuthData, permissions } = useSelector((state) => ({
    companyId: state.companyid.companyId,
    userId: state.companyid.userId,
    permissions: state.companyid.permissions,
    adminAuthData: state.adminAuth.adminAuthData,
    userAuthData: state.userAuth.userAuthData
  }));

  // const credential = useMemo(() => {
  //   const storedCredential = localStorage.getItem("userCrdential");
  //   console.log("Retrieving user credential from localStorage");
  //   return storedCredential ? JSON.parse(storedCredential) : null;
  // }, []);
  
  // useEffect(() => {
  //   if (credential) {
  //     dispatch(userCredential(credential));
  //   }
  // }, [credential]);
  

  // const isAdmin = adminAuthData?.role?.includes("ADMINISTRATOR");

  return (
    <BrowserRouter>
      <div className="relative">

        <ToastContainer />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dlp" element={<DLP />} />
          <Route path="/dlp12" element={<Home1 />} />

          <Route path="/ir-services" element={<IR />} />
          <Route path='/presignup' element={<PreSignUp1 />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/signup1" element={<SignUp1 />} /> */}
          <Route path="/thankyouSignUp" element={<ThankYouSign />} />
          <Route path="/login" element={<Login12 />} />
          <Route path="/recharge/:email" element={<Recharge />} />
          <Route path="/forgotPassword" element={<ForgotPasswordNew />} />
          <Route path="/complete-signup/:uniqueid" element={<CompleteSignUp />} />
          <Route path="/cms/history" element={<LicenseHistoryCustomer />} />
          <Route path="/cms/account/:userid" element={
            permissions.find((path) => path?.pagePath?.includes('/cms/account/') && path?.permissionLevel === 'no_access')
              ? <Error /> : <Account1 />} />
          <Route path="/cms/allOrg/:userid" element={<AllOrganisation />} />
          <Route path="/cms/allCompany/:userid" element={<AllCompanies />} />
          <Route
            path="/cms/admin/notifications/:userid"
            element={
              permissions.find((path) => path?.pagePath?.includes('/cms/admin/notifications/') && path?.permissionLevel === 'no_access')
                ? <Error /> : <Notification />}
          />
          <Route
            path="/cms/admin/organisation/:userid"
            element={userAuthData?.permissions?.[0]?.permissionLevel === 'no_access' ? <Error /> : <OrganisationSettings />}
          />
          {/* <Route path="/cms/overview/:userid" element={<Dashboard />} /> */}
          <Route
            path="/cms/licenseInfo/:userId"
            element={
              permissions.find((path) => path?.pagePath?.includes('/cms/licenseInfo/') && path?.permissionLevel === 'no_access')
                ? <Error />
                : <LicenseDetail1 />
            }
          />
          <Route path="/cms/licenseHistory/:userid" element={<LicenseHistoryAdmin />} />

          <Route path="/cms/linkeddevices/:product/:userid" element={
            permissions.find((path) => path?.pagePath?.includes('/cms/linkeddevices/') && path?.permissionLevel === 'no_access')
              ? <Error /> : <LinkedDevices />} />
          {/* <Route path="/cms/linkeddevices/:userid" element={<LinkedDevices />} /> */}

          <Route path="/cms/installer/:companyid/:userid" element={
            permissions.find((path) => path?.pagePath?.includes('/cms/installer/') && path?.permissionLevel === 'no_access')
              ? <Error /> : <Installer1 />} />
          <Route
            path='/cms/userroles/:userid'
            element={
              permissions.find((path) => path?.pagePath?.includes('/cms/userroles/'))?.permissionLevel === 'no_access'
                ? <Error />
                : <AddUser />
            }
          />

          <Route path="/cannotget" element={<Error />} />
          <Route path="/cms/newuser/:uniqueId" element={<NewUserSignup />} />
          <Route path="/cms/createLicense/:userId" element={<CreateLicense2 />} />
          {/* <Route path="/cms/logs/program/:companyid/:orgId/:userId" element={<ProgramLogs />} />
          <Route path="/cms/logs/system/:companyid/:orgId/:userId" element={<SystemLogs />} /> */}
          <Route path="/cms/release/manage/:userid" element={<Release />} />
          <Route path="/cms/verifyUser/:userId" element={<VerifyUser />} />
          <Route path="/error" element={<ErrorPage />} />

        </Routes>

      </div>
    </BrowserRouter>
  );
}

export default App;
