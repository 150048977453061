import React, { useEffect, useRef, useState } from 'react';
import Header from '../../Header1';
import { passwordStrength } from 'check-password-strength'
import { FaCheck } from 'react-icons/fa6';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify'; // Assuming toast is being used for notifications
// import startsWith from 'lodash.startswith';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MdAccountCircle, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { antivirusList, dlpList, industryList, jobList } from '../../utils/ConstantOption';
import ErrorPage from '../ErrorPage';

const CompleteSignUp = () => {
    const navigate = useNavigate();
    const [signUpData, setSignUpData] = useState({
        companyName: '',
        industry: '',
        title: '',
        current_antivirus: [],
        deviceCount: '',
        current_dlp_provider: [],
        // groupName: '',
        country: '',
        state: '',
        password: '',
        // department: '',
        token: '',
        other_antivirus: "",
        other_dlp: "",
        other_industry: "",
        other_jobRole: "",
        _id: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const validateFields = (section) => {
        const errors = {};
        {

            if (!trialForm.last_name.trim()) errors.current_dlp_provider = 'Current DLP is required';
            if (!trialForm.companyName.trim()) errors.current_antivirus = 'Current antivirus is required';
            // if (!privacy) errors.privacy = 'Confirmation required';

        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const [confirmPassword, setConfirmPassword] = useState('');
    const [preFillData, setPreFillData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        country: '',
        industry: "",
        type: "",
        job_role: '',
        products: "",
        current_dlp_provider: [],
        current_antivirus: []

    })
    const [password, setPassword] = useState('');
    const [score, setScore] = useState({
        length: 0,
        value: '',
        contains: []
    });
    const [isDLPDropdownOpen, setIsDLPDropdownOpen] = useState(false);
    const [selectedDLPItems, setSelectedDLPItems] = useState([]);
    const [isAntivirusDropdownOpen, setIsAntivirusDropdownOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'confirmPassword') {
            setConfirmPassword(event.target.value);
            return;
        }
        setSignUpData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'password') {
            const passwordStrengthResult = passwordStrength(value);
            // console.log(passwordStrengthResult);
            setScore((prevScore) => ({
                ...prevScore,
                value: passwordStrengthResult.value,
                length: passwordStrengthResult.length,
                contains: passwordStrengthResult.contains
                // corrected 'lentgh' to 'length'
            }));
        }

    };
    const handleProviderChange = (e, field) => {
        const selectedValue = e.target.value;
        signUpData((prevData) => {
            let updatedField = [];

            if (selectedValue === "Others") {
                // If "Others" is selected, set it as the only value in the array
                updatedField = [selectedValue];
            } else {
                // Otherwise, just replace with the selected value
                updatedField = [selectedValue];
            }

            return {
                ...prevData,
                [field]: updatedField, // Update the specific field with the selected value
            };
        });
    };
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    // Function to handle checkbox change for DLP
    const handleDLPCheckboxChange = (item) => {
        setSignUpData((prevState) => {
            let updatedDLPs;

            if (item === "None") {
                // If "None" is selected, deselect all others
                updatedDLPs = ["None"];
            } else {
                // Remove "None" if any other option is selected
                updatedDLPs = prevState.current_dlp_provider.includes(item)
                    ? prevState.current_dlp_provider.filter((i) => i !== item) // Uncheck the item
                    : [...prevState.current_dlp_provider.filter((i) => i !== "None"), item]; // Add item, remove "None"
            }

            return {
                ...prevState,
                current_dlp_provider: updatedDLPs,
            };
        });
    };
    // Function to handle checkbox change for Antivirus
    const handleAntivirusCheckboxChange = (item) => {
        setSignUpData((prevState) => {
            let updatedAntivirus;

            if (item === "None") {
                // If "None" is selected, deselect all other options and only keep "None"
                updatedAntivirus = ["None"];
            } else {
                // If any other option is selected:
                // 1. Remove "None" if it's selected
                // 2. Toggle the current item in the list
                updatedAntivirus = prevState.current_antivirus.includes(item)
                    ? prevState.current_antivirus.filter((i) => i !== item) // Unselect the item
                    : [...prevState.current_antivirus.filter((i) => i !== "None"), item]; // Add the item and remove "None"
            }

            return {
                ...prevState,
                current_antivirus: updatedAntivirus,
            };
        });
    };
    // Refs for dropdown containers
    const dlpDropdownRef = useRef(null);
    const antivirusDropdownRef = useRef(null);
    // Handle click outside to close the dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (!dlpDropdownRef.current || !dlpDropdownRef.current.contains(event.target))
            ) {
                setIsDLPDropdownOpen(false);
            }

            if ((!antivirusDropdownRef.current || !antivirusDropdownRef.current.contains(event.target))
            ) {
                setIsAntivirusDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dlpDropdownRef, antivirusDropdownRef]);

    const [errorMessage, setErrorMessage] = useState(null);
    useEffect(() => {
        const uuid = window.location.pathname.split('/').pop(); // Extract the last part of the URL
        axios.get(`${process.env.REACT_APP_HOST_NAME}/api/webuser/fetchUser/${uuid}`)
            .then((response) => {
                // Handle the response here
                setPreFillData(response.data)
                setSignUpData((prevData) => ({
                    ...prevData,
                    country: response.data.country,
                    groupName: response.data.company_name,
                    companyName: response.data.company_name,
                    token: response.data.token,
                    _id: response.data._id
                }))
                setErrorMessage(null);
                // console.log(response?.data);
            })
            .catch((error) => {
                // Handle any errors here
                toast.error(error?.response?.data || error?.message);
                // navigate('/error')
                console.error(error);
                setErrorMessage(error?.response?.data || error?.message)
            });
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const payload = {};

        // Function to handle fields with "Others" option
        const processField = (fieldArray, otherFieldValue) => {
            if (Array.isArray(fieldArray) && fieldArray.length > 0) {
                return fieldArray.includes("Others") && otherFieldValue
                    ? fieldArray.map(item => (item === "Others" ? otherFieldValue : item))
                    : [...fieldArray];
            }
            return undefined;
        };

        // Populate the payload
        payload.current_dlp_provider = processField(signUpData.current_dlp_provider, signUpData.other_dlp);
        payload.current_antivirus = processField(signUpData.current_antivirus, signUpData.other_antivirus);

        // Handle individual fields with "Others" option
        if (signUpData.industry) {
            payload.industry = signUpData.industry === "Others" && signUpData.other_industry
                ? signUpData.other_industry
                : signUpData.industry;
        }

        if (signUpData.title) {
            payload.title = signUpData.title === "Others" && signUpData.other_jobRole
                ? signUpData.other_jobRole
                : signUpData.title;
        }

        // Add other fields directly if they have values
        const otherFields = ["companyName", "deviceCount", "country", "state", "password", "token", "_id"];
        otherFields.forEach(field => {
            if (signUpData[field]) {
                payload[field] = signUpData[field];
            }
        });
        // console.log(payload,"Payload");

        // Validate required fields
        if (signUpData.deviceCount && signUpData.country && signUpData.state && signUpData.password && confirmPassword === signUpData.password) {
            // Check password strength
            if (score.value === 'Weak' || score.value === 'Too weak') {
                toast.error('Password strength is weak. Please choose a stronger password.');
                setFormSubmitted(false);
                return;
            }

            // All required fields are filled, passwords match, and password strength is acceptable
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/complete-signup`, { ...payload })
                .then((response) => {
                    // console.log(response, "formSubmission");
                    toast.success(response?.data);

                    // Reset form fields
                    setSignUpData({
                        companyName: '',
                        industry: '',
                        title: '',
                        current_antivirus: '',
                        current_dlp_provider: '',
                        deviceCount: '',
                        country: '',
                        state: '',
                        groupName: '',
                        token: '',
                        password: '',
                        description: '',
                        _id: ''
                    });

                    // Reset confirm password
                    setConfirmPassword('');

                    setFormSubmitted(false);
                    navigate('/login');
                    window.scrollTo(0, 0);

                })
                .catch((err) => {
                    console.error(err);
                    toast.error(err?.message || "Submission failed. Please try again.");
                });

            // console.log('Form submitted', signUpData);
        } else {
            // Handle validation failure
            if (confirmPassword !== signUpData.password) {
                toast.error('Passwords do not match');
            } else {
                toast.error('Please fill all required fields');
            }
        }
    };

    // console.log(signUpData,"Signup data");

    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');

    const selectCountry = (val) => {
        setSignUpData((preData) => ({
            ...preData,
            country: val
        }));
        // console.log(val, "country");
    };

    const selectRegion = (val) => {
        setSignUpData((preData) => ({
            ...preData,
            state: val
        }));
    };
    const handlePhoneChange = (val, countryData, e, formattedValue) => {
        setSignUpData((preData) => ({
            ...preData,
            phone: formattedValue,  // Save the formatted value with country code
        }));
    };

    const isValidPhoneNumber = (inputNumber, country, countries) => {
        return countries.some((country) => {
            return inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber);
        });
    };
    if (errorMessage) {
        return <ErrorPage />;
      }
    return (
        <>
            <Helmet>
                <title>Matisoft | Matisoft Account Creation</title>
            </Helmet>
            <Header />
            <div className='bg-gray-200 h-full w-full completeSignUp'>
                <div className=' w-[90vw] mx-auto bg-blue-100 p-4 rounded-lg shadow-lg'>
                    <div className='text-center py-4'>
                        <h1 className='text-[1.7em] py-2 border-b  border-b-gray-300 text-blue-900'>MATISOFT ACCOUNT CREATION</h1>
                        <p className='text-[0.8em] py-2 '>Fill in your information and sign up with us to get access to more information and updates.</p>
                    </div>
                    <form onSubmit={handleSubmit} className='pb-[4vh]' >
                        <div className=' gap-4 pt-4 text-[0.8em] font-[400] text-gray-900'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[1vw] gap-y-[2vh]'>

                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>First Name:</label>
                                    <input type='text' placeholder='First Name'
                                        name='first_name' value={preFillData.first_name}
                                        className='form-inputCompleteSignUp' maxLength='50' disabled style={{ backgroundColor: "#e5ebec" }} />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Last Name:</label>
                                    <input type='text' name='last_name' maxLength='50'
                                        disabled value={preFillData.last_name} className='form-inputCompleteSignUp ' style={{ backgroundColor: "#e5ebec" }} />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Email Id:</label>
                                    <input type='email' name='email' maxLength='30' value={preFillData.email} disabled style={{ backgroundColor: "#e5ebec" }}
                                        className='form-inputCompleteSignUp' />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Phone Number:</label>
                                    <input type='tel' name='phone' value={preFillData.phone} className='form-inputCompleteSignUp' style={{ backgroundColor: "#e5ebec" }} disabled />

                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Organization Name:</label>
                                    <input type='text' name='companyName'
                                        value={preFillData.company_name} onChange={handleChange}
                                        maxLength='50' disabled
                                        className='form-inputCompleteSignUp' style={{ backgroundColor: "#e5ebec" }} />
                                </div>

                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Country:</label>

                                    <input type='text' name='country'

                                        value={preFillData.country} disabled style={{ backgroundColor: "#e5ebec" }}
                                        className='form-inputCompleteSignUp' />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>State*:</label>

                                    <RegionDropdown
                                        classes='form-inputCompleteSignUp bg-white border border-1 border-[#dad9d9]'
                                        country={preFillData.country}
                                        name='state'
                                        value={signUpData.state}
                                        onChange={selectRegion} required
                                    />{formSubmitted && !signUpData.state && (
                                        <span className="text-red-600 pl-[1vw] text-[0.9em]">This field is required</span>
                                    )}
                                </div>

                                {preFillData.type !== 'CHANNEL_PARTNER' && !preFillData.industry && <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Industry Name*:</label>
                                    <select name='industry' value={signUpData.industry} onChange={handleChange} required className='form-inputCompleteSignUp bg-white border border-1 border-[#dad9d9]'>

                                        <option value="">Select...</option>
                                        {industryList.map((list, index) => (
                                            <option key={index} value={list}>{list}</option>
                                        ))}

                                    </select>
                                    {/* Check if the selected value is "Others" */}
                                    {signUpData.industry === "Others" && (
                                        <input
                                            type="text"
                                            name="other_industry"
                                            value={signUpData.other_industry || ""}
                                            onChange={handleChange}  // Update state for 'other_antivirus'
                                            placeholder="Please specify"
                                            className="mt-2 border p-2 rounded bg-white"
                                            maxLength='50'
                                        />
                                    )}
                                </div>}

                                {Array.isArray(preFillData?.products) &&
                                    preFillData.products.some(item => item.name.includes('EDR')) &&
                                    preFillData.current_antivirus?.length === 0 ? (
                                    preFillData.type === "END_USER" ? (
                                        <div className="flex flex-col">
                                            <label className="mb-2 font-[500]">Who is your current antivirus provider?*:</label>
                                            <select
                                                name="current_antivirus"
                                                value={signUpData.current_antivirus}
                                                onChange={handleChange}
                                                required
                                                className="form-inputCompleteSignUp border border-1 border-[#dad9d9]"
                                            >
                                                <option value="">Select...</option>
                                                {antivirusList.map((provider, index) => (
                                                    <option key={index} value={provider}>
                                                        {provider}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* Display additional input for "Others" */}
                                            {signUpData.current_antivirus.includes("Others") && (
                                                <input
                                                    type="text"
                                                    name="other_antivirus"
                                                    value={signUpData.other_antivirus || ""}
                                                    onChange={handleChange}
                                                    placeholder="Please specify"
                                                    className="mt-2 border p-2 rounded bg-white"
                                                    maxLength="50"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div ref={antivirusDropdownRef}>
                                            <label className="">Which antivirus are you selling?:</label>
                                            <div
                                                className="mt-2 form-inputCompleteSignUp bg-white border border-1 border-[#dad9d9] cursor-pointer flex flex-row justify-between items-center"
                                                onClick={() => setIsAntivirusDropdownOpen(prev => !prev)} // Toggle dropdown
                                            >
                                                {signUpData.current_antivirus.length > 0
                                                    ? signUpData.current_antivirus
                                                        .slice(0, 2) // Get the first two names
                                                        .join(", ") + (signUpData.current_antivirus.length > 2 ? ", ..." : "") // Add "..." if more than two
                                                    : "Select Antivirus..."}
                                                <MdOutlineKeyboardArrowDown />
                                            </div>
                                            {isAntivirusDropdownOpen && (
                                                <div className="relative">
                                                    <div className="absolute left-0 top-full w-full border p-2 rounded bg-white shadow-lg max-h-64 overflow-y-auto z-10">
                                                        {antivirusList.map((item, index) => (
                                                            <div key={index} className="flex items-center mb-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`antivirus-${index}`}
                                                                    value={item}
                                                                    checked={signUpData.current_antivirus.includes(item)}
                                                                    onChange={() => handleAntivirusCheckboxChange(item)} // Update state
                                                                    className="mr-2"
                                                                />
                                                                <label htmlFor={`antivirus-${index}`} className="text-gray-700">
                                                                    {item}
                                                                </label>
                                                                {item === "Others" && signUpData.current_antivirus.includes("Others") && (
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Specify..."
                                                                        name="other_antivirus"
                                                                        value={signUpData.other_antivirus || ""}
                                                                        onChange={handleChange}
                                                                        className="ml-2 border p-1 rounded"
                                                                        maxLength="50"
                                                                    />
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                ) : null}


                                {Array.isArray(preFillData?.products) &&
                                    preFillData.products.some(item => item.name.includes('DLP')) &&
                                    preFillData.current_dlp_provider.length === 0 ? (
                                    preFillData.type === "END_USER" ? (
                                        <div className="flex flex-col">
                                            <label className="mb-2 font-[500]">Who is your current DLP provider?*:</label>
                                            <select
                                                name="current_dlp_provider"
                                                value={signUpData.current_dlp_provider}
                                                onChange={handleChange}
                                                required
                                                className="form-inputCompleteSignUp border border-1 border-[#dad9d9]"
                                            >
                                                <option value="">Select...</option>
                                                {dlpList.map((provider, index) => (
                                                    <option key={index} value={provider}>
                                                        {provider}
                                                    </option>
                                                ))}
                                            </select>

                                            {/* Display additional input for "Others" */}
                                            {signUpData.current_dlp_provider.includes("Others") && (
                                                <input
                                                    type="text"
                                                    name="other_dlp"
                                                    value={signUpData.other_dlp || ""}
                                                    onChange={handleChange}
                                                    placeholder="Please specify"
                                                    className="mt-2 border p-2 rounded bg-white"
                                                    maxLength="50"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div ref={dlpDropdownRef}>
                                            <label className="">Which DLP are you selling?:</label>
                                            <div
                                                className="mt-2 form-inputCompleteSignUp bg-white border border-1 border-[#dad9d9] cursor-pointer flex flex-row justify-between items-center"
                                                onClick={() => setIsDLPDropdownOpen(prev => !prev)} // Toggle dropdown
                                            >
                                                {signUpData.current_dlp_provider.length > 0
                                                    ? signUpData.current_dlp_provider
                                                        .slice(0, 2) // Get the first two names
                                                        .join(", ") + (signUpData.current_dlp_provider.length > 2 ? ", ..." : "") // Add "..." if more than two
                                                    : "Select DLPs..."}
                                                <MdOutlineKeyboardArrowDown />
                                            </div>
                                            {isDLPDropdownOpen && (
                                                <div className="relative">
                                                    <div className="absolute left-0 top-full w-full border p-2 rounded bg-white shadow-lg max-h-64 overflow-y-auto z-10">
                                                        {dlpList.map((item, index) => (
                                                            <div key={index} className="flex items-center mb-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`dlp-${index}`}
                                                                    value={item}
                                                                    checked={signUpData.current_dlp_provider.includes(item)}
                                                                    onChange={() => handleDLPCheckboxChange(item)}
                                                                    className="mr-2"
                                                                />
                                                                <label htmlFor={`dlp-${index}`} className="text-gray-700">
                                                                    {item}
                                                                </label>
                                                                {item === "Others" && signUpData.current_dlp_provider.includes("Others") && (
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Specify..."
                                                                        name="other_dlp"
                                                                        value={signUpData.other_dlp || ""}
                                                                        onChange={handleChange}
                                                                        className="ml-2 border p-1 rounded"
                                                                        maxLength="50"
                                                                    />
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                ) : null}


                                {preFillData.type !== 'CHANNEL_PARTNER' && !preFillData.job_role && <div className='flex flex-col'>
                                    <label className='mb-2 font-[500] '>Job Role*:</label>
                                    <select name='title' value={signUpData.title} onChange={handleChange} required className='border p-2 rounded bg-white'>

                                        <option value="">Select...</option>
                                        {jobList.map((job, index) => (
                                            <option key={index} value={job}>
                                                {job}
                                            </option>
                                        ))}
                                    </select>
                                    {signUpData.job_title === "Others" && (
                                        <input
                                            type="text"
                                            name="other_jobRole"
                                            value={signUpData.other_jobRole || ""}
                                            onChange={handleChange}  // Update state for 'other_antivirus'
                                            placeholder="Please specify"
                                            className="mt-2 border p-2 rounded bg-white"
                                            maxLength='50'
                                        />
                                    )}
                                </div>}

                                <div className='flex flex-col '>
                                    <label className='mb-2 font-[500] '>Number of License required (Approx)*:</label>
                                    <input type='number' name='deviceCount'
                                        value={signUpData.deviceCount > 0 ? signUpData.deviceCount : ""}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            // Remove leading zeros
                                            if (value.startsWith("0")) {
                                                value = value.replace(/^0+/, ""); // Remove all leading zeros
                                            }
                                            value = parseInt(value, 10) || 0; // Parse value to ensure it's a valid number
                                            if (value <= 10000000000) { // Ensure value is within the max limit
                                                handleChange({ target: { name: "deviceCount", value } });
                                            }
                                        }}
                                        required min='1' className='form-inputCompleteSignUp border border-1 border-[#dad9d9]'
                                        onKeyDown={(e) => {
                                            if (e.key === '+' || e.key === '-') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onInput={(e) => {
                                            // Prevent leading zeros during typing
                                            if (e.target.value.startsWith("0")) {
                                                e.target.value = e.target.value.replace(/^0+/, "");
                                            }
                                        }}
                                        max='10000000000' style={{ backgroundColor: 'white' }} />

                                </div>

                                <div>
                                </div>
                            </div>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[1vw] '>
                                <div className='flex flex-col relative '>
                                    <label className=' font-[500]'>Create New Password*:</label>
                                    <div className='relative mt-2'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            value={signUpData.password}
                                            onChange={handleChange}
                                            required
                                            className="form-inputCompleteSignUp border border-1 border-[#dad9d9]"
                                            maxLength='16'
                                        />
                                        {showPassword ? (
                                            <BsEyeSlashFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={togglePasswordVisibility}
                                            />
                                        ) : (
                                            <BsEyeFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={togglePasswordVisibility}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col relative'>
                                    <label className='mb-2 font-[500]'>Confirm New Password*:</label>
                                    <div className='relative'>
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name='confirmPassword'
                                            value={confirmPassword}
                                            onChange={handleChange}
                                            required
                                            className='form-inputCompleteSignUp border border-1 border-[#dad9d9]'
                                            maxLength='16'
                                        />
                                        {showConfirmPassword ? (
                                            <BsEyeSlashFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={toggleConfirmPasswordVisibility}
                                            />
                                        ) : (
                                            <BsEyeFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={toggleConfirmPasswordVisibility}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr />
                        <div className='flex md:flex-row flex-col-reverse justify-between text-[0.8em]'>
                            <div className='  py-[1vh] '>
                                <div className='text-blue-700 text-[0.9em] py-[0.5vh]'>(Your password should contain at least 8 characters and the properties listed below)</div>
                                Length: {score.length ? score.length : 0}, &nbsp;
                                Your password is  {score.value ? <span className={`
                                ${score.value === 'Too weak' ? 'bg-red-600 text-white' :
                                        score.value === 'Weak' ? 'bg-orange-500' :
                                            score.value === 'Medium' ? 'bg-yellow-500' :
                                                'bg-green-600 text-white  '} px-[0.6vw] py-[0.6vh]
                            `}>
                                    {score.value}
                                </span> : <span className='border-b-[1px] w-2'></span>}
                            </div>
                            <div>{signUpData.password != '' && confirmPassword !== '' && (confirmPassword === signUpData.password ? <div className='text-green-700 text-[0.9em]'>(Passwords match)</div> : <div className='text-red-600 text-[0.9em] '>(Passwords do not match)</div>)}
                            </div > </div>
                        <div className=' grid grid-cols-2 md:grid-cols-4 gap-y-[1vw] max-w-[850px] text-[0.8em]'>
                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("lowercase") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Lowercase</div>
                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("uppercase") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Uppercase</div>

                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("number") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Numbers</div>

                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("symbol") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Special Characters</div>

                        </div>

                        <div className='flex flex-col items-start py-4 text-[0.7em]'>
                            <div className='flex items-center '>
                                <p>By clicking submit, you agree to the terms and conditions of the <a href='/trial-agreement' target='_blank' className='text-blue-600 underline hover:text-blue-700'>Matisoft Cyber Security Labs Trial Agreement</a>, acknowledge that your data will be handled and transmitted as described in the <a href='/privacy-policy' target='_blank' className='text-blue-600 underline hover:text-blue-700'>Privacy Policy</a>, and confirm that you have fully read and understood it.</p>
                            </div>

                        </div>
                        <div className='mx-auto  flex flex-col justify-center items-center py-[2vh]'>  <button type='submit' className='mt-4 w-[27vw] bg-blue-600 border border-blue-600 border-1 text-white px-[2vw] py-[0.8vh] rounded hover:bg-white hover:text-blue-700'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CompleteSignUp;