import React, { useEffect, useState } from 'react';
import '../../css/signup.css';
import { FaCheck } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Country } from "country-state-city";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Header from '../../Header1';
import { Helmet } from 'react-helmet';

const SignUp = () => {
    const validProducts = ['Data Leak Prevention(DLP)', 'Endpoint Detection and Response(EDR)', 'Data Leak Prevention(DLP) & Endpoint Detection and Response(EDR)']; // Replace with your valid products

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [formSection, setFormSection] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [warning, setWarning] = useState(false);
    const countryList = Country.getAllCountries();
    const { productValue } = location.state || {};
    let productValues = query.get('Products');
    productValues = productValues ? decodeURIComponent(productValues) : null;
  
    const [cookieCheck, setCookieCheck] = useState(null);
    const [trialForm, setTrialForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        phone: '',
        companyName: '',
        product: productValues,
        type: '',
        channel_partner_type: "",
        other: ""
    });

    // Check if product value is valid
    useEffect(() => {
        if (!validProducts.includes(productValues)) {
            navigate('/error'); // Redirect to an error page
        }
    }, [productValues, navigate]);

    const validatePhone = () => {
        if (!phone || phone.length < 6) return false;
        return true;
    };
    const [countries, setCountries] = useState([]); // State to store the list of countries

    // Fetch countries on component mount
    const [countryCode, setCountryCode] = useState(""); // Default country code (Greece)

    // Handle country selection
    const handleCountryChange = (e) => {
        const selectedCountry = countryList.find((country) => country.isoCode === e.target.value);
        if (selectedCountry) {
            // Store the isoCode in trialForm
            setTrialForm((prevForm) => ({ ...prevForm, country: selectedCountry.name }));

            // Set the country code (iso2) for phone input
            setCountryCode(selectedCountry.isoCode.toLowerCase());

        }
    };


    const handlePhoneChange = (phone) => {
        const formattedPhone = `+${phone}`;
        setPhone(formattedPhone);
        setTrialForm((prevForm) => ({ ...prevForm, phone: formattedPhone }));
    };

    // const validatePhone = (combinedPhone) => {
    //     const phoneNumber = parsePhoneNumberFromString(combinedPhone);
    //     return phoneNumber && phoneNumber.isValid();
    // };
    
    // Inside the <PhoneInput /> component
  
    const validateFields = (section) => {
        const errors = {};
        if (section === 1) {
            if (!trialForm.firstName.trim()) errors.firstName = 'First name is required';
            if (!trialForm.lastName.trim()) errors.lastName = 'Last name is required';
            if (!trialForm.companyName.trim()) errors.companyName = 'Company name is required';
        } else if (section === 2) {
            if (!trialForm.country.trim()) errors.country = 'Country is required';
            if (!trialForm.email.trim()) errors.email = 'Email is required';
            if (!phone.trim()) errors.phone = 'Phone number is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTrialForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_HOST_NAME}/check-cookie`)
            .then((response) => {
                setCookieCheck(response.data);
                // console.log("Already Login");
                
                if (response?.data?.role?.includes('WEBSITE_USER')) {

                    if (response?.data?.licenseId) {
                        window.location.href = '/cms/company/list';
                    }
                    else {
    
                        navigate(`/cms/licenseInfo/${response?.data?.id}`)
                    }
            
                }
                // console.log(response.data);
                
            })
            .catch((err) => {
                // console.error(err);
                // navigate('/login');
            });
    }, []);

const [trialRequest,setTrialRequest]=useState(false);

    const handleTrialSubmit = (e) => {
        e.preventDefault();
     
        
        if (!validatePhone()) {
            // setIsPhoneValid(false);
            toast.error("Please input valid phone number.")
            return;
        }
        if (trialForm.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trialForm.email)) {
            toast.error("Please input a valid Email ID");
            return;
        }
     
     
        const payload = {
            firstName: trialForm.firstName,
            lastName: trialForm.lastName,
            email: trialForm.email,
            country: trialForm.country,
            phone: trialForm.phone,
            companyName: trialForm.companyName,
            product: trialForm.product,
            type: trialForm.type,
            ...(trialForm.channel_partner_type && {
                channel_partner_type: trialForm.channel_partner_type !== 'Other'
                    ? trialForm.channel_partner_type
                    : trialForm.other !== ''
                        ? trialForm.other
                        : "Other"
            })
        };
      setTrialRequest(true);
        if (validateFields(2)) {
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/register`, trialForm)
                .then(response => {
                    const userId = response?.data?.data?._id;
                    if (userId) {
                        const query = new URLSearchParams(location.search);
                        query.set('userId', userId);
                        query.set('firstPart', 'true');
                        query.set("customerType", trialForm.type);
                        navigate(`/thankyouSignUp?${query.toString()}`, {
                            state: { myProp: 'trialRequested' },
                        });
                    } else {
                        toast.error("User ID not found in the response.");
                    }
                    setTrialRequest(false);

                    setFormSubmitted(true);
                })
                .catch(err => {
                    console.log(err, "error");
                    setFormSubmitted(false);
                    setTrialRequest(false);

                    toast.error(err?.response?.data?.error|| err?.response?.data || err?.message);
                });
        } else {
            setWarning(true);
            setTrialRequest(false);
        }
    };

    const handleNext = () => {
        setFormSubmitted(true);
        if (validateFields(1)) {
            setFormSection(2);
            setWarning(false);
        } else {
            setWarning(true);
        }
    };

    const handleBack = () => {
        setFormSection(1);
    };
    // console.log(trialForm, "Trial Form");

    return (
        <>
            <Helmet>
                <title>Matisoft | Sign up</title>
            </Helmet>
            <Header />
            <div className='w-full min-h-screen h-full signUp_container'>
                <div className='z-10 w-full relative'>
                    <div className='w-full text-white p-4 mx-auto'>
                        <p className='font-[500] w-full md:w-[50vw] mx-auto text-[1.2em]  sm:text-[text-1.3em] md:text-[1.4em] py-[1vh] text-center'>
                            Request a free trial for our unparalleled solutions
                        </p>
                    </div>
                    <div className='w-full md:w-[65vw] lg:w-[62vw] mx-auto bg-blue-100 px-[1vw] py-[0.5vh]'>
                        <div className='px-[1vw] py-[1vh] w-full h-full'>
                            <div className='text-[1em]'>
                                <form onSubmit={handleTrialSubmit}>
                                    <h2 className="text-[0.9em] py-[1vh]">Who am I?</h2>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-y-[1vh] gap-x-[4vw]'>
                                        <div className="flex flex-col md:flex-row gap-y-[1vh] md:gap-y-0 md:gap-x-[3vw] text-[0.8em]">
                                            <label className="flex items-center gap-x-[0.5vw]">
                                                <input
                                                    type="radio"
                                                    name="type"
                                                    value="END_USER"
                                                    required
                                                    checked={trialForm.type === 'END_USER'}
                                                    onChange={handleInputChange}
                                                />
                                                End User
                                            </label>
                                            <label className="flex items-center gap-x-[0.5vw]">
                                                <input
                                                    type="radio"
                                                    name="type"
                                                    value="CHANNEL_PARTNER"
                                                    required
                                                    checked={trialForm.type === 'CHANNEL_PARTNER'}
                                                    onChange={handleInputChange}
                                                />
                                                Channel Partner
                                            </label>
                                        </div>
                                        {trialForm.type === 'CHANNEL_PARTNER' && (
                                            <div className="flex flex-row gap-x-1 ">
                                                <select
                                                    id="channel_partner_type"
                                                    className={`${trialForm.channel_partner_type ? "text-black" : "text-gray-400"
                                                        } focus:text-black form-input bg-white`}
                                                    name="channel_partner_type"
                                                    value={trialForm.channel_partner_type}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value="" disabled className="text-gray-400">
                                                        Channel partner type*
                                                    </option>
                                                    <option value="Dealer">Dealer</option>
                                                    <option value="Distributor">Distributor</option>
                                                    <option value="MSP">MSP</option>
                                                    <option value="MSSP">MSSP</option>
                                                    <option value="System Integrator">System Integrator</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {trialForm.channel_partner_type === 'Other' && (
                                                    <input
                                                        value={trialForm.other}
                                                        onChange={handleInputChange}
                                                        className="px-1 border border-gray-400 rounded focus:outline-none focus:ring-1 focus:ring-[#4fd0ff] w-[150px] text-[0.7em]"
                                                        name="other"
                                                        placeholder="Please specify..."
                                                        type="text"
                                                        maxLength='50'
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="pt-[1vh]  grid grid-cols-1 md:grid-cols-2 gap-y-[1vh] gap-x-[4vw]">
                                        <input
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name*"
                                            value={trialForm.firstName}
                                            onChange={handleInputChange}
                                            required
                                            maxLength="40"
                                            className={`form-input ${formSubmitted && !trialForm.firstName.trim() ? 'border-red-500' : ''}`}
                                        />
                                        <input
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name*"
                                            value={trialForm.lastName}
                                            onChange={handleInputChange}
                                            required
                                            maxLength="40"
                                            className={`form-input ${formSubmitted && !trialForm.lastName.trim() ? 'border-red-500' : ''}`}
                                        />
                                        <input
                                            type="text"
                                            name="companyName"
                                            placeholder="Your Organization Name*"
                                            value={trialForm.companyName}
                                            onChange={handleInputChange}
                                            required
                                            maxLength="40"
                                            className="form-input"
                                        />
                                        <select
                                            value={countryList.find((country) => country.name === trialForm.country)?.isoCode || ""}
                                            onChange={handleCountryChange}
                                            className="form-input bg-white" required
                                        >
                                            <option value="" disabled>
                                                -- Select a Country --
                                            </option>
                                            {countryList.map((country) => (
                                                <option key={country.isoCode} value={country.isoCode}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>

                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Business Email*"
                                            value={trialForm.email}
                                            maxLength="254"
                                            onChange={handleInputChange}
                                            required

                                            className="form-input"
                                        />
                                        <PhoneInput
                                            country={countryCode}
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                placeholder: 'Enter Phone Number*',
                                            }}
                                            containerClass="w-full"
                                            inputClass="form-input"
                                            inputStyle={{
                                                height: '40px',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                borderRadius: '4px',
                                                padding: '0.5em 1em',
                                                backgroundColor: 'white',
                                                fontSize: '1em',
                                                marginLeft: '11%'
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            dropdownStyle={{
                                                height: '300px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                            }}
                                            enableSearch
                                            // isValid={(value, country) => validatePhone(value, country.iso2)}

                                        />
                                    </div>

                                    <div className="pt-[2vh] text-gray-800 font-[300] text-center">
                                        <span className="text-[0.6em]">
                                            By clicking submit, you agree to Matisoft's{' '}
                                            <a href="/privacy-policy" target="_blank" className="underline text-blue-700 hover:text-[#4fd0ff]">
                                                Privacy Policy
                                            </a>.
                                        </span>
                                    </div>
                                    <div className="flex justify-center">
                                        <button type="submit" className="button-new text-[0.8em] w-[30vh]" disabled={trialRequest}>{trialRequest ? "Loading..." : "Submit"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUp;