const antivirusList = [
    "AVG", "Avast", "Bitdefender", "Carbon Black", "CrowdStrike",  "Cybereason","Cylance",
    "DeepInstinct", "Eset", "F-Secure", "FireEye", "Kaspersky",
    "Malwarebytes", "McAfee", "Microsoft", "Quick Heal", "SentinelOne","Seqrite", "Sophos",
    "Symantec", "Trend Micro", "Webroot", "Others", "None"
];

const dlpList = [
    "Check Point Software", "Digital Guardian (HelpSystems)",
    "Forcepoint", "McAfee (Trellix)", "Microsoft Purview",
    "Symantec (Broadcom)", "Trend Micro",
    "Others", "None"

];

const jobList = [
 
    "Business Development",
    "CEO",
    "CIO",
    "CISO/CSO",
    "CMO",
    "COO",
    "CTO",
    "Director",
    "IT Professional",
    "Reseller",
    "Sales/Marketing",
    "VP",
    "Others"

];

const industryList = [
    "Accounting", "Administration & Office Support",
    "Advertising, Arts & Media",
    "Banking & Financial Services",
    "Call Centre & Customer Service",
    "Channel Partner",
    "Community Services & Development",
    "Construction",
    "Consulting & Strategy",
    "Dealer Distributor",
    "Defence", "Design & Architecture",
    "Education & Training", "Engineering",
    "Farming, Animals & Conservation",
    "Government",
    "Healthcare & Medical",
    "Hospitality & Tourism",
    "Human Resources & Recruitment",
    "Information & Communication Technology",
    "Insurance & Superannuation", "Legal",
    "Manufacturing, Transport & Logistics",
    "Marketing & Communications",
    "Mining, Resources & Energy",
    "MSSP", "Real Estate & Property",
    "Retail & Consumer Products", "Sales",
    "Science & Technology", "SI",
    "Sport & Recreation", "Trades & Services", "Others"

];
const companySize = [
    "Less than 25",
    "25-50",
    "50-100",
    "100-200",
    "200-500",
    "500-1000",
    "1000+"
];

export { antivirusList, dlpList, jobList, industryList, companySize };