import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";

const ParticlesBackground = () => {
    const containerRef = useRef(null);
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, [init]);

    const particlesLoaded = useCallback((container) => {
        containerRef.current = container;
        window.particlesContainer = container;
    }, []);

    const options = useMemo(() => {
        return {
            fullScreen: {
                zIndex: -1,
            },
            background: {
                color: "#07070c", // Consistent dark blue background
            },
            particles: {
                number: {
                    value: 50,
                },
                links: {
                    enable: true,
                    color: "#FFFFFF",
                },
                move: {
                    enable: true,
                },
                color: {
                    value: "#4fd0ff", // White particles for contrast
                },
            },
        };
    }, []);

    return (
        <div className="fixed inset-0 bg-darkBlue"> {/* Tailwind utility classes */}
            {init && (
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={options}
                    className="absolute top-0 left-0 w-full h-full"
                />
            )}
        </div>
    );
};

export default ParticlesBackground;
