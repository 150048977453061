import React, { useEffect, useState } from 'react'
// import Sidebar from '../../common/Sidebar'
import { TbCoinFilled } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux'
import { setCompanyId, setPermissions, setUserId } from '../../../Redux/companyid/companyid.action'
import axios from 'axios'
import { toast } from 'react-toastify'
import { fetchUSERLicenceList } from '../../../Redux/userLicenceList/userLicence.action'
import { ImCross } from 'react-icons/im'
import { PiCoinsFill } from "react-icons/pi";
import { RiCoinsFill } from "react-icons/ri";
import { RiCopperCoinFill } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { RiComputerLine } from "react-icons/ri";
import { LuHistory } from "react-icons/lu";
import Cookies from 'js-cookie';
import LinkedDevices from './LinkedDevices';
import LicenseHistory from '../../components/LicenseHistory';
import { FaKey } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import Sidebar1 from '../../common/Sidebar1';
import { Helmet } from 'react-helmet';
import Loader from '../../common/Loader';
import { NavLink, useNavigate } from 'react-router-dom';
// import LicenseHistory from '../../components/LicenseHistory';
import { PiWarningCircleBold } from "react-icons/pi";
import { setProductData } from '../../../Redux/linkedDeviceDetail/linkedDeviceDetail.action';
import { checkUserAuth } from '../../../Redux/userAuth/userAuth.action';
import { MdDelete } from 'react-icons/md';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import { IoMdNotifications } from "react-icons/io";
import Error from '../../../pages/Error';
import { Oval } from 'react-loader-spinner';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const LicenseDetail1 = () => {
    const navigate = useNavigate();
    const { loading, companyId, userId, orgId, userLicenceList, permissions, userAuthData, errorUserAuth } = useSelector(state => ({
        loading: state.userLicenceList.loading,
        companyId: state.companyid.companyId,
        userId: state.companyid.userId,
        orgId: state.companyid.orgId,

        userLicenceList: state.userLicenceList.userLicenceList,
        permissions: state.companyid.permissions,
        userAuthData: state.userAuth.userAuthData,
        errorUserAuth: state.userAuth.errorUserAuth
    }))
    const dispatch = useDispatch();
    const [sendToInstaller, setSendToInstaller] = useState(false);
    const [linkingWindow, setLinkingWindow] = useState(false);
    const [licenceKey, setLicenceKey] = useState('');
    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [editWindow, setEditWindow] = useState(false);
    const [updatePage, setUpdatePage] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [timer, setTimer] = useState(0);

    const COUNTER_KEY = 'my-timer-counter-ReqKey'; // Key to store timer value in localStorage
    const START_TIME_KEY = 'my-timer-start-time-ReqKey'; // Key to store the start time
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedStartTime = localStorage.getItem(START_TIME_KEY);
        if (savedStartTime) {
            const elapsed = Math.floor(Date.now() / 1000) - parseInt(savedStartTime, 10);
            const initialTimeLeft = Math.max(180 - elapsed, 0); // Calculate remaining time
            return initialTimeLeft;
        }
        return 180; // Default to 180 seconds if no start time exists
    });

    const [isActive, setIsActive] = useState(() => {
        const startTime = localStorage.getItem(START_TIME_KEY);
        return !!startTime; // If there's a saved start time, consider the timer active
    });

    useEffect(() => {
        let timer;

        const calculateTimeLeft = () => {
            const startTime = parseInt(localStorage.getItem(START_TIME_KEY), 10);
            if (!startTime) return;

            const currentTime = Math.floor(Date.now() / 1000);
            const elapsedTime = currentTime - startTime;
            const remainingTime = 180 - elapsedTime; // Assuming timer was initially 120 seconds

            if (remainingTime > 0) {
                setTimeLeft(remainingTime);
            } else {
                // If time has expired, stop the timer and clean up localStorage
                setTimeLeft(0);
                localStorage.removeItem(COUNTER_KEY);
                localStorage.removeItem(START_TIME_KEY);
                setIsActive(false);
            }
        };

        if (isActive && timeLeft > 0) {
            timer = setInterval(() => {
                calculateTimeLeft();
            }, 1000);
        }

        // Clean up timer on component unmount or when the timer is paused
        return () => clearInterval(timer);
    }, [isActive, timeLeft]);

    useEffect(() => {
        if (timeLeft <= 0 && isActive) {
            localStorage.removeItem(COUNTER_KEY);
            localStorage.removeItem(START_TIME_KEY);
            setIsActive(false);
        }
    }, [timeLeft, isActive]);

    const startTimer = () => {
        const startTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        localStorage.setItem(START_TIME_KEY, startTime);
        localStorage.setItem(COUNTER_KEY, 180); // Save initial time (120 seconds)
        setIsActive(true);
        setTimeLeft(180); // Reset the timer    
    };

    // Format the time left for the OTP timer
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('/').filter(Boolean); // Split and filter out empty parts

        // Extract the user ID and company ID from the appropriate positions
        const userid = parts[parts.length - 1]?.split('?')[0]; // Extract user ID, ignoring query params
        const companyID = parts[parts.length - 2];

        dispatch(setUserId(userid));

        if (userid) {
            dispatch(fetchUSERLicenceList(userid));
        }
    }, [dispatch, updatePage]); // Dependency on `updatePage`


    const [country, setCountry] = useState('');  // This will handle the country flag

    const [initialFormData] = useState({
        street_address: '',
        state: '',
        zip_code: '',
        country: '',
        city: '',
        phone_number: '',
        gst_no: '',
        pan_no: '',
        landline_no: '',
        billing_name: userLicenceList.name

    });

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target ? e.target : { name: e.name, value: e.value };
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

        if (name === 'country') {
            setSelectedCountry(value);
            setFormData((prevFormData) => ({ ...prevFormData, state: '', city: '' })); // Reset state and city when country changes
        } else if (name === 'state') {
            setFormData((prevFormData) => ({ ...prevFormData, city: '' })); // Reset city when state changes
        }
    };
    const handlePhoneChange = (phone, countryData) => {
        setFormData((prevState) => ({
            ...prevState,
            phone: phone,  // Updates the phone number field
        }));
        setCountry(countryData.countryCode);  // Set country code based on input
    };
    const validate = () => {
        let errors = {};
        let isValid = true;

        if (!formData.street_address) {
            isValid = false;
            errors["street_address"] = "Please enter your address.";
        }

        if (!formData.zip_code) {
            isValid = false;
            errors["zip_code"] = "Please enter your pincode.";
        } else if (!/^[0-9]{5,6}$/.test(formData.zip_code)) {
            isValid = false;
            errors["zip_code"] = "Please enter a valid 5 or 6 digit pincode.";
        }

        if (!formData.country) {
            isValid = false;
            errors["country"] = "Please select your country.";
        }

        if (!formData.city) {
            isValid = false;
            errors["city"] = "Please enter your city.";
        }

        if (!formData.state) {
            isValid = false;
            errors["state"] = "Please enter your state.";
        }

        setErrors(errors);
        return isValid;
    };
    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryid(country.id);
        setFormData((prevFormData) => ({ ...prevFormData, country: country.name, state: '', city: '' }));
    };
    const handleStateChange = (state) => {
        setstateid(state.id)
        setFormData((prevFormData) => ({ ...prevFormData, state: state.name, city: '' }));
    };
    const handleCityChange = (city) => {
        setFormData((prevFormData) => ({ ...prevFormData, city: city.name }));
    };
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [emailAndPhoneWindow, setEmailAndPhoneWindow] = useState({
        type: '',
        open: false
    });
    const validatePhone = (combinedPhone) => {
        // Parse the phone number directly
        const phoneNumber = parsePhoneNumberFromString(combinedPhone);
        // Validate the phone number
        return phoneNumber && phoneNumber.isValid();
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Create a filtered object with only fields that have values
        const payload = {};
        Object.keys(formData).forEach(key => {
            if (formData[key]) { // Only include fields that are not empty or falsy
                payload[key] = formData[key];
            }
        });

        // Add any other required fields that are not part of formData
        payload.user_id = userId;
        //   console.log('Phone in payload:', payload.phone_number);
        if (payload.phone_number && !validatePhone(payload.phone_number)) {
            toast.error("Please input a valid phone number.");
            return;
        }

        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/address/add`, payload)
            .then(response => {
                if (response.headers['content-type'].includes('application/json')) {
                    // If the response is JSON, parse it and log the data
                    // console.log("JSON Response:", response.data);
                } else {
                    // If the response is not JSON, log the response as text
                }

                setEditWindow(false);
                toast.success( response?.data?.message || response?.data);
                // console.log(response);
                
                setUpdatePage(!updatePage);
            })
            .catch(error => {

                toast.error(error?.response?.data || error?.message);

                // toast.error();

                setEditWindow(false);
                // console.log("Error:", error);
            });
    };

    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredItemIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredItemIndex(null);
    };
    const [historyWindow, setHistoryWindow] = useState(false);
    const [isSidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };

    const handleLinkKey = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/key/link`,
            {
                "userId": userId,
                "licenseKey": licenceKey
            }
        )
            .then(response => {
                if (response.headers['content-type'].includes('application/json')) {
                    // If the response is JSON, parse it and log the data

                } else {
                    // console.log(response);
                }
                toast.success(response?.data);
                setUpdatePage(!updatePage);
                setLicenceKey('');
                setLinkingWindow(!linkingWindow);
                setSendToInstaller(true);
            })
            .catch(error => {
                toast.error(error?.response?.data || error?.message);
                // console.log(error, "error");
            });

    }
    const [isEmailValid, setIsEmailValid] = useState(false);

    const handleEdit = () => {
        setFormData({
            street_address: userLicenceList?.address?.street_address,
            zip_code: userLicenceList?.address?.zip_code,
            country: userLicenceList?.address?.country,
            city: userLicenceList?.address?.city,
            state: userLicenceList?.address?.state,
            gst_no: userLicenceList?.address?.gst_no,
            pan_no: userLicenceList?.address?.pan_no,
            landline_no: userLicenceList?.address?.landline_no,
            billing_name: userLicenceList?.address?.billing_name || userLicenceList?.name,
            phone_number: userLicenceList?.address?.phone_number
            // subscreiber_emails: userLicenceList?.subscreiber_emails || []
        });

    };
    const handleHistoryNavigate = (key, id) => {
        // Create a new instance of URLSearchParams
        const queryParams = new URLSearchParams();

        // Add the key to the query parameters
        // queryParams.set("Key", key);

        // Navigate to the desired route with query params
        navigate(`/cms/licenseHistory/${userId}`);
    };

    const handleLinkedDeviceNavigate = (productCode, deviceCount, productName, orgId, licenseId) => {
        const data = {
            productCode,
            deviceCount,
            productName,
            orgId,
            dueDate: userLicenceList?.linked_license?.license_due_date, // Adjust as needed
            licenseId,
        };

        // Dispatch the data
        dispatch(setProductData(data));

        // Navigate with state
        const queryParams = new URLSearchParams(data).toString();
        // localStorage.setItem(`${productName}linkedDeviceData`, JSON.stringify(data));

        // Navigate to the URL with query params
        navigate(`/cms/linkeddevices/${productName}/${userId}?${queryParams}`);
    };

    // Email validation function
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    const handleInputChange = (name, value, extraData = {}) => {
        switch (name) {
            case 'country':
                setSelectedCountry(value);
                setCountryid(extraData.id);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    country: value,
                    state: '',
                    city: '',
                }));
                break;

            case 'state':
                setstateid(extraData.id);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    state: value,
                    city: '',
                }));
                break;

            case 'city':
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    city: value,
                }));
                break;

                case 'phone':
                    const formattedPhoneNumber = value.startsWith('+') ? value : `+${value}`;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone_number: formattedPhoneNumber,
                    }));
                    setCountry(extraData.countryCode);
                    break;
                

            default:
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value,
                }));
                break;
        }
    };

    // Unified handle change function for all inputs

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCancel = () => {
        // Show the confirmation dialog
        setShowConfirmation(true);
    };
    const [requestData, setRequestData] = useState({
        window: false,
        message: ''
    })
    const [rechargeReq, setRechargeReq] = useState(false);
    const [requestHold, setRequestHold] = useState(false);
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [errorRequestSent, setRequestSent] = useState(false);
    const handleRequestKey = (e) => {
        e.preventDefault();
        setRequestHold(true); // Set to true before starting the request

        axios
            .post(`${process.env.REACT_APP_HOST_NAME}/api/license/request/or/renewal`, {
                email: userAuthData?.email
            })
            .then((response) => {
                toast.success(response?.data?.message);
                setRequestData({ window: false, message: '' });
                const otpTimerDuration = 180; // Set timer for 3 minutes
                localStorage.setItem('otpTimerReqKey', otpTimerDuration); // Save the initial timer value in local storage
                localStorage.setItem('otpStartTimeReqKey', Math.floor(Date.now() / 1000)); // Save the current time in seconds
                setTimer(otpTimerDuration); // Initialize the timer state
                startTimer();
                localStorage.removeItem("ReqSentError");

            })
            .catch((error) => {
                // console.log(error);
                setRequestSent(true);
                localStorage.setItem("ReqSentError", true);
                toast.error(error?.response?.data || error?.message);
            })
            .finally(() => {
                setRequestHold(false); // Set to false after request completes
            });
    };

    const handleConfirmCancel = () => {
        // Handle the cancellation (e.g., reset form, navigate away, etc.)
        setFormData(initialFormData);
        setShowConfirmation(false);
        setEditWindow(false);
        // Reset or clear the form data if needed
    };

    const handleCloseDialog = () => {
        // Close the confirmation dialog
        setShowConfirmation(false);
    };
    if (loading) return <Loader />;
    if (errorUserAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | License Info</title>
            </Helmet>
            {/* Sidebar */}
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/14 z-[70]'} bg-gray-200  h-full fixed `}>
                {/* Sidebar Content Goes Here */}
                <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            </div>

            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'} z-30 relative overflow-x-auto pt-[5vh] pb-[2vh] licenseDetail`} style={{ fontSize: '18px' }}>
                {linkingWindow && <div className={` ${linkingWindow ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[20] bg-gray-600 opacity-50  `} ></div>}
                {linkingWindow && (
                    <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50 ">
                        <div className="bg-blue-100 opacity-[0.96] border-blue-400 border-[1px] rounded w-[40vw] p-4 relative text-[0.9em]">
                            <button
                                className="absolute right-[-5px] top-[-5px]"
                                onClick={() => setLinkingWindow(!linkingWindow)}
                            >
                                <ImCross className="text-white bg-red-600 p-1 text-xl font-[200] rounded-full" />
                            </button>
                            <form onSubmit={handleLinkKey} className="px-[2vw] py-[0.5vh]">
                                <div className="pb-[1.2vh]">
                                    <label className="pr-[2vw] text-[1.1em] pb-[1.2vh] block">Enter License Key:</label>
                                    <input
                                        type="text"
                                        className="w-[25vw] px-[2px] py-[0.5vh] border-blue-300 border-[1px]"
                                        placeholder='XXXXX-XXXXX-XXXXX-XXXXX-XXXXX'
                                        value={licenceKey}
                                        maxLength="30"
                                        onChange={(e) => setLicenceKey(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="flex flex-row pt-[1vh]">
                                    <PiWarningCircleBold className="text-[1.1em]" />
                                    <span className="pl-[0.5vw] text-[0.9em]">Please enter in capital letters. The key is case-sensitive.</span>
                                </div>
                                {userLicenceList?.linked_license?.key && (
                                    <div className="flex flex-row relative top-[1vh]">
                                        <PiWarningCircleBold className="text-[1.1em] flex-shrink-0" />
                                        <span className="pl-[0.5vw] text-[0.9em]">If a new key is linked, the previous key's credits will be lost and cannot be used in the future.</span>
                                    </div>
                                )}
                                <div className="flex flex-row justify-end gap-x-2 mt-[2vh]">
                                    <button
                                        className="bg-[#2e84a3] border border-1 border-[#2e84a3] text-white px-[2vw] py-[1vh] shadow-md rounded-sm hover:bg-white hover:text-[#276f8a] transition"
                                    >
                                        Apply
                                    </button>
                                    <button
                                        type="button"
                                        className="bg-[#2e84a3] border border-1 border-[#2e84a3] text-white px-[2vw] py-[1vh] shadow-md rounded-sm hover:bg-white hover:text-[#276f8a] transition"
                                        onClick={() => setLinkingWindow(!linkingWindow)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {sendToInstaller && (
                    <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-blue-100 opacity-[0.96] border-blue-400 border-[1px] rounded w-[40vw] p-4 relative text-[0.9em]">
                            <button
                                className='absolute right-[-5px] top-[-5px]'
                                onClick={() => { setSendToInstaller(!sendToInstaller) }}
                            >
                                <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' onClick={() => setLinkingWindow(false)} />
                            </button>

                            <div className="text-[1.1em] text-justify p-[2vh]">
                                To begin using Matisoft's products, please download the installer from the&nbsp;
                                <NavLink
                                    to={`/cms/installer/${userAuthData.companyId}/${userId}`}
                                    className="text-blue-600 underline hover:text-blue-700 font-semibold"
                                >
                                    Installer
                                </NavLink>
                                &nbsp;page.
                            </div>

                        </div>
                    </div>
                )}
                <div className='px-[1vw] py-[1vh]'>

                    <h1 className=' text-[1.2em] py-[0.5vh] mb-1 text-primary shadow-lg text-center bg-[#e3f2f5] px-[0.5vw] relative'>License Information
                        {/* <span className='absolute right-3 top-1' title='License History'><LuHistory onClick={() => handleHistoryNavigate(userLicenceList?._id, userLicenceList?.linked_license?.key)} className='hover:text-blue-600 cursor-pointer' /></span> */}
                    </h1>

                    {!userLicenceList?.linked_license?.key ? (<div className='glowing-div1 bg-blue-100 flex flex-row justify-center items-center py-[0.5vh]'>                            <div className="text-[0.9em] text-red-600 "> {/* Apply the red color here */}
                        <p className="flex flex-row pl-2">
                            No license key is linked to your account. If you already have the key, link it below; otherwise,
                        </p>
                    </div>

                        <div className='flex flex-row gap-x-2 items-center text-red-600'> {/* Apply the red color here */}
                            <span className='text-[0.8em]'></span>
                            <button
                                className={`text-[0.9em] px-[0.7vw] py-[0.2vh] rounded-sm border border-1 border-blue-600 bg-blue-600 text-white hover:text-blue-600 hover:bg-white`}
                                disabled={requestHold || isActive}
                                onClick={handleRequestKey}>
                                {requestHold ? (
                                    <div className='flex flex-row gap-x-1 items-center text-gray-800'>
                                        Loading <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                    </div>
                                ) : isActive ? "Resend Request" : localStorage.getItem("ReqSentError") ? "Resend Request" : "Request Key"}
                            </button>
                            {isActive && (
                                <span className='text-xs text-gray-600'>
                                    {`Resend request after ${formatTime(timeLeft)}`}
                                </span>
                            )}
                            {localStorage.getItem("ReqSentError") && (
                                <>
                                    <span className='text-[0.8em]'> or </span>
                                    <a className='underline text-blue-600 hover:text-blue-700 text-[0.8em]' target='_blank' href='/contact'>Contact us</a>
                                </>
                            )}
                        </div>
                    </div>

                    ) : <div></div>}
                    <div className='flex flex-row justify-between items-center'>
                        {userLicenceList?.linked_license?.status === 'EXPIRED' ? (
                            <div className="text-[0.9em] text-red-600 glowing-div1">
                                Your license has expired. Please click the "Recharge" button to renew it.
                            </div>

                        ) : <div></div>}

                        {userLicenceList?.linked_license?.key && (
                            <div className="min-w-[25vw] flex gap-2 text-[0.9em]">
                                <button
                                    onClick={() => handleHistoryNavigate(userLicenceList?._id, userLicenceList?.linked_license?.key)}
                                    className="border bg-white border-blue-600 text-blue-600 shadow-lg px-3 py-1 hover:bg-blue-700 hover:text-white rounded-sm"
                                >
                                    Transaction History
                                </button>
                                <button
                                    className="bg-white text-red-600 border border-red-600 shadow-lg px-3 py-1 hover:bg-red-600 hover:text-white rounded-sm"
                                    onClick={handleRequestKey}
                                    disabled={isActive || requestHold}
                                    title={isActive || requestHold ? 'Request sent' : ''}
                                >
                                    {requestHold ? "Loading..." : "Recharge"}
                                </button>
                            </div>
                        )}
                    </div>

                    <div className='border-gray-300  border-[1px] py-[0.5vh] mt-1  px-[2vw] text-gray-800'>
                        <div className='py-[1.2vh] flex flex-row'>
                            <div className='min-w-[240px] font-[500]'>Organization Name :</div>
                            <span>{userLicenceList?.name} </span>
                        </div>

                        <div className='py-[1.2vh] flex flex-row'>
                            <div className='min-w-[240px] font-[500]'>Billing Details :</div>
                            <span className='font-[400]'>
                                {userLicenceList?.address?.street_address || userLicenceList?.address?.city || userLicenceList?.address?.state || userLicenceList?.address?.country || userLicenceList?.address?.zip_code ? (
                                    <>
                                        {(() => {
                                            const { billing_name, street_address, city, state, country, zip_code } = userLicenceList.address;
                                            const address = [billing_name, street_address, city, state, country, zip_code]
                                                .filter(Boolean) // Filter out empty values
                                                .join(', '); // Join values with commas

                                            return address ? `${address}...` : ''; // Append '...' if address is non-empty
                                        })()}

                                        <CiEdit onClick={() => {
                                            handleEdit();
                                            setEditWindow(true);
                                        }} className="inline-block ml-2 cursor-pointer text-blue-800 hover:text-purple-600" />
                                    </>
                                ) : (
                                    <button
                                        className=' underline text-blue-700 py-[0.4vh]   hover:text-purple-600'
                                        onClick={() => setEditWindow(true)}
                                    >
                                        <CiEdit />
                                    </button>
                                )}

                            </span>

                        </div>
                        {editWindow && (<div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-xs">
                            <div className="bg-blue-100 opacity-[0.96] border-blue-400 border-[1px] rounded w-[88vw] h-[90vh] p-4 relative text-[0.9em]">
                                <button className='absolute right-[-5px] top-[-5px]' onClick={() => setEditWindow(false)}>
                                    <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                                </button>
                                <h1 className="text-[1.2em] font-semibold  text-center shadow-md text-blue-800 pl-[1vw] py-[1vh]">
                                    Kindly Fill Your Billing Details
                                </h1>
                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-[2vh] mt-[3vh] mx-auto"> {/* Adjusting gap with vh */}

                                        <div className="flex flex-col">
                                            <label htmlFor="street_address" className="mb-[1vh] font-[500]">Billing Name:</label>
                                            <input
                                                type="text"
                                                id="billing_name"
                                                name="billing_name"
                                                maxLength="50"
                                                value={formData.billing_name || userLicenceList?.name}
                                                onChange={(e) => handleInputChange('billing_name', e.target.value)} // Adjusted for direct value
                                                className="border border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />
                                            {errors.street_address && <span className="text-red-500 text-sm">{errors.street_address}</span>}
                                        </div>

                                        {/* Billing Address */}
                                        <div className="flex flex-col">
                                            <label htmlFor="street_address" className="mb-[1vh] font-[500]">Billing Address:</label>
                                            <input
                                                type="text"
                                                id="street_address"
                                                name="street_address"
                                                maxLength="60"
                                                value={formData.street_address}
                                                onChange={(e) => handleInputChange('street_address', e.target.value)} // Adjusted for direct value
                                                className="border border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />
                                            {errors.street_address && <span className="text-red-500 text-sm">{errors.street_address}</span>}
                                        </div>

                                        {/* Pincode */}
                                        <div className="flex flex-col">
                                            <label htmlFor="zip_code" className="mb-[1vh] font-[500]">Pincode:</label>
                                            <input
                                                type="text"
                                                id="zip_code"
                                                name="zip_code"
                                                value={formData.zip_code}
                                                onChange={(e) => handleInputChange('zip_code', e.target.value)} // Adjusted for direct value
                                                maxLength="10"

                                                className="border border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />
                                            {errors.zip_code && <span className="text-red-500 text-sm">{errors.zip_code}</span>}
                                        </div>

                                        {/* Country */}
                                        <div className="flex flex-col">
                                            <label className="mb-[1vh] font-[500]">Country:</label>
                                            <CountryDropdown
                                                classes="border border-gray-300 p-[2vh] rounded-lg bg-white"
                                                value={formData.country}
                                                onChange={(val) => {
                                                    handleInputChange('country', val, { id: val.id }); // Adjusted for name
                                                }}
                                            />
                                            {errors.country && <span className="text-red-500 text-sm">{errors.country}</span>}
                                        </div>

                                        {/* State */}
                                        <div className="flex flex-col">
                                            <label className="mb-[1vh] font-[500]">State:</label>
                                            <RegionDropdown
                                                classes="border border-gray-300 p-[2vh] rounded-lg bg-white"
                                                country={formData.country}
                                                value={formData.state} name="state"
                                                onChange={(val) => {
                                                    handleInputChange('state', val, { id: val.id }); // Adjusted for name
                                                }}
                                            />
                                        </div>

                                        {/* City */}
                                        <div className="flex flex-col">
                                            <label htmlFor="city" className="mb-[1vh] font-[500]">Town/City Name:</label>
                                            <input
                                                type="text"
                                                id="city" name="city"
                                                value={formData.city}
                                                onChange={(e) => handleInputChange('city', e.target.value)} // Adjusted for direct value
                                                maxLength="25"
                                                className="border border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />
                                            {errors.city && <span className="text-red-500 text-sm">{errors.city}</span>}
                                        </div>

                                        <div className="flex flex-col">
                                            <label htmlFor="street_address" className="mb-[1vh] font-[500]">Landline Number:</label>
                                            <input
                                                type="text"
                                                id="landline_no"
                                                name="landline_no"
                                                maxLength="15"
                                                value={formData.landline_no}
                                                onChange={(e) => handleInputChange('landline_no', e.target.value)} // Adjusted for direct value
                                                className="border border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />
                                            {errors.street_address && <span className="text-red-500 text-sm">{errors.street_address}</span>}
                                        </div>
                                        {/* Phone Number */}
                                        <div className="flex flex-col">
                                            <label className="mb-[1vh] font-[500]">Phone Number:</label>
                                            <PhoneInput
                                                country={country || undefined}
                                                value={formData.phone_number}
                                                onChange={(value) => handleInputChange('phone', value)} // Adjusted to correctly pass phone number
                                                inputProps={{
                                                    name: 'phone',
                                                    // required: true,

                                                    autoFocus: false,
                                                    placeholder: 'Enter phone number',
                                                }}
                                                containerStyle={{ height: '7vh' }}
                                                inputStyle={{
                                                    height: '7vh',
                                                    width: 'calc(100% - 11%)',
                                                    marginLeft: '11%',
                                                    border: '1px solid #d1d5db',
                                                    padding: '1.5vh 1vw',
                                                }}
                                                dropdownStyle={{ height: '30vh', bottom: '100%', transform: 'translateY(-5px)' }}
                                                disableSearchIcon
                                                enableSearch
                                            // disableCountryCode
                                            />
                                        </div>

                                        {/* GST Number */}
                                        <div className="flex flex-col">
                                            <label htmlFor="gst_no" className="mb-[1vh] font-[500]">GST Number:</label>
                                            <input
                                                type="text" // Changed to text for GST number as it can contain letters
                                                id="gst_no"
                                                name="gst_no"
                                                value={formData.gst_no}
                                                onChange={(e) => handleInputChange('gst_no', e.target.value.toUpperCase())} // Converts input to uppercase
                                                maxLength="15"
                                                className="border uppercase border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />

                                            {/* {errors.gst_no && <span className="text-red-500 text-sm">{errors.gst_no}</span>} */}
                                        </div>

                                        {/* PAN Number */}
                                        <div className="flex flex-col">
                                            <label htmlFor="pan_no" className="mb-[1vh] font-[500]">PAN:</label>
                                            <input
                                                type="text"
                                                id="pan_no"
                                                name="pan_no"
                                                value={formData.pan_no}
                                                onChange={(e) => handleInputChange('pan_no', e.target.value.toUpperCase())} // Adjusted for direct value
                                                maxLength="10"
                                                className="border uppercase border-gray-300 px-[1vw] py-[1.5vh] rounded-lg"
                                            />
                                            {errors.pan_no && <span className="text-red-500 text-sm">{errors.pan_no}</span>}
                                        </div>

                                    </div>

                                    {/* Submit Button */}
                                    <div className=' mt-[3vh] flex flex-row  gap-x-[1vw] justify-end text-[1.2em]'>
                                        <button
                                            disabled={!permissions.some(permission => permission.pagePath === '/cms/licenseInfo/' && permission.permissionLevel === 'read_write')}

                                            title={permissions.some(permission => permission.pagePath === '/cms/licenseInfo/' && permission.permissionLevel === 'read_write') ? 'Submit' : "You don't have permission"}
                                            className="bg-[#2e84a3] border border-1 border-[#2e84a3] text-white px-[2vw] py-[1vh] shadow-md rounded-sm hover:bg-white hover:text-[#276f8a] transition"
                                        >
                                            Submit
                                        </button>
                                        <button type="button"
                                            className="bg-[#2e84a3] border border-1 border-[#2e84a3] text-white px-[2vw] py-[1vh] shadow-md rounded-sm hover:bg-white hover:text-[#276f8a] transition"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        {/* {showConfirmation && <div className={` ${showConfirmation ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[50] bg-gray-600 opacity-50  `} ></div>} */}

                                        <ConfirmationDialog
                                            isOpen={showConfirmation}
                                            onClose={handleCloseDialog}
                                            onConfirm={handleConfirmCancel}
                                        />
                                    </div>
                                </form>

                            </div>
                        </div>)}

                        <div className='py-[1.2vh] flex flex-row items-center'>
                            <div className='min-w-[240px] font-[500]'>Subscriber Notifications :</div>
                            {/* <span>{userLicenceList?.phone}</span> */}
                            <span className='flex flex-row items-center underline  text-blue-600 rounded-md hover:text-purple-600  '
                                onClick={() => {
                                    navigate(`/cms/admin/notifications/${userId}`)
                                }}
                            >
                                <CiEdit /></span>
                        </div>

                        <div className='py-[1.2vh] flex flex-row'>
                            <div className='min-w-[240px] font-[500]'>License Key :</div>
                            <div className=' flex flex-row gap-x-[1vw] items-center mr-3'>
                                {userLicenceList?.linked_license?.key}

                                <button
                                    className={`bg-blue-600 border border-1 border-blue-600 shadow-md text-white flex flex-row items-center py-[0.4vh] px-[0.5vw] hover:text-blue-600 hover:bg-white rounded-sm
                                   ${!permissions.some(permission => permission.pagePath === '/cms/licenseInfo/' && permission.permissionLevel === 'read_write') ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    title={
                                        !permissions.some(permission => permission.pagePath === '/cms/licenseInfo/' && permission.permissionLevel === 'read_write')
                                            ? "You don't have permissions"
                                            : userLicenceList?.linked_license?.key
                                                ? "If a new key is linked, the previous key's credits will be lost and cannot be used in the future."
                                                : ''
                                    }
                                    onClick={() => setLinkingWindow(true)}
                                    disabled={!permissions.some(permission => permission.pagePath === '/cms/licenseInfo/' && permission.permissionLevel === 'read_write')}
                                >
                                    <span className={`text-[0.9em] ${!permissions.some(permission => permission.pagePath === '/cms/licenseInfo/' && permission.permissionLevel === 'read_write') ? '' : ''}`}>
                                        {userLicenceList?.linked_license?.key ? "Change Key" : "Link Key"}
                                    </span>
                                </button>

                            </div>
                            {/* {!userLicenceList?.linked_license?.key && <div className=' flex flex-row gap-x-2 items-center'><span className='text-[0.8em]'>Don't have the key?</span> <button
                                className={`text-[0.9em] `} disabled={requestHold || isActive} onClick={handleRequestKey}>{requestHold ? <div className='flex flex-row gap-x-1 items-center text-gray-800'>
                                    Loading <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                </div> : isActive ? "Resend Request" : localStorage.getItem("ReqSentError")?"Resend Request":"Request"}</button>
                                {isActive && <span className='text-xs'>
                                    {`Resend request after ${formatTime(timeLeft)}`}
                                </span>}
                                {localStorage.getItem("ReqSentError") &&<> <span className='text-[0.8em]'>Please try again or 
                                    </span> <a className='underline text-blue-600 hover:text-blue-700 text-[0.8em]' target='_blank'  href='/contact'>Contact us</a></>}
                            </div>} */}

                        </div>

                        <div className='py-[1.2vh] flex flex-row .'>
                            <div className='min-w-[240px] font-[500]'>Total Credits :</div>
                            <span>{userLicenceList?.linked_license?.total_credits}</span>
                        </div>

                        {/* <div className='py-[1vh] flex flex-row '> */}
                        <div className='py-[1.2vh]  flex flex-row'>
                            <div className='min-w-[240px] font-[500]'>Products :</div>

                            {userLicenceList?.linked_license?.products.length > 0 ? (
                                <div
                                    className={`grid grid-cols-1 ${userLicenceList?.linked_license?.products.length === 2
                                        ? "lg:grid-cols-2"
                                        : userLicenceList?.linked_license?.products.length >= 3
                                            ? "lg:grid-cols-2 xl:grid-cols-3"
                                            : "lg:grid-cols-1"
                                        } bg-[#e3f2f5] py-[1vh] rounded-sm border-[1px] border-blue-200 w-[60vw]`}
                                >
                                    {userLicenceList?.linked_license?.products?.map((item, index) => (
                                        <div
                                            key={`${item?.name}index`}
                                            className="flex flex-col pr-[1vw] border-r border-gray-400 px-[1vw] relative"
                                        >
                                            <div className="font-semibold pr-[1vw] w-[4vw] ">{item?.name}</div>
                                            <div className='text-[0.9em]'>
                                                {`Daily rate: ${item?.daily_rate} `}
                                                <span className="text-[0.8em] text-gray-500">credits/day</span>
                                            </div>
                                            <div className="flex items-center text-[0.9em]">
                                                Computers:
                                                <span className="font-semibold text-blue-600 flex items-center">
                                                    <button
                                                        onClick={() =>
                                                            handleLinkedDeviceNavigate(
                                                                item?.product_code,
                                                                item?.installerIds?.length,
                                                                item?.name,
                                                                userLicenceList?.linked_license?.orgId,
                                                                userLicenceList?._id,
                                                                userLicenceList?.linked_license?._id
                                                            )
                                                        }
                                                        title="Linked Devices"
                                                        className="flex flex-row items-center border border-b border-b-blue-600 hover:cursor-pointer hover:text-purple-600"
                                                    >
                                                        &nbsp;
                                                        {Math.max(item?.installerIds?.length || 0, item?.no_computer || 0)}
                                                        &nbsp;
                                                        <RiComputerLine />
                                                    </button>
                                                </span>
                                            </div>
                                            <div className="text-[0.7em] text-gray-600 pt-[2vh] text-justify">
                                                {item.on_installation === false && item.on_account_link === false ? (
                                                    <span>
                                                        Credits{" "}
                                                        {new Date(item?.start_date) <= new Date()
                                                            ? "are currently being deducted"
                                                            : "will start deducting"}{" "}
                                                        from {new Date(item.start_date).toLocaleDateString()}, based on the
                                                        initial device count ({item?.no_computer}), until the product
                                                        installations exceed this count.
                                                    </span>
                                                ) : item.on_installation === true ? (
                                                    <span>
                                                        Credits will be deducted based on the number of product
                                                        installations.
                                                    </span>
                                                ) : (
                                                    <span>
                                                        Credits are being deducted from the license linking date, which is{" "}
                                                        {new Date(
                                                            userLicenceList?.linked_license?.dateTimeOfAccountLink
                                                        ).toLocaleDateString()}
                                                        , based on the initial device count ({item?.no_computer}), until
                                                        the product installations exceed this count.
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <span className="text-[0.9em]">No products are licensed.</span>
                            )}


                        </div>
                        <div className='py-[1.2vh] flex flex-row .'>
                            <div className='min-w-[240px] font-[500]'>Expiry Date :</div>
                            <div className='flex flex-row items-center text-[0.9em]'>
                                {userLicenceList?.linked_license?.license_due_date ? (
                                    <>
                                        <span>{new Date(userLicenceList?.linked_license?.license_due_date).toLocaleDateString()}</span>
                                        <span className='text-[0.9em] text-blue-600 ml-2'>
                                            (*Expiry date will change with increase or decrease in number of product installations.)
                                        </span>
                                    </>
                                ) : (
                                    <span>NA</span>
                                )}
                            </div>

                        </div>

                        {/* <div> Name : </div> */}

                    </div>
                </div>
            </div>
        </div >

    )
}

export default LicenseDetail1