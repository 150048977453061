import axios from 'axios'; // Import Axios
import { toast } from 'react-toastify';

import { RECEIVE_VERIFY_USER, REQUEST_FAILED_VERIFY_USER, REQUEST_VERIFY_USER, SET_FILTER_VERIFY_USER, SET_PAGE_NUMBER_VERIFY_USER, SET_PAGE_SIZE_VERIFY_USER, SET_SORT_OPTIONS_VERIFY_USER } from './verifyUser.action_type';
export const verifyUserList = (id,pageSize, page, sortBy, sortOrder, filtersvalue,type) => {
    return async (dispatch) => {
        dispatch({ type: REQUEST_VERIFY_USER });
     

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/all-users`, {
               ...filtersvalue,
                "page": page,
                "pageSize": pageSize,
                "sortBy": sortBy,
                "sortOrder": sortOrder,
                "adminId":id,
                type
            });

            dispatch({ type: RECEIVE_VERIFY_USER, payload: response.data });
            // console.log('Data from backend:', response.data);

        } catch (error) {
            // console.error('Error fetching data:', error);
            dispatch({ type: REQUEST_FAILED_VERIFY_USER, payload: error });
            // toast.error(error?.response?.data || error?.message)
        }
    };
};

export const setSortOptions = (sortBy, sortOrder) => ({
    type: SET_SORT_OPTIONS_VERIFY_USER,
    payload: { sortBy, sortOrder }
});

export const setPageSize = (pageSize) => ({
    type: SET_PAGE_SIZE_VERIFY_USER,
    payload: pageSize,
});

export const setPageNumber = (pageNumber) => ({
    type: SET_PAGE_NUMBER_VERIFY_USER,
    payload: pageNumber,
});

export const setFilters = (filters) => ({
    type: SET_FILTER_VERIFY_USER,
    payload: filters,
});

