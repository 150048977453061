import React, { useState, useEffect } from 'react';
import Header from '../../Header1';
import { Helmet } from 'react-helmet';
import LoginWithPassword from './LoginWithPassword';
import LoginWithOTP from './LoginWithOTP';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../Footer';
import axios from 'axios';

const Login12 = () => {
    const [loginSection, setLoginSection] = useState(1);
    const [email, setEmail] = useState(localStorage.getItem('emailLogin') || ''); // Load email from local storage
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpRequest, setOtpRequest] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [timer, setTimer] = useState(0);
    const [cookieCheck, setCookieCheck] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    // Update URL with the current login section (password or OTP)
    const updateLoginSectionInURL = (section) => {
        const params = new URLSearchParams(location.search);
        params.set('section', section); // Set 'section' query param
        navigate({ search: params.toString() }); // Update URL with new query
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST_NAME}/check-cookie`)
            .then((response) => {
                setCookieCheck(response.data);
                // console.log("Already Login");
                
                if (response?.data?.role?.includes('WEBSITE_USER')) {

                    if (response?.data?.licenseId) {
                        window.location.href = '/cms/company/list';
                    }
        
                    else {
        
                        navigate(`/cms/licenseInfo/${response?.data?.id}`)
        
                    }
               
                }
                // console.log(response.data);
                
            })
            .catch((err) => {
                // console.error(err);
                // navigate('/login');
            });
    }, []);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const section = queryParams.get('section'); // Get section from the URL
        if (section) {
            setLoginSection(parseInt(section)); // Set the login section based on URL
        }
    }, [location.search]); // Run the effect when the URL changes

    return (
        <>
            <Helmet>
                <title>Matisoft | Login</title>
            </Helmet>
            <Header />
            <div className='relative h-[94vh] w-full flex items-center justify-center login_Container'>
                <div className='bg-white px-[1vw] py-[1vh]  border-[1px] border-blue-500 rounded-md shadow-md w-[90vw] md:w-[50vw] lg:w-[32vw]'>
                    <h2 className='text-[1.4em] font-bold mb-4 text-center bg-[#458EA2] text-white'>Login</h2>
                    <div className='flex flex-row items-center justify-around'>
                        <span
                            onClick={() => {
                                setLoginSection(1);
                                updateLoginSectionInURL(1); // Update URL to reflect password section
                            }}
                            className={`${loginSection === 1 ? 'text-blue-600 text-[1.1em]' : 'text-gray-600'} cursor-pointer`}
                        >
                            Password
                        </span>
                        <span
                            onClick={() => {
                                setLoginSection(2);
                                updateLoginSectionInURL(2); // Update URL to reflect OTP section
                            }}
                            className={`${loginSection === 2 ? 'text-blue-600 text-[1.1em]' : 'text-gray-600'} cursor-pointer`}
                        >
                            OTP
                        </span>
                    </div>
                    <hr className='my-2' />
                    {loginSection === 1 ? (
                        <LoginWithPassword
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                        />
                    ) : (
                        <LoginWithOTP
                            email={email}
                            setEmail={setEmail}
                            otp={otp}
                            setOtp={setOtp}
                            timer={timer}
                            setTimer={setTimer}
                            otpRequest={otpRequest}
                            setOtpRequest={setOtpRequest}
                            isOtpSent={isOtpSent}
                            setIsOtpSent={setIsOtpSent}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Login12;
