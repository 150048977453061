
import { REQUEST_LICENCE_HISTORY_USER,  SET_FILTER_LICENCE_HISTORY_USER, SET_PAGE_NUMBER_LICENCE_HISTORY_USER, SET_PAGE_SIZE_LICENCE_HISTORY_USER, SET_SORT_OPTIONS_LICENCE_HISTORY_USER, RECEIVE_LICENCE_HISTORY_USER, REQUEST_FAILED_LICENCE_HISTORY_USER } from "./licence.action_type"
const initialState = {
    loading: false,
    // sortBy: 'date', 
    sortBy: 'updated_date',
    // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loading: false,
    licenseHistoryList: [],
    totalRecords: 0,
    error: null,
    filters: {},
    
    // actionList:[],
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {
// console.log(state.sortBy,state.sortOrder);

    switch (action.type) {
        case REQUEST_LICENCE_HISTORY_USER:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_LICENCE_HISTORY_USER:
            return {
                ...state,
                loading: false,
                // actionList:action.payload.statusList,
                licenseHistoryList: action.payload.data,
                totalRecords:action.payload.totalRecords,
            }
        case REQUEST_FAILED_LICENCE_HISTORY_USER:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_LICENCE_HISTORY_USER:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_LICENCE_HISTORY_USER:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_LICENCE_HISTORY_USER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_LICENCE_HISTORY_USER: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state;
    }
}
export { reducer };