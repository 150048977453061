import React, { useEffect, useRef, useState } from 'react';
import '../../../css/index.css';
import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import 'react-tabs/style/react-tabs.css'; // Import default styles

import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegQuestionCircle } from "react-icons/fa";
import { TbVirus } from "react-icons/tb";
import { MdOutlineAutoDelete } from "react-icons/md";
import { BiCustomize } from "react-icons/bi";
import SidebarInstaller from '../../../common/SidebarInstaller';

import { RiAlertLine } from "react-icons/ri";
import { Helmet } from 'react-helmet';
import VerifyEndUser from './VerifyEndUser';
import VerifyChannelPartner from './VerifyChannelPartner';
import VerifyAllUser from './VerifyAllUser';
import axios from 'axios';
import { toast } from 'react-toastify';
import SidebarLicense from '../../../common/SidebarLicense';
import Loader from '../../../common/Loader';
import Error from '../../../../pages/Error';

const Instances = () => {

  const { companyId, userId, loading ,errorAdminAuth} = useSelector((state) => ({

    companyId: state.companyid.companyId,
    userId: state.companyid.userId,
    loading: state.verifyUser.loading,
    errorAdminAuth: state.adminAuth.errorAdminAuth


  }));
  const [updateData, setUpdateData] = useState(false);
  const [isSidebarExpanded, setSidebarExpanded] = useState(true);
  const [actionStatuses, setActionStatuses] = useState({});

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded);
  };
  const [selectedTab, setSelectedTab] = useState(0); // State to track selected tab index

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const fileid = queryParams.get('uniqueId') || null;
  // console.log(fileid, "FileId");

  useEffect(() => {
    // Extract the 'tab' parameter from the URL query string
    const params = new URLSearchParams(location.search);
    const tab = parseInt(params.get('tab'), 10);
    if (!isNaN(tab)) {
      setSelectedTab(tab);
    }
  }, []);

  const handleTabSelect = (index) => {
    setSelectedTab(index);

    // Merge 'tab' query with existing parameters
    const params = new URLSearchParams(location.search);
    params.set('tab', index); // Update the tab parameter
    navigate(`?${params.toString()}`, { replace: true }); // Navigate with updated query
  };
  useEffect(() => {
    // Extract localStorage data on page load
    const storedStatuses = {};
    // Loop through localStorage keys
    Object.keys(localStorage).forEach((key) => {
      try {
        const value = localStorage.getItem(key);
        const data = value ? JSON.parse(value) : null; // Parse only if value exists
        if (data?.actionStatus && data?.action) {
          // Store both actionStatus and action
          storedStatuses[key] = {
            actionStatus: data.actionStatus,
            action: data.action
          };
        }
      } catch (error) {
        console.error(`Error parsing localStorage data for key "${key}":`, error);
      }
    });

    // Update state with parsed data
    setActionStatuses(storedStatuses);
  }, []);
  const handleUserAction = (id, action, resendMail, products) => {
    const payload = { userId: id, adminId: userId };
    if (resendMail) {
      payload.reSendMail = true;
    } else {
      payload.verificationStatus = action;
      payload.products = products;
    }

    // Set action status as pending and store the action itself
    setActionStatuses(prev => ({
      ...prev,
      [id]: { actionStatus: 'pending', action: resendMail === true ? 'RESENDEMAIL' : action } // Store both action status and the action
    }));

    localStorage.setItem(id, JSON.stringify({ actionStatus: 'pending', action: resendMail === true ? 'RESENDEMAIL' : action }));

    axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/update`, payload)
      .then(response => {
        toast.success(response.data);
        // setUpdatedData(prev => !prev);

        setActionStatuses(prev => ({
          ...prev,
          [id]: { actionStatus: 'success', action: action } // Store success and action
        }));
        localStorage.removeItem(id);
        setUpdateData(!updateData);
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error(error?.response?.data || error.message);

        setActionStatuses(prev => ({
          ...prev,
          [id]: { actionStatus: 'failed', action: action } // Store failed and action
        }));
        localStorage.setItem(id, JSON.stringify({
          actionStatus: 'failed',
          action: action,
          errMessage: error?.response?.data || error.message
        }));
      })
      .finally(() => {
        // setConfirmAction(false);
        // setShowConfirmationModal(false);
      });
  };

  //  if (loading) return <Loader />;
  if (errorAdminAuth) {
    return <Error />;
}
  return (
    <div className="flex h-screen">
      <Helmet>
        <title>Matisoft | Verify User</title>
      </Helmet>
      <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed`}>
        <SidebarLicense isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
      </div>
      <div className={`flex-1  ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'}  relative overflow-hidden 2xl:text-[1.2em] `} >
        <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect} selectedTabClassName="selected-tab">

          <div className={`fixed top-0 bottom-[87.5vh]  w-full  
           z-50`}>
            <div className='absolute bottom-0'>
              <div className={`flex flex-row justify-center items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[96vw]'} `}>

                <h1 className='text-[1.1em] font-semibold pb-[0.4vh]'>Verify User</h1>

              </div>
              <div style={{ lineHeight: "3vh" }} className={` pl-[1vw] w-full  border-b-1   `}>
                <TabList className={` flex flex-row gap-x-[2px]    ${selectedTab === 0 ? '!border-b-[#4fd0ff]' : ''} `}>
                  <Tab className={`py-[1vh] px-[0.6vw] cursor-pointer  flex flex-row items-center gap-x-1  ${selectedTab === 0 ? ' border-b-2 font-[500] bg-white text-gray-900 !border-b-[#4fd0ff]' : 'bg-gray-200 text-gray-800'}`}>All User
                  </Tab>
                  <Tab className={`py-[1vh] px-[0.6vw] cursor-pointer  flex flex-row items-center gap-x-1  ${selectedTab === 1 ? '  border-b-2 font-[500] bg-white text-gray-900 !border-b-[#4fd0ff]' : 'bg-gray-200 text-gray-800'}`}>End User
                  </Tab>
                  <Tab className={`py-[1vh] px-[0.6vw] cursor-pointer flex flex-row items-center gap-x-1   ${selectedTab === 2 ? ' border-b-2 font-[500] bg-white text-gray-900 !border-b-[#4fd0ff]' : 'bg-gray-200 text-gray-800'}`}>Channel Partner </Tab>


                </TabList>
              </div>
            </div>
          </div>
          <TabPanel>
            <VerifyAllUser handleUserAction={handleUserAction} actionStatuses={actionStatuses} setActionStatuses={setActionStatuses} updateData={updateData} setUpdateData={setUpdateData} />
          </TabPanel>
          <TabPanel>
            <VerifyEndUser handleUserAction={handleUserAction} actionStatuses={actionStatuses} setActionStatuses={setActionStatuses} updateData={updateData} setUpdateData={setUpdateData} />
          </TabPanel>
          <TabPanel>
            <VerifyChannelPartner handleUserAction={handleUserAction} actionStatuses={actionStatuses} setActionStatuses={setActionStatuses} updateData={updateData} setUpdateData={setUpdateData} />
          </TabPanel>


        </Tabs>

      </div>
    </div>

  );
}

export default Instances;
