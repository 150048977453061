import React, { useEffect, useState, useRef } from 'react'
import Loader from '../../common/Loader'
import '../../css/index.css'
import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom'
import { FaInfoCircle } from "react-icons/fa";

// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import FilterSection from '../../common/FilterSection';
import DataEntriesNo from '../../components/pagination/DataEntriesNo'
import PageNumber from '../../components/pagination/PageNumber'
import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCircleInfo, FaPlus } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import Sidebar1 from '../../common/Sidebar1';
import { userRoles, setSortOptions } from '../../../Redux/userRoles/userRoles.action';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import Error from '../../../pages/Error';
import { useSortedData } from '../../../utils/sortUtils';

const AddUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openShowHide, setOpenShowHide] = useState(false);


    const [newUserBar, setNewUserBar] = useState(false);
    const [accessHelp, setAccessHelp] = useState(false);
    const {
        userId, userRolesData, sortOrder, pageNumber, totalRecords, sortBy, filters, loading, permissions, userAuthData, errorUserAuth } = useSelector((state) => ({
            userId: state.companyid.userId,
            userRolesData: state.userRoles.userRolesData,
            sortOrder: state.userRoles.sortOrder,
            pageNumber: state.userRoles.pageNumber,
            totalRecords: state.userRoles.totalRecords,
            sortBy: state.userRoles.sortBy,
            filters: state.userRoles.filters,
            loading: state.userRoles.loading,
            permissions: state.companyid.permissions,
            userAuthData: state.userAuth.userAuthData,
            errorUserAuth: state.userAuth.errorUserAuth
        }));

    let newSize;
    const fieldParam = new URLSearchParams(location.search).get('field');
    const operandParam = new URLSearchParams(location.search).get('operand');
    const valueParam = new URLSearchParams(location.search).get('value');
    const excludeParam = new URLSearchParams(location.search).get('exclude');
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const sortByParam = new URLSearchParams(location.search).get('sortBy');
    const sortOrderParam = new URLSearchParams(location.search).get('sortOrder');
    // Assuming the values are stored as JSON strings in the URL, parse them
    const field = fieldParam ? JSON.parse(fieldParam) : [];
    const operand = operandParam ? JSON.parse(operandParam) : [];
    const value = valueParam ? JSON.parse(valueParam) : [];
    const exclude = excludeParam ? JSON.parse(excludeParam) : [];
    const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
    const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];
    const sortByCol = sortByParam ? JSON.parse(sortByParam) : [];
    const sortOrderCol = sortOrderParam ? JSON.parse(sortOrderParam) : [];
    // console.log(sortByCol,sortOrderCol,"clm and order");

    const [activeArrow, setActiveArrow] = useState({
        columnName: sortByCol.length > 0 ? sortByCol[0] : "createdAt", // Extract first element or use default
        direction: sortOrderCol.length > 0 ? sortOrderCol[0] : -1, // Extract first element or use default
    });
    const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
    const [currPage, setCurrPage] = useState(1);
    const [filterChange, setFilterChange] = useState();
    const [showFilter, setShowFilter] = useState(false);
    const handleFilter = () => {
        setShowFilter(!showFilter);
    }
    let filtersvalue = React.useMemo(() => {
        return {
            filters: {
                field,
                operand,
                value,
                exclude,
            },
        };
    }, [field, operand, value, exclude]);
    const filtersValueRef = React.createRef(filtersvalue);
    const pageSizeRef = React.createRef(pageSize);
    const [showConfirmationModal, setShowConfirmationModal] = useState(null);

    useEffect(() => {
        const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
        const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
        setPageSizeFromURL(parsedSize);
        const pageNumberParam = new URLSearchParams(location.search).get('page');
        const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
        setCurrPage(parsedPage);
    }, [location.search]);
    const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time

    // Function to get current time
    function getCurrentTime() {
        const now = new Date();
        return now.toLocaleString();
    }

    // Function to add leading zero if needed
    function addZeroIfNeeded(value) {
        return value < 10 ? '0' + value : value;
    }

    // Function to update current time
    function updateTime() {
        setCurrentTime(getCurrentTime());
    }
    const [updatedData, setUpdatedData] = useState(false);

    const [duration, setDuration] = useState(false);

    const [newUserDetail, setNewUserDetail] = useState({
        email: '',
        type: 'read_write',
        title: '',
        first_name: '',
        last_name: ''
    });

    useEffect(() => {
        const currentURL = window.location.pathname;

        const parts = currentURL.split('/');
        const fileid = null;
        const userid = parts[parts?.length - 1]

        dispatch(setUserId(userid))
        dispatch(setFileId(fileid));

        if (userid) {
            dispatch(userRoles(userid, pageSizeFromURL, currPage, sortBy, sortOrder, filtersvalue));
        }
        // dispatch(checkAuthentication())

        setCurrentTime(getCurrentTime());
    }, [dispatch, pageNumber, pageSizeFromURL, currPage, filterChange, currentTime, updatedData]);

    const columnsUserRoles = [
        { id: 0, name: "Email", value: "email", type: 'string' },
        { id: 1, name: "Permisssion", value: "role1", type: 'string' },
        { id: 2, name: "First Name", value: "first_name", type: 'string' },
        { id: 3, name: "Last Name", value: "last_name", type: 'string' },
        { id: 4, name: "Job Role", value: "job_role", type: 'string' },

    ]
    const getValueByPath = (obj, path) => {
        if (!path) return undefined;
        return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
    };
    const [checkedRows, setCheckedRows] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columnsUserRoles);
    const [checkedColumns, setCheckedColumns] = useState(columnsUserRoles.map(() => true));
    const handleCheckboxChange = (event, index) => {
        const { checked } = event.target;
        const column = columnsUserRoles[index];

        setCheckedColumns(prevState => {
            const updatedCheckedColumns = [...prevState];
            updatedCheckedColumns[index] = checked;
            return updatedCheckedColumns;
        });
        setVisibleColumns(prevState => {
            const updatedVisibleColumns = [...prevState];
            // console.log(updatedVisibleColumns, "visibleColumn");
            if (checked) {

                if (!updatedVisibleColumns.find(col => col.id === column.id)) {
                    updatedVisibleColumns.push(column);
                    updatedVisibleColumns.sort((a, b) => a.id - b.id);
                }
            } else {

                const filteredColumns = updatedVisibleColumns.filter(col => col.id !== column.id);
                return filteredColumns;
            }

            return updatedVisibleColumns;
        });
    };
    const selectAllColumns = () => {
        setCheckedColumns(columnsUserRoles.map(() => true)); // Mark all columns as checked
        setVisibleColumns(columnsUserRoles);
    };

    // Function to deselect all columns
    const deselectAllColumns = () => {
        setCheckedColumns(columnsUserRoles.map(() => false)); // Mark all columns as unchecked
        setVisibleColumns([]); // Hide all columns
    };

    const handleSort = (columnName, newSortOrder) => {
        setActiveArrow({
            columnName: columnName,
            direction: newSortOrder
        })

        dispatch(setSortOptions(columnName, newSortOrder));
        const searchParams = new URLSearchParams(location.search);

        // Update the sortBy and sortOrder parameters
        searchParams.set('sortBy', JSON.stringify([columnName])); // Save the sorted column
        searchParams.set('sortOrder', JSON.stringify([newSortOrder])); // Save the sort order

        // Use navigate to update the URL with the new query parameters
        navigate({
            pathname: location.pathname, // Keep the current pathname
            search: searchParams.toString(), // Apply the updated search parameters
        });
    };

    const sortedData = useSortedData(userRolesData, sortBy, sortOrder);

    const handlePageSizeChange = (event) => {
        event.preventDefault();
        newSize = parseInt(event.target.value);

        const existingParams = new URLSearchParams(location.search);
        existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };

    const handleDeleteUser = (deleteUserId) => {
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/subuser/delete`, {
            "rootUserId": userId,
            "userId": deleteUserId
        })
            .then(response => {

                toast.success(response?.data);
                setUpdatedData(!updatedData);

            })
            .catch(error => {

                // console.error('Error:', error?.message);
                toast.error(error?.response?.data || error?.message);
            });
        setShowConfirmationModal(false);
        setExpiryDays(0);
    }
    const [expiryDays, setExpiryDays] = useState('');

    const resetTime = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };

    const handlePageNumberChange = (newPageNumber) => {
        setCurrPage(newPageNumber);
        const existingParams = new URLSearchParams(location.search);
        existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number

        const queryString = existingParams.toString();

        navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

    };
    const handleClick = () => {

    };

    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showMoreStates, setShowMoreStates] = useState({});
    // Function to toggle show more/less state for 'details'
    const toggleShowMoreDetails = (rowIndex) => {

        const key = `${rowIndex}-details`;
        setShowMoreStates(prevState => ({
            ...prevState,
            [key]: !prevState[key] // Toggle the show more/less state for the specific row and 'details' value
        }));
    };
    const [expandedStates, setExpandedStates] = useState({});

    // Function to toggle show more/less state for 'details'
    const toggleExpand = (rowIndex, colIndex) => {
        setExpandedStates((prev) => ({
            ...prev,
            [`${rowIndex}-${colIndex}`]: !prev[`${rowIndex}-${colIndex}`],
        }));
    };
    const onApplyFilters = (filters) => {

        setFilterChange(filters);

    }

    const [isSidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);

    };

    const toggleNewUserBar = () => {
        setNewUserBar(!newUserBar);
    };

    const handleSetDetail = (e) => {
        const { name, value } = e.target;
        setNewUserDetail(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCancel = () => {
        // Show the confirmation dialog
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        // Handle the cancellation (e.g., reset form, navigate away, etc.)

        setNewUserDetail({
            email: '',
            type: 'read_write',
            title: '',
            first_name: '',
            last_name: ''
        })
        // setFormData(initialFormData);
        setShowConfirmation(false);
        setNewUserBar(false);
        // Reset or clear the form data if needed
    };

    const handleCloseDialog = () => {
        // Close the confirmation dialog
        setShowConfirmation(false);
    };
    const handleAddUser = (e) => {
        e.preventDefault();
        if (newUserDetail.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUserDetail.email)) {
            toast.error("Please input a valid Email ID");
            return;
        }
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/add/subuser`, {
            userId: userId,
            email: newUserDetail.email,
            job_role: newUserDetail.title,
            first_name: newUserDetail.first_name,
            last_name: newUserDetail.last_name,
            permissions: newUserDetail.type
            // "permissions": [{ "apiPath": "/", "permissionLevel": newUserDetail.type }]

        },
            // {
            //     withCredentials: true 
            // }
        )
            .then(response => {
                toast.success(response?.data);
                setUpdatedData(!updatedData);
                setNewUserBar(false);
                setNewUserDetail({ email: '', type: 'read_write', first_name: '', last_name: '' });
            })
            .catch(error => {
                // console.log(error, "in adding new user");
                toast.error(error?.response?.data || error?.message);
            });
    };

    const handleClear = () => {
        setNewUserDetail({ email: '', type: 'read_write', first_name: '', last_name: '' });
        setNewUserBar(false);
    };


    const buttonRef = useRef(null);

    if (loading) return <Loader />;
    if (errorUserAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen font-[17px] 2xl:font-[20px]"  >
            <Helmet>
                <title>Matisoft | User Roles</title>
            </Helmet>
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed `}>
                {/* Sidebar Content Goes Here */}
                <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            </div>

            {/* Main Content */}
            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%] z-40'}  relative overflow-x-auto  !2xl:font-[30px] `}>
                <div className="fixed top-0 bottom-[76vh] w-full bg-white z-50">
                    <div className={`flex flex-row justify-end items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'}`}>
                        {newUserBar && <div className={` ${newUserBar ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[50] bg-gray-600 opacity-50  `}  ></div>}

                        {newUserBar && (
                            <div className='fixed bg-[#88d5f1] opacity-[0.96] border-white-100 border-[1px] rounded w-[56vw] left-[22vw] z-[60] top-[5vh] bottom-[5vh] text-[1em] ' style={{ zIndex: '80' }}>
                                <div className="flex flex-row justify-between items-center text-[1.3em] shadow-md px-[1vw] py-[2vh]">
                                    <div className='flex flex-row gap-x-2'>
                                        <div>Add New User</div>
                                        {/* <button onClick={() => setAccessHelp(true)} className='text-[0.9em]  px-[0.5vw]  ' title='Permissions as per access' ><FaInfoCircle/></button> */}
                                    </div>
                                    <button
                                        className="z-60 cursor-pointer"
                                        onClick={handleCancel}
                                    >
                                        <RxCross2 />
                                    </button>
                                </div>
                                <div className=" px-[1vw]">
                                    <form onSubmit={handleAddUser}>
                                        <div className='grid grid-cols-2 gap-x-[3vw] gap-y-[2vh]'>
                                            {/* First Name */}
                                            <div className='flex flex-col py-[1vh]'>
                                                <label className="py-[1vh] font-[500]">First name*:</label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    className="py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px]"
                                                    value={newUserDetail.first_name}
                                                    onChange={handleSetDetail}
                                                    placeholder="Enter first name"
                                                    required
                                                    maxLength='40'
                                                />
                                            </div>

                                            {/* Last Name */}
                                            <div className='flex flex-col py-[1vh]'>
                                                <label className="py-[1vh] font-[500]">Last name*:</label>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    className="py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px]"
                                                    value={newUserDetail.last_name}
                                                    onChange={handleSetDetail}
                                                    placeholder="Enter last name"
                                                    required
                                                    maxLength='40'
                                                />
                                            </div>

                                            {/* Email Address */}
                                            <div className='flex flex-col py-[1vh]'>
                                                <label className="py-[1vh] font-[500]">Email address*:</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px]"
                                                    value={newUserDetail.email}
                                                    onChange={handleSetDetail}
                                                    placeholder="Enter email"
                                                    required
                                                    maxLength='254'
                                                />
                                            </div>

                                            {/* Job Role (Select Box) */}
                                            <div className='flex flex-col py-[1vh]'>
                                                <label className='font-[500] py-[1vh]'>Job role:</label>
                                                <select
                                                    name='title'
                                                    value={newUserDetail.title}
                                                    onChange={handleSetDetail}
                                                    className='py-[0.8vh] rounded border border-gray-600 px-[0.5vw] bg-white h-[38px] appearance-none'
                                                >
                                                    <option value="">Select...</option>
                                                    <option>CEO</option>
                                                    <option>CISO/CSO</option>
                                                    <option>CTO</option>
                                                    <option>CIO</option>
                                                    <option>COO</option>
                                                    <option>CMO</option>
                                                    <option>IT Professional</option>
                                                    <option>Security Professional</option>
                                                    <option>Director</option>
                                                    <option>VP</option>
                                                    <option>Sales/Marketing</option>
                                                    <option>Business Development</option>
                                                    <option>Intern</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Permissions and Help */}
                                        <div className='flex justify-between mt-[2vh]'>
                                            <div className='flex flex-col py-[1vh]'>
                                                <div className='flex flex-row items-center '>
                                                    <label className="py-[2vh] font-[500]">Permission*:</label>  <div

                                                        onClick={() => setAccessHelp(true)}
                                                        className='text-[1em] text-primary underline px-[0.8vw] rounded-sm  hover:text-blue-800'
                                                        title='Permissions as per access'>
                                                        {/* <span>Help</span> */}
                                                        <FaInfoCircle />
                                                    </div></div>
                                                <div className="flex flex-row items-baseline gap-x-2 py-[1vh]">
                                                    <input
                                                        type="radio"
                                                        name="type"
                                                        value="read_write"
                                                        checked={newUserDetail.type === "read_write"}
                                                        onChange={handleSetDetail} required className='cursor-pointer'
                                                    />
                                                    <div><p>Read & Write</p></div>
                                                </div>
                                                <div className="flex flex-row items-baseline gap-x-2 py-[1vh] " >
                                                    <input
                                                        type="radio"
                                                        name="type"
                                                        value="read_only"
                                                        checked={newUserDetail.type === "read_only"}
                                                        onChange={handleSetDetail}
                                                        required className='cursor-pointer '
                                                    />
                                                    <div><p>Read Only</p></div>
                                                </div>
                                                {/* <div className="flex flex-row items-baseline gap-x-2 py-[1vh]">
                                                    <input
                                                        type="radio"
                                                        name="type"
                                                        value="no_access"
                                                        checked={newUserDetail.type === "no_access"}
                                                        onChange={handleSetDetail}
                                                        required
                                                    />
                                                    <div><p>No Access</p></div>
                                                </div> */}
                                            </div>

                                        </div>

                                        {/* Add and Cancel Buttons */}
                                        <div className="absolute bottom-[3vh] right-[3vw] flex flex-row justify-between">
                                            <div>
                                                <button
                                                    className="px-[1vw] py-[0.5vh] border border-primary text-white bg-primary hover:bg-transparent hover:text-primary"
                                                    title={!permissions.some(permission => permission.pagePath === '/cms/userroles/' && permission.permissionLevel === 'read_write') && "You don't have permissions"}
                                                    disabled={!permissions.some(permission => permission.pagePath === '/cms/userroles/' && permission.permissionLevel === 'read_write')}
                                                >
                                                    Add
                                                </button>
                                                <button
                                                    className="ml-1 px-[1vw] py-[0.5vh] border border-primary text-white bg-primary hover:bg-transparent hover:text-primary"
                                                    type="button"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </button>
                                                <ConfirmationDialog
                                                    isOpen={showConfirmation}
                                                    onClose={handleCloseDialog}
                                                    onConfirm={handleConfirmCancel}
                                                />
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        )}
                        {accessHelp && <div className={` ${accessHelp ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[100] bg-gray-600 opacity-50  `} style={{ zIndex: '120' }} ></div>}

                        {accessHelp && (
                            <div className='fixed bg-blue-100 opacity-[0.96] border-white-100 border-[1px] rounded w-[86vw] left-[7vw] z-[200] top-[3vh] bottom-[3vh] text-[1em] ' style={{ zIndex: '120' }}>
                                <div className='filter-section-content relative'>
                                    <button
                                        className='absolute right-[-5px] top-[-5px]'
                                        onClick={() => { setAccessHelp(!accessHelp) }}
                                    >
                                        <ImCross className='text-white bg-red-600 hover:bg-red-700 p-1 text-2xl font-[200] rounded-full' onClick={() => setAccessHelp(false)} />
                                    </button>
                                    <h1 className="text-[1.3em] shadow-md font-[500] pb-[1vh] text-blue-800 text-center ">Permissions Based on Access Levels</h1>
                                    <div className="overflow-auto p-4">
                                        <table className="table-auto w-full text-left border-collapse">
                                            <thead className="font-[500]">
                                                <tr>
                                                    <th className="border border-blue-300 px-4 py-2 text-center">Page</th>
                                                    <th className="border border-blue-300 px-4 py-2 text-center">Admin (Default on first account creation)</th>
                                                    <th className="border border-blue-300 px-4 py-2 text-center">Read & Write</th>
                                                    <th className="border border-blue-300 px-4 py-2 text-center">Read Only</th>
                                                </tr>
                                            </thead>

                                            <tbody className="text-[0.9em]">
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Notifications</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Add, update, delete</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Add, update, delete</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit/view users</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Add user, delete (except admin)</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Add user, delete (except admin)</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit/view user account</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit basic info, password, email</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit basic info, password, email, delete user account</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit basic info, password, email, delete user account</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit/view organization</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit, delete organization</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">License information</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Edit billing address, link key</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Linked devices</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Delete device</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Delete device</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Installer</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Download only approved products</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">Download only approved products</td>
                                                    <td className="border border-blue-300 px-4 py-2 text-center">No action</td>
                                                </tr>
                                            </tbody>
                                        </table>



                                    </div>
                                </div></div>
                        )}

                    </div>
                    <div className={`flex flex-row items-center justify-between pl-[5vw] mx-auto  `}>
                        <div className='text-[1.2em] 2xl:text-[1.3em] font-[600] pb-[1vh]  pt-[3vh] '>User Roles </div>
                    </div>
                    <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row items-center gap-x-[1%]'>

                        <div className=''>
                            <div className='cursor-pointer hover:text-blue-500' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
                            {openShowHide && <>
                                <div
                                    className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 "

                                ></div>
                                <div className='showhide-section-container z-40'>
                                    <div className="modal-body filter-section-content  ">
                                        <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                                            <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                                        </button>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {columnsUserRoles.map((item, index) => (
                                                    <label className="cont flex flex-row gap-x-[1%] text-white">
                                                        <input
                                                            type="checkbox"
                                                            className="toggle-vis"
                                                            data-column={index + 1}
                                                            checked={checkedColumns[index]}
                                                            defaultChecked
                                                            onChange={(event) => handleCheckboxChange(event, index)}
                                                        />
                                                        <span className="checkmark"></span> <p>{item.name}</p>
                                                    </label>
                                                ))}
                                            </div>
                                            <div></div>
                                        </div>
                                        <div className="row" style={{ marginTop: "20px" }}>
                                            <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                                            <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                        <div className={` ${showFilter || showConfirmationModal ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  `} ></div>
                        <div className='flex flex-row items-center hover:text-blue-500' onClick={handleFilter}>
                            <span>Filters &nbsp;</span>
                            <FaFilter className={` ${filtersvalue?.filters?.field?.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer`} />
                            {filtersvalue?.filters?.field.length > 0 && <span>&nbsp;{filtersvalue?.filters?.field?.length} applied</span>}
                        </div>
                        {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsUserRoles} initialFilters={filtersvalue} onApplyFilters={onApplyFilters} />}
                        <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCcw /></div>
                        <div>{`Last page updated at : ${currentTime}`}</div>
                        <div className='flex flex-row items-center gap-x-1 btn4' onClick={toggleNewUserBar}><span className=''>Add</span> <FaPlus /></div>
                    </div>
                    <div className={`flex flex-row justify-between items-center py-[1vh] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'} border-b-1 border-b-gray-500`}>
                        <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
                        <PageNumber
                            pageSize={pageSizeFromURL}
                            currPage={currPage}
                            totalRecords={totalRecords}
                            handlePageNumberChange={handlePageNumberChange}
                        />
                    </div>
                </div>

                {/* Main Content Goes Here */}

                <div className="overflow-auto" style={{ marginTop: '24vh', width: '100%', height: '76vh' }}>
                    <table className="w-full table-fixed">
                        {/* Table Head */}
                        <thead className="sticky top-0 bg-blue-200 text-[0.9em]">
                            <tr className=" font-semibold text-gray-800">

                                {visibleColumns.map((column, index) => (
                                    <th key={index} className="py-2 px-3 min-w-[280px] w-[280px] text-center">
                                        <div
                                            className="flex items-center justify-center gap-x-1 cursor-pointer"
                                            onClick={() => handleSort(column.value, activeArrow.columnName === column.value ? -activeArrow.direction : 1)}
                                        >
                                            <span>{column.name}</span>
                                            <p>
                                                <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                                                    <FaLongArrowAltUp title='Ascending' onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                                                    <FaLongArrowAltDown title='Descending' onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                                                </span>
                                            </p>
                                        </div>
                                    </th>
                                ))}

                                <th className="py-2 px-3 min-w-[200px] w-[200px] text-center">Delete</th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className=" text-gray-800 border border-b-1 border-b-gray-300">
                            {sortedData.length > 0 ? (
                                sortedData.map((item, indexVal) => (
                                    <tr key={indexVal} className={`${indexVal % 2 === 1 ? 'bg-gray-100' : ''}`}>
                                        {/* Action Column */}



                                        {/* Dynamic Columns */}

                                        {visibleColumns.map((column, colIndex) => (
                                            <td
                                                key={`${indexVal}-${colIndex}`}
                                                className="py-2 px-3  w-[280px] text-center text-[0.9em] break-words"
                                            >
                                                {(() => {
                                                    const value = item[column.value];

                                                    if (column.value === "file_size" && value) {
                                                        // Convert file size to KB and format it to two decimal places
                                                        return <span>{(value / 1024).toFixed(2)}</span>;
                                                    }
                                                    if (column.value === "instance_count" && value) {
                                                        // Convert file size to KB and format it to two decimal places
                                                        return <NavLink className="hover:text-blue-600 text-blue-700 underline"
                                                            to={`/cms/allinstances/${companyId}/${userId}?uniqueId=${(item.unique_ID)}&tab=5`}
                                                            target="_blank"
                                                        >{value}</NavLink>;
                                                    }
                                                    if (column.type === "date" && value) {
                                                        // Format dates using toLocaleString()
                                                        return <span>{new Date(value).toLocaleString()}</span>;
                                                    }

                                                    if (value && typeof value === "string" && value.length > 200) {
                                                        // Handle long text with "Show More/Show Less"
                                                        return (
                                                            <div className="text-container">
                                                                {expandedStates[`${indexVal}-${colIndex}`] ? (
                                                                    <>
                                                                        <span>{value}</span>
                                                                        <button
                                                                            className="show-more-btn text-blue-600"
                                                                            onClick={() => toggleExpand(indexVal, colIndex)}
                                                                        >
                                                                            ...Show Less
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="text-truncated">{value.substring(0, 200)}...</span>
                                                                        <button
                                                                            className="show-more-btn text-blue-600"
                                                                            onClick={() => toggleExpand(indexVal, colIndex)}
                                                                        >
                                                                            ...Show More
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        );
                                                    }

                                                    // Default case for plain text or other values
                                                    return <span>{value}</span>;
                                                })()}
                                            </td>


                                        ))}


                                        {/* Delete Column */}
                                        <td className="py-2 px-3 min-w-[280px] w-[280px] text-center mx-auto">
                                            <MdDelete
                                                title={

                                                    !permissions.some(
                                                        (permission) =>
                                                            permission.pagePath === '/cms/userroles/' &&
                                                            permission.permissionLevel === 'read_write'
                                                    ) ? "You don't have permissions" : item?.role1 === 'Admin' ? "Admin can not delete their own account" : "Delete"
                                                }
                                                className={`mx-auto  text-[1.5em] 
            ${item?.role1 === 'Admin' ||
                                                        !permissions.some(
                                                            (permission) =>
                                                                permission.pagePath === '/cms/userroles/' &&
                                                                permission.permissionLevel === 'read_write'
                                                        )
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : 'hover:text-orange-500 cursor-pointer'
                                                    } 
            ${showConfirmationModal === indexVal
                                                        ? 'text-orange-500'
                                                        : 'text-red-600'
                                                    }`}
                                                onClick={() => {
                                                    if (
                                                        item?.role1 !== 'Admin' &&
                                                        permissions.some(
                                                            (permission) =>
                                                                permission.pagePath === '/cms/userroles/' &&
                                                                permission.permissionLevel === 'read_write'
                                                        )
                                                    ) {
                                                        setShowConfirmationModal(indexVal);
                                                    }
                                                }}
                                            />
                                        </td>

                                        {showConfirmationModal === indexVal && (
                                            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[60]">
                                                <div className="bg-white text-[1.2em] border-gray-300 border-1 rounded-lg shadow-lg max-w-[52vw] max-h-[95vh] p-4 overflow-y-auto">
                                                    <p className='text-[1.1em] py-[1vh] '>
                                                        Are you sure you want to delete the user <span className='font-semibold text-blue-700'>{item?.email}</span>?
                                                    </p>
                                                    <div className="modal-buttons pr-[1vw] gap-x-1 py-[2vh] flex flex-row justify-end">
                                                        <button
                                                            className="px-[1.2vw] py-[0.7vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={() => handleDeleteUser(item._id)}
                                                        >
                                                            Yes
                                                        </button>
                                                        <button
                                                            className="px-[1.2vw] py-[0.7vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
                                                            onClick={() => setShowConfirmationModal(null)}
                                                        >
                                                            No
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={visibleColumns.length + 3} className="py-2 pl-2">
                                        No matching data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default AddUser;