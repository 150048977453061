import axios from 'axios';
import {
  REQUEST_UUID_DATA,
  RECEIVE_UUID_DATA,
  REQUEST_FAILED_UUID_DATA,
  SET_SORT_OPTIONS,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
  SET_FILTERS
} from './uuid.action_type';

let eventSource = null; // Define eventSource outside the action creator

export const fetchUuidDataFromAPI = (userId,orgId, pageSize, pageNumber, sortBy, sortOrder, filters,actionStatus) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_UUID_DATA });

    // Close previous eventSource if exists

    if (eventSource) {
      eventSource.close();
    }

    const queryParams = new URLSearchParams();
    queryParams.set('filters', JSON.stringify(filters.filters));
    queryParams.set('page', pageNumber);
    queryParams.set('pageSize', pageSize);
    queryParams.set('sortBy', sortBy);
    queryParams.set('sortOrder', sortOrder);
    queryParams.set('userId', userId);
    queryParams.set('actionStatus', actionStatus);

    const apiUrl = `${process.env.REACT_APP_HOST_NAME}/api/fetch/alerts/${orgId}?${queryParams}`;

    eventSource = new EventSource(apiUrl);

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        dispatch({ type: RECEIVE_UUID_DATA, payload: data });
        console.log(data,"data");
      } catch (error) {
        console.error('Error parsing event data:', error);
        dispatch({ type: REQUEST_FAILED_UUID_DATA, payload: error?.message });
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      dispatch({ type: REQUEST_FAILED_UUID_DATA, payload: error?.message });
    };

    // Clean up function
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  };
};

export const setSortOptions = (sortBy, sortOrder) => ({
  type: SET_SORT_OPTIONS,
  payload: { sortBy, sortOrder }
});

export const setPageSize = (pageSize) => ({
  type: SET_PAGE_SIZE,
  payload: pageSize,
});

export const setPageNumber = (pageNumber) => ({
  type: SET_PAGE_NUMBER,
  payload: pageNumber,
});

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});
