import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header1';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { passwordStrength } from 'check-password-strength';
import { FaCheck } from 'react-icons/fa6';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle new password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        otp: ''
    })
    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };
    const [step, setStep] = useState(1);
    const [otpRequest, setOtpRequest] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false); // To check if OTP is sent
    const [timer, setTimer] = useState(0); // To track countdown in seconds

    const countdownRef = useRef(null); // Store countdown reference

    // Effect to check for existing timer on component mount
    useEffect(() => {
        const savedTimer = localStorage.getItem('otpTimerForgot');
        const savedStartTime = localStorage.getItem('otpStartTimeForgot');

        if (savedTimer && savedStartTime) {
            const timerValue = parseInt(savedTimer, 10);
            const startTime = parseInt(savedStartTime, 10);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

            // Calculate how much time is left
            const remainingTime = (startTime + timerValue) - currentTime;
            if (remainingTime > 0) {
                setTimer(remainingTime); // Set the timer to remaining time
                setIsOtpSent(true);
                startTimer(remainingTime); // Start the timer with the remaining time
            } else {
                // Reset timer if it has expired
                resetTimer();
            }
        }

        // Clean up function to clear interval on unmount
        return () => clearInterval(countdownRef.current);
    }, []); // Run once on mount

    // Function to start the timer
    const startTimer = (initialTime) => {
        // Clear any existing interval to avoid multiple intervals running
        clearInterval(countdownRef.current);

        countdownRef.current = setInterval(() => {
            setTimer((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(countdownRef.current);
                    resetTimer(); // Reset timer when it expires
                    return 0;
                }
                const newTime = prevTime - 1;
                localStorage.setItem('otpTimerForgot', newTime); // Save the current timer value in localStorage
                return newTime;
            });
        }, 1000); // Update every second
    };

    // Function to reset the timer
    const resetTimer = () => {
        localStorage.removeItem('otpTimerForgot'); // Clear timer from localStorage
        localStorage.removeItem('otpStartTimeForgot'); // Clear start time
        setIsOtpSent(false); // Reset OTP sent status
    };

    // Function to format time in mm:ss format
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const [score, setScore] = useState({
        length: 0,
        value: '',
        contains: []
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target; // Extract name and value from the input
        setFormData((prevState) => ({
            ...prevState,
            [name]: value // Dynamically update the correct field based on the name attribute
        }));
        if (name === 'password') {
            const passwordStrengthResult = passwordStrength(value);
            // console.log(passwordStrengthResult);
            setScore((prevScore) => ({
                ...prevScore,
                value: passwordStrengthResult.value,
                length: passwordStrengthResult.length,
                contains: passwordStrengthResult.contains
                // corrected 'lentgh' to 'length'
            }));
        }
    };
    // Handle OTP generation
    const handleGenerateOTP = (e) => {
        e.preventDefault();
        setOtpRequest(true); // Indicate that an OTP request is in progress

        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/forgot-password/otp/generate`, { email })
            .then((response) => {
                setStep(2); // Move to the next step (e.g., OTP verification)
                setIsOtpSent(true); // Mark OTP as sent
                const otpTimerDuration = 180; // Set timer for 3 minutes

                localStorage.setItem('otpTimerForgot', otpTimerDuration); // Save the initial timer value in local storage
                localStorage.setItem('otpStartTimeForgot', Math.floor(Date.now() / 1000)); // Save the current time in seconds
                startTimer(otpTimerDuration); // Start the timer
                setTimer(otpTimerDuration); // Initialize the timer state

                toast.success(response?.data?.msg);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error || err?.message);
            })
            .finally(() => {
                setOtpRequest(false); // Reset request state
            });
    };

    // Handle OTP verification
    const handleVerifyOTP = (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        if(score.value === 'Weak'  || score.value === 'Too weak'){
            toast.error('Password strength is weak. Please choose a stronger password.');
            return;
        }
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/forgot-password/reset`, {
            otp:formData.otp,
            email,
            newPassword: formData.password,
            confirmPassword:formData.confirmPassword
        })
            .then((response) => {
                toast.success(response?.data?.msg);
                setConfirmPassword('');
                setPassword('');
                setOtp('');
                setEmail('');
                localStorage.removeItem('otpTimerForgot'); // Clear timer from local storage
                localStorage.removeItem('otpStartTimeForgot'); // Clear start time

                setIsOtpSent(false); // Reset OTP sent status
                navigate('/login'); // Redirect to login
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error || err?.message);
            });
    };
// console.log(score);

    return (
        <>
            <Helmet>
                <title>Matisoft | Forgot Password</title>
            </Helmet>
            <Header />
            <div className='bg-blue-200 relative h-[94vh] w-full flex items-center justify-center login_Container'>
                <div className='bg-white px-[1vw] py-[4vh] rounded-md shadow-md w-[84vw] '>
                    <h2 className='text-[1.5em] px-[1vw] font-[500] mb-[2vh]  text-white bg-[#458EA2]  text-center'>Reset Password</h2>
                    <div className='flex flex-col md:flex-row justify-around'>
                        <form onSubmit={handleGenerateOTP} className='w-full md:w-1/3 p-4'>
                            <div className='mb-4'>
                                <label className='block text-gray-800 py-[1vh]'>Email Id</label>
                                <input
                                    type='email'
                                    name='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='Enter your email'
                                    required
                                    maxLength='254'
                                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                                />
                            </div>
                            <div className="flex flex-col justify-between items-center mb-4 gap-y-1">
                                <button
                                    type='submit'
                                    className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200'
                                    disabled={otpRequest || timer > 0} // Disable when request is in progress or timer is running
                                >
                                    {otpRequest ? "Please wait..." : isOtpSent ? "Resend OTP" : "Generate OTP"}
                                </button>
                                {timer > 0 && (
                                    <p className="text-blue-700 ml-4 mt-[0.5vh] text-[0.8em]">Resend OTP after {formatTime(timer)}</p>
                                )}
                            </div>
                        </form>
                        <div className='hidden md:block border-r border-gray-400 h-[60vh]'></div>
                        <form onSubmit={handleVerifyOTP} className='w-full md:w-1/3 p-4'>
                            <div className='mb-4'>
                                <label className='block text-gray-800 py-[1vh]'>OTP</label>
                                <input
                                    type='text'
                                    name='otp'
                                    value={formData.otp}
                                    onChange={handleInputChange}
                                    placeholder='Enter OTP'
                                    required
                                    maxLength='6'
                                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                                />
                            </div>
                            <div className='mb-4 relative'>
                                <label className='block text-gray-800 py-[1vh]'>New Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                                    name='password'
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                    maxLength='16'
                                    placeholder='Enter your new password'
                                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                                />
                                <span
                                    className='absolute inset-y-0 right-3 top-[43%] flex items-center cursor-pointer'
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icons for toggling */}
                                </span>
                            </div>
                            <div className='mb-2 relative'>
                                <label className='block text-gray-800 py-[1vh]'>Confirm Password</label>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                                    name='confirmPassword'
                                    value={formData.confirmPassword}
                                    maxLength='16'
                                    onChange={handleInputChange}
                                    required
                                    placeholder='Confirm your new password'
                                    className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                                />
                                <span
                                    className='absolute inset-y-0  right-3 flex items-center cursor-pointer'
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icons for toggling */}
                                </span>
                            </div>
                            {formData.password?.length > 0 && (
                                formData.password === formData.confirmPassword ? (
                                    <div className='text-green-700 flex flex-col text-[0.9em] items-end'>
                                        <div>Passwords match</div>
                                    </div>
                                ) : (
                                    <div className='text-red-600 flex justify-end text-[0.9em]'>
                                        Passwords do not match
                                    </div>
                                )
                            )}

                            {/* Password Requirements */}
                            <div className="text-[0.8em]">
                                <p>Password must contain:</p>
                                <div className="list-inside grid grid-cols-2">
                                    <div className={`flex flex-row gap-x-1 items-center ${score.length >= 8 ? 'text-blue-600' : 'text-gray-500'}`}>
                                        <FaCheck /> At least 8 characters
                                    </div>
                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("lowercase") ? 'text-blue-600' : 'text-gray-500'}`}>
                                        <FaCheck /> Lowercase
                                    </div>
                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("uppercase") ? 'text-blue-600' : 'text-gray-500'}`}>
                                        <FaCheck /> Uppercase
                                    </div>
                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("number") ? 'text-blue-600' : 'text-gray-500'}`}>
                                        <FaCheck /> Numbers
                                    </div>
                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("symbol") ? 'text-blue-600' : 'text-gray-500'}`}>
                                        <FaCheck /> Special Characters
                                    </div>
                                </div>
                            </div>

                            <button
                                type='submit'
                                className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200'
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
