import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import default styles for react-tabs
import Sidebar1 from '../../common/Sidebar1';
import { Helmet } from 'react-helmet';
import axios from 'axios'; // Assuming axios is used for API calls
import 'react-tabs/style/react-tabs.css'; // Import default styles
import { passwordStrength } from 'check-password-strength';
import { FaCheck } from 'react-icons/fa';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { IoMdArrowDropright } from "react-icons/io";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { setUserId } from '../../../Redux/companyid/companyid.action';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../../utils/ConfirmationDialog';
import SidebarLicense from '../../common/SidebarLicense';
import { NavLink } from 'react-router-dom';
import { FaCircleInfo } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {

    Oval,

} from 'react-loader-spinner';
import Error from '../../../pages/Error';
const Account1 = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { userId, permissions, userAuthData, adminAuthData, errorUserAuth } = useSelector((state) => ({
        userId: state.companyid.userId,
        permissions: state.companyid.permissions,
        userAuthData: state.userAuth.userAuthData,
        adminAuthData: state.adminAuth.adminAuthData,
        errorUserAuth: state.userAuth.errorUserAuth

    }))
    const validatePhone = (combinedPhone) => {
        // Parse the phone number directly
        const phoneNumber = parsePhoneNumberFromString(combinedPhone);
        // Validate the phone number
        return phoneNumber && phoneNumber.isValid();
    };
    const [tabIndex, setTabIndex] = useState(0);
    const [showConfirmationModal, setShowConfirmationModal] = useState(null);

    const [isSidebarExpanded, setSidebarExpanded] = useState(true);
    const [updateData, setUpdatedData] = useState(false);
    const [step, setStep] = useState(1); // Step 1: Verify old email, Step 2: Enter OTP, Step 3: Update new email
    const [otpRequest, setOtpRequest] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false); // To check if OTP is sent

    const [timer, setTimer] = useState(0); // To track countdown in seconds
    const updateStepInURL = (step) => {
        const params = new URLSearchParams(location.search);
        params.set('step', step); // Add or update the query parameter
        navigate({ search: params.toString() }); // Use navigate instead of history.push
    };
    // State for Basic Information form
    const [basicInfo, setBasicInfo] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: ''
    });
    // console.log(permissions, 'permissionss');

    // State for Password form
    const [changePassword, setChangePassword] = useState({
        password: '',
        confirmPassword: ''
    });
    const [score, setScore] = useState({
        length: 0,
        value: '',
        contains: []
    });
    // State for Organisation settings
    const [orgSettings, setOrgSettings] = useState({
        orgName: '',
        country: '',
        current_antivirus: '',
        state: ''
    });
    const [phone, setPhone] = useState('');

    const [emailUpdate, setEmailUpdate] = useState({
        oldEmail: '',
        newEmail: "",
        otp: '',
    });

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleCancel = () => {
        // Show the confirmation dialog
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        // Handle the cancellation (e.g., reset form, navigate away, etc.)
        // setFormData(initialFormData);
        setShowConfirmation(false);
        // Reset or clear the form data if needed
    };

    const handleCloseDialog = () => {
        // Close the confirmation dialog
        setShowConfirmation(false);
    };

    const COUNTER_KEY = 'my-timer-counter-account'; // Key to store timer value in localStorage
    const START_TIME_KEY = 'my-timer-start-time-account'; // Key to store the start time
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedStartTime = localStorage.getItem(START_TIME_KEY);
        if (savedStartTime) {
            const elapsed = Math.floor(Date.now() / 1000) - parseInt(savedStartTime, 10);
            const initialTimeLeft = Math.max(180 - elapsed, 0); // Calculate remaining time
            return initialTimeLeft;
        }
        return 180; // Default to 180 seconds if no start time exists
    });

    const [isActive, setIsActive] = useState(() => {
        const startTime = localStorage.getItem(START_TIME_KEY);
        return !!startTime; // If there's a saved start time, consider the timer active
    });

    useEffect(() => {
        let timer;
        const calculateTimeLeft = () => {
            const startTime = parseInt(localStorage.getItem(START_TIME_KEY), 10);
            if (!startTime) return;

            const currentTime = Math.floor(Date.now() / 1000);
            const elapsedTime = currentTime - startTime;
            const remainingTime = 180 - elapsedTime; // Assuming timer was initially 120 seconds

            if (remainingTime > 0) {
                setTimeLeft(remainingTime);
            } else {
                // If time has expired, stop the timer and clean up localStorage
                setTimeLeft(0);
                localStorage.removeItem(COUNTER_KEY);
                localStorage.removeItem(START_TIME_KEY);
                setIsActive(false);
            }
        };

        if (isActive && timeLeft > 0) {
            timer = setInterval(() => {
                calculateTimeLeft();
            }, 1000);
        }

        // Clean up timer on component unmount or when the timer is paused
        return () => clearInterval(timer);
    }, [isActive, timeLeft]);
    // console.log(emailUpdate?.newEmail);

    useEffect(() => {
        if (timeLeft <= 0 && isActive) {
            localStorage.removeItem(COUNTER_KEY);
            localStorage.removeItem(START_TIME_KEY);
            setIsActive(false);
        }
    }, [timeLeft, isActive]);

    const startTimer = () => {
        const startTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        localStorage.setItem(START_TIME_KEY, startTime);
        localStorage.setItem(COUNTER_KEY, 180); // Save initial time (120 seconds)
        setIsActive(true);
        setTimeLeft(180); // Reset the timer    
    };
    // Parse query parameters on component mount
    useEffect(() => {
        const query = queryString.parse(location.search);
        if (query.step === 'otp') {
            setStep(2);  // Set the step to OTP verification if query says so
        } else if (query.step === 'confirm') {
            setStep(3);  // Set step to final confirmation if query says so
        }
    }, [location.search]);

    // Fetch initial data on page load of basic Information
    useEffect(() => {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('/');
        const fileid = null;
        const userid = parts[parts?.length - 1]

        dispatch(setUserId(userid))
        const fetchBasicInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_NAME}/api/webuser/${userid}`);
                setBasicInfo({
                    firstName: response?.data?.first_name,
                    lastName: response?.data?.last_name,
                    phoneNumber: response?.data?.phone
                });
                setEmailUpdate((prevState) => ({
                    ...prevState,
                    oldEmail: response?.data?.email
                }))
                // Check if phone number starts with '+' (indicating a country code)
                // if (!basicInfo?.phoneNumber?.startsWith('+')) {
                //     setCountry(''); 
                // }
            } catch (error) {
                toast.error(error?.response?.data || error?.message)
                // console.error('Error fetching basic info:', error?.response?.data );
            }
        };

        // API call to fetch organisation settings
        const fetchOrgSettings = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_NAME}/api/view/user/org/${userid}`);

                setOrgSettings({
                    orgName: response?.data?.data?.name,
                    country: response?.data?.data?.country,
                    state: response?.data?.data?.state,
                    current_antivirus: response?.data?.data?.current_antivirus
                });
            } catch (error) {
                // console.error('Error fetching organisation settings:', error);
            }
        };

        // Call all three functions to prefill data
        fetchBasicInfo();

        fetchOrgSettings();
    }, [updateData]);
    const [country, setCountry] = useState('');  // This will handle the country flag

    const handlePhoneChange = (phone, countryData) => {
        // Only update state if there are actual changes to avoid unnecessary re-renders
        const formattedPhone = `+${phone}`;
        setPhone(formattedPhone);
        setBasicInfo((prevForm) => ({ ...prevForm, phoneNumber: formattedPhone }));
    };

    const handleInputChange = (e, section) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        switch (section) {
            case 'basicInfo':
                setBasicInfo((prevState) => ({
                    ...prevState,
                    [name]: newValue
                }));
                break;
            case 'password':
                setChangePassword((prevState) => ({
                    ...prevState,
                    [name]: newValue
                }));
                if (name === 'password') {
                    const passwordStrengthResult = passwordStrength(value);
                    setScore((prevScore) => ({
                        ...prevScore,
                        value: passwordStrengthResult.value,
                        length: passwordStrengthResult.length,
                        contains: passwordStrengthResult.contains
                        // corrected 'lentgh' to 'length'
                    }));
                }
                break;
            case 'orgSettings':
                setOrgSettings((prevState) => ({
                    ...prevState,
                    [name]: newValue
                }));
                break;
            default:
                break;
        }
    };
    // const handleOrgSettingsChange = (name, value) => {
    //     setOrgSettings((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    // Function to verify OTP
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setOtpRequest(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/otp/verify`, {
                userId: userId,
                otp: emailUpdate.otp.toString(),
                email: emailUpdate.newEmail
            });
            localStorage.removeItem('otpTimerAccount'); // Remove the timer from local storage
            setTimer(0); // Reset timer state
            setIsOtpSent(false); // Reset OTP sent status
            // console.log(response,"verify otp");

            toast.success(response?.data);
            setEmailUpdate((prevState) => ({
                ...prevState,
                newEmail: '',
                otp: ''
            }))
            localStorage.removeItem('otpTimerAccount');
            localStorage.removeItem('otpStartTimeAccount');
            localStorage.removeItem('emailAccount');
            localStorage.removeItem(COUNTER_KEY);
            localStorage.removeItem(START_TIME_KEY);
            setUpdatedData(!updateData);
            setStep(3); // Move to the new email update step
            const params = new URLSearchParams(location.search);
            params.delete('step');
            navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
        } catch (error) {
            // console.log(error ,"Error");

            toast.error(error?.response?.data?.error || error?.message);
        } finally {
            setOtpRequest(false);
        }
    };

    // Enable or disable buttons based on input validity
    const isEmailValid = emailUpdate.newEmail.includes('@');
    const isOtpValid = emailUpdate.otp.length === 6; // Assuming OTP is a 6-digit number

    // Step 3: Update new email
    const handleEmailUpdateSubmit = async (e) => {

        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/otp/verify`, {
                otp: emailUpdate.otp,
                userId: userId
            });
            toast.success('Email updated successfully!');
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
    };
    const countdownRef = useRef(null); // Store countdown reference

    // Effect to check for existing timer on component mount

    useEffect(() => {
        const storedEmail = localStorage.getItem('emailAccount');
        const storedTimer = parseInt(localStorage.getItem('otpTimerAccount'));
        const queryParams = new URLSearchParams(location.search);
        const step = queryParams.get('step'); // Get the step from the URL query parameter

        if (storedEmail) {
            setEmailUpdate(prevState => ({
                ...prevState,
                newEmail: storedEmail
            }));
            // updateStepInURL('verify')
        }
        if (storedTimer) {
            const elapsedTime = Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem('otpStartTimeAccount'));
            const remainingTime = storedTimer - elapsedTime;
            if (remainingTime > 0) {
                setTimer(remainingTime);
            } else {
                localStorage.removeItem('otpTimerAccount');
                localStorage.removeItem('otpStartTimeAccount');
                // localStorage.removeItem('emailAccount');
                setIsOtpSent(false);
            }
        }

        // If step is 'verify', set the OTP step as active
        if (step === 'verify') {
            setIsOtpSent(true);
        }

        // Decrement the timer every second
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer > 0) {
                    localStorage.setItem('otpTimerAccount', prevTimer - 1); // Save the updated time in localStorage
                    return prevTimer - 1;
                } else {
                    clearInterval(intervalId); // Stop the timer when it reaches zero
                    return 0;
                }
            });
        }, 1000);

        // Cleanup interval on unmount
        return () => clearInterval(intervalId);

    }, [location.search, setIsOtpSent]);

    // Function to start the timer
    // const startTimer = (initialTime) => {
    //     clearInterval(countdownRef.current);

    //     countdownRef.current = setInterval(() => {
    //         setTimer((prevTime) => {
    //             if (prevTime <= 1) {
    //                 clearInterval(countdownRef.current);
    //                 resetTimer(); 
    //                 return 0;
    //             }
    //             const newTime = prevTime - 1;
    //             localStorage.setItem('otpTimerAccount', newTime);
    //             return newTime;
    //         });
    //     }, 1000);
    // };

    // Function to reset the timer
    const resetTimer = () => {
        localStorage.removeItem('otpTimerAccount'); // Clear timer from localStorage
        localStorage.removeItem('otpStartTimeAccount'); // Clear start time
        setIsOtpSent(false); // Reset OTP sent status
    };
    // Function to format time in mm:ss format
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Step 1: Send OTP to verify old email
    const [emailRequest, setEmailRequest] = useState(false);
    // Function to send OTP
    const handleSendOtp = async (e) => {
        e.preventDefault();
        if (emailUpdate.newEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailUpdate.newEmail)) {
            toast.error("Please input a valid Email ID");
            return;
        }
        setEmailRequest(true);
        setOtpRequest(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/send/otp`, {
                email: emailUpdate.newEmail,
                userId: userId
            });
            toast.success(response?.data?.msg);
            setIsOtpSent(true); // Mark OTP as sent
            // console.log(response,"send otp");

            const otpTimerDuration = 180; // Set timer for 3 minutes
            localStorage.setItem('otpTimerAccount', otpTimerDuration); // Save the initial timer value in local storage
            localStorage.setItem('otpStartTimeAccount', Math.floor(Date.now() / 1000)); // Save the current time in seconds
            localStorage.setItem('emailAccount', emailUpdate.newEmail); // Save the initial timer value in local storage

            startTimer(otpTimerDuration); // Start the timer
            setTimer(otpTimerDuration); // Initialize the timer state
            setStep(2);
            updateStepInURL('verify'); // Update the URL to indicate that the OTP verification step has started


        } catch (error) {
            toast.error(error?.response?.data || error?.message);
        } finally {
            setEmailRequest(false);
            setOtpRequest(false);
        }
    };


    const handleEmailChange = (name, value) => {
        setEmailUpdate((prevState) => ({
            ...prevState,
            [name]: value, // Check if the value is a number
        }));
    };
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    const isValidPhoneNumber = (inputNumber, country, countries) => {
        if (inputNumber.length === 0) return true; // Allow empty input initially
        const isValid = countries.some(
            (countryData) => inputNumber.startsWith(countryData.dialCode)
        );
        return isValid;
    };
    const handleSubmit = async (e, type) => {
        e.preventDefault();

        let payload = {};
        // Decide payload based on the type
        if (type === 'basicInfo') {

            payload = { _id: userId, phone: basicInfo.phoneNumber, firstName: basicInfo.firstName, lastName: basicInfo.lastName, };
        } else if (type === 'password') {
            if (changePassword.password !== changePassword.confirmPassword) {
                toast.error("Passwords do not match!"); return;
            }
            else if (score.value === "Too weak" || score.value === "Weak") {
                toast.error('Password strength is weak. Please choose a stronger password!'); return;
            }
            payload = { _id: userId, update_type: "password_update", change_Password: changePassword.password, password: changePassword.confirmPassword };
        }
        else if (type === 'email_update') {
            payload = {
                _id: userId, update_type: "email_update",
                email: emailUpdate.newEmail
            };

        }
        if (payload.phone && !validatePhone(payload.phone)) {
            // setIsPhoneValid(false);
            toast.error("Please input valid phone number.");
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/update-profile`, payload);
            const successMessage = response?.data?.message; // Extract the success message from the response
            toast.success(successMessage); // Show the message using toast
            setUpdatedData(!updateData);
            setStep(1);
            localStorage.removeItem('otpTimerAccount'); // Clear timer from local storage

            localStorage.removeItem('otpStartTimeAccount'); // Clear start time

            setChangePassword({
                password: '',
                confirmPassword: ''
            });



        } catch (error) {
            // console.log(error, "error in email update");

            toast.error(error?.response?.data || error?.message);
        }
    };

    // Submit handler for Organisation Settings
    const handleOrgSettingsSubmit = async (e) => {
        const payload = {
            userId: userId,
            name: orgSettings.orgName,
            country: orgSettings.country,
            current_antivirus: orgSettings.current_antivirus,
            state: orgSettings.state
        };
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/update/org`, { ...payload });
            const successMessage = response?.data?.message; // Extract the success message from the response
            toast.success(successMessage); // Show the message using toast
            setUpdatedData(!updateData);
        } catch (error) {
            toast.error(error?.response?.data || error?.message);
        }
    };
    const handleDeleteAccount = async (e) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/subuser/account/delete`, {
                "userId": userId
            });

            // Show success message from the response

            toast.success(response?.data?.message)
            // Optionally, redirect to login page or home page after deletion
            window.location.href = '/';
        } catch (error) {
            // Show error message from the response
            toast.error(error?.response?.data || error?.message)

            // console.error('Error deleting account:', error);
        }

    };
    if (errorUserAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | User Account Settings</title>
            </Helmet>
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed`}>
                {adminAuthData?.role?.includes("ADMINISTRATOR") ? (
                    <SidebarLicense isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
                ) : (
                    <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
                )}
            </div>

            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%] z-40'} px-[1vw] py-[1vh] relative overflow-x-auto 2xl:font-[30px] licenseCreation`}>
                <h1 className="text-[1.5em]  font-[500] py-[2vh] border-b-[2px] border-b-[#4fd0ff] shadow-md pl-[1vw]">User Account Settings</h1>

                <div className="bg-white shadow-md rounded-lg p-4 mt-[1vh]">
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList className="flex border-b bg-blue-100">
                            <Tab className={`p-2 cursor-pointer   ${tabIndex === 0 ? ' border-b-2 font-[500] !border-b-[#4fd0ff]' : ''}`}>Basic Information</Tab>
                            <Tab className={`p-2 cursor-pointer   ${tabIndex === 1 ? ' border-b-2 font-[500] !border-b-[#4fd0ff]' : ''}`}>Update Email</Tab>
                            <Tab className={`p-2 cursor-pointer ${tabIndex === 2 ? ' border-b-2 font-[500] !border-b-[#4fd0ff]' : ''}`}>Change Password</Tab>
                            {/* <Tab className={`p-2 cursor-pointer ${tabIndex === 3 ? 'border-b-2 !border-b-[#4fd0ff]' : ''}`}>Organisation Settings</Tab> */}
                            <Tab className={`p-2 cursor-pointer ${tabIndex === 3 ? ' border-b-2 font-[500] !border-b-[#4fd0ff]' : ''}`}>Delete User Account</Tab>
                        </TabList>
                        <TabPanel>
                            <>
                                <form onSubmit={(e) => handleSubmit(e, "basicInfo")} className="py-[2vh] ">
                                    <div className='grid grid-cols-2'>  <div className="py-[2vh]">
                                        <label className="block text-[1.1em] py-[1vh]" htmlFor="firstName">First Name</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            value={basicInfo.firstName}
                                            name='firstName'
                                            onChange={(e) => handleInputChange(e, 'basicInfo')}
                                            className="w-[70vw] md:w-[35vw] bg-gray-100 px-[1vw] py-[1.2vh] border rounded-lg"
                                            placeholder="Enter your first name"
                                        />
                                    </div>
                                        <div className="py-[2vh]">
                                            <label className="block text-[1.1em] py-[1vh]" htmlFor="lastName">Last Name</label>
                                            <input
                                                type="text"
                                                id="lastName"
                                                name='lastName'
                                                value={basicInfo.lastName}
                                                onChange={(e) => handleInputChange(e, 'basicInfo')}
                                                className="w-[70vw] md:w-[35vw] bg-gray-100 px-[1vw] py-[1.2vh] border rounded-lg"
                                                placeholder="Enter your last name"
                                            />
                                        </div>
                                        <div className="py-[2vh] flex flex-col gap-[1vh]">
                                            <label className="text-[1.1em]" htmlFor="phoneNumber">Phone Number</label>
                                            <PhoneInput
                                                country='us'  // If no country is detected, set it to undefined
                                                value={basicInfo.phoneNumber} onChange={handlePhoneChange}    // Handle phone number changes
                                                inputProps={{
                                                    name: 'phone',
                                                    required: false,
                                                    autoFocus: false,
                                                    placeholder: 'Enter phone number',
                                                }}
                                                containerStyle={{
                                                    width: '35vw',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '40px',
                                                    background: ''
                                                }}
                                                inputStyle={{
                                                    height: '40px',
                                                    width: 'calc(100% - 11%)',
                                                    boxSizing: 'border-box',
                                                    border: '1px solid #e5e7eb',
                                                    borderRadius: '4px',
                                                    padding: '1vh 1vw',
                                                    marginLeft: '11%',
                                                    backgroundColor: '#f3f4f6',
                                                    fontSize: '1em',
                                                }}
                                                dropdownStyle={{
                                                    height: '300px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    bottom: '100%', // Position dropdown above the input
                                                    transform: 'translateY(-5px)', // Small spacing adjustment upwards
                                                }}
                                                disableSearchIcon
                                                enableSearch={true}
                                                isValid={isValidPhoneNumber}

                                            // Disable automatic country code detection
                                            />
                                        </div>

                                    </div>
                                    <button type="submit"

                                        className="bg-blue-500 text-white py-[1.3vh] px-[1vw] cursor-pointer rounded-md hover:bg-blue-600">Save Changes
                                    </button>
                                </form>
                                {/* <hr /> */}

                            </>

                        </TabPanel>
                        <TabPanel>
                            <>
                                <div className='font-[500] pt-[4vh] pb-[2vh]'>Current Email Address: <span className='font-[600] text-gray-900'> {emailUpdate?.oldEmail}</span></div>
                                <hr />
                                {!isOtpSent ? (
                                    <form onSubmit={handleSendOtp} className="mb-6"> {/* Added margin bottom for separation */}
                                        {/* <h1 className='text-blue-700 mt-[2vh]'>Step 1</h1> */}
                                        <div className="flex flex-col gap-y-4 mt-4">
                                            <label htmlFor="newEmail" className="font-[600] mb-2">New Email Address:</label>
                                            <div className='flex flex-row items-center gap-x-[1vw]'>
                                                <input
                                                    type="email"
                                                    id="newEmail"
                                                    value={emailUpdate.newEmail}
                                                    onChange={(e) => setEmailUpdate({ ...emailUpdate, newEmail: e.target.value })}
                                                    required
                                                    maxLength='254'
                                                    className="border p-2 rounded bg-gray-100 w-[20vw]"
                                                    placeholder="Enter new email address"
                                                />
                                                <button
                                                    type="submit"
                                                    disabled={!isEmailValid || emailRequest}
                                                    className="bg-blue-500 flex flex-row items-center gap-x-1 text-white hover:bg-blue-600 px-[1vw] py-[1vh] rounded"
                                                >
                                                    {emailRequest ? <>     <Oval height="15" width="15" strokeWidth={5} color="#64636b " ariaLabel="loading" />
                                                        Sending </> : "Send OTP"}
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                ) :
                                    (
                                        <>
                                            <h1 className='py-[1vh] pt-[2vh]'>
                                                For your security, we have sent the OTP to your email
                                                <span className="font-semibold text-gray-900">{` ${localStorage.getItem("emailAccount")}`}</span>.
                                                <span
                                                    className="text-blue-600 cursor-pointer"
                                                    onClick={() => {
                                                        setIsOtpSent(false); // Reset to the first step
                                                        updateStepInURL('generate'); // Update URL to indicate the first step
                                                    }}
                                                >
                                                    {` Change`}
                                                </span>
                                            </h1>
                                            <form onSubmit={handleVerifyOtp}>
                                                {/* <h1 className='text-blue-700'>Step 2</h1> */}
                                                <div className="flex flex-col gap-y-4 mt-4">
                                                    <label htmlFor="otp" className="font-[600] mb-2">Enter OTP:</label>
                                                    <div className='flex flex-row items-center gap-x-[1vw]'>
                                                        <input
                                                            type="number"
                                                            id="otp"
                                                            value={emailUpdate.otp}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                // Ensure the value is numeric, within the 6-digit limit, and non-negative
                                                                if (/^\d{0,6}$/.test(value)) {
                                                                    setEmailUpdate({ ...emailUpdate, otp: value });
                                                                }
                                                            }}
                                                            required
                                                            className="border p-2 rounded bg-gray-100 w-[20vw]"
                                                            placeholder="Enter the OTP"
                                                            maxLength="6" // Ensures max length in browsers supporting it for type="number"
                                                            onKeyDown={(e) => {
                                                                // Prevent invalid keys like '+', '-', 'e', etc.
                                                                if (e.key === '+' || e.key === '-' || e.key === 'e') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />


                                                        <button
                                                            type="submit"
                                                            disabled={otpRequest || !isOtpValid}
                                                            className="bg-blue-500 px-[1vw] py-[1vh] text-white hover:bg-blue-600 rounded"
                                                        >
                                                            Verify OTP
                                                        </button>
                                                        <div className="text-blue-600 text-[0.9em]">
                                                            {localStorage.getItem("my-timer-counter-account") ? <span className='text-xs'>
                                                                {`Resend OTP after ${formatTime(timeLeft)}`}
                                                            </span>
                                                                :
                                                                <div
                                                                    onClick={timer > 0 || otpRequest === true ? null : handleSendOtp} // Only allow click if timer is 0
                                                                    className={`pl-1 py-2  rounded-md  text-blue-600 text-center ${timer > 0 || otpRequest ? 'cursor-not-allowed opacity-90' : '  cursor-pointer'
                                                                        }`}
                                                                >
                                                                    {otpRequest ?
                                                                        (
                                                                            <div className='flex flex-row gap-x-1 items-center text-gray-800'>
                                                                                Sending <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                                                            </div>
                                                                        )
                                                                        : 'Resend OTP'}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </form></>)}
                            </>
                        </TabPanel>
                        <TabPanel>
                            <form onSubmit={(e) => handleSubmit(e, "password")} className="pt-[2vh]">
                                <div className="py-[2vh] w-[30vw]">
                                    <label className="block mb-2" htmlFor="currentPassword">Enter New Password</label>
                                    <div className='relative'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            value={changePassword.password}
                                            onChange={(e) => handleInputChange(e, 'password')}
                                            required
                                            className='border p-2 rounded w-full bg-gray-100'
                                            maxLength='16'
                                        />
                                        {showPassword ? (
                                            <BsEyeSlashFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={togglePasswordVisibility}
                                            />
                                        ) : (
                                            <BsEyeFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={togglePasswordVisibility}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-row items-center gap-x-[5vw]'>
                                    <div className="py-[2vh] w-[30vw]">
                                        <label className="block mb-2" htmlFor="newPassword">Confirm New Password</label>
                                        <div className='relative'>
                                            <input
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name='confirmPassword'
                                                value={changePassword.confirmPassword}
                                                onChange={(e) => handleInputChange(e, 'password')}
                                                required
                                                className='border p-2 rounded w-full bg-gray-100'
                                                maxLength='16'
                                            />
                                            {showConfirmPassword ? (
                                                <BsEyeSlashFill
                                                    className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                    onClick={toggleConfirmPasswordVisibility}
                                                />
                                            ) : (
                                                <BsEyeFill
                                                    className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                    onClick={toggleConfirmPasswordVisibility}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div>{changePassword.password != '' && changePassword.confirmPassword !== '' && (changePassword.confirmPassword === changePassword.password ? <div className='text-green-700 text-[0.9em]'>(Passwords match)</div> : <div className='text-red-600 text-[0.9em] '>(Passwords do not match)</div>)}
                                    </div></div>
                                <div className='text-blue-700 text-[0.9em] py-[0.5vh]'>(Your password should contain at least 8 characters and the properties listed below)</div>

                                <div className='flex md:flex-row flex-col-reverse justify-between text-[0.9em] py-[1vh] '>
                                    <div className='  py-[1vh] '>
                                        Length: {score.length ? score.length : 0}, &nbsp;
                                        Your password is  {score.value ? <span className={`
                                           ${score.value === 'Too weak' ? 'bg-red-600 text-white' :
                                                score.value === 'Weak' ? 'bg-orange-500' :
                                                    score.value === 'Medium' ? 'bg-yellow-500' :
                                                        'bg-green-600 text-white    '} px-[0.6vw] py-[0.7vh]
                            `}>
                                            {score.value}
                                        </span> : <span className='border-b-[1px] w-2'></span>}
                                    </div>

                                </div>
                                <div className=' grid grid-cols-2 md:grid-cols-4 gap-y-[1vw] max-w-[750px] text-[0.9em] pb-[3vh]'>
                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("lowercase") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Lowercase</div>
                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("uppercase") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Uppercase</div>

                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("number") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Numbers</div>

                                    <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("symbol") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Special Characters</div>

                                </div>
                                <button type="submit"

                                    className="bg-blue-500 text-white py-2 px-4 hover:bg-blue-600 rounded">Update Password</button>
                            </form>
                        </TabPanel>

                        <TabPanel>
                            <form className="pt-[1vh]">
                                <div className={` ${showConfirmationModal ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  `} ></div>

                                <div className='py-[3vh] text-[1.1em]'>
                                    {userAuthData?.role1 !== "Admin"
                                        ? "If you delete this account, you will be logged out and lose access to everything within the account."
                                        : (
                                            <>
                                                An admin cannot delete their own user account, as this would delete the entire account.                                            </>
                                        )
                                    }

                                </div>
                                <button
                                    type="button"
                                    onClick={() => setShowConfirmationModal('delete')}

                                    className=" bg-red-500 border border-red-500 font-[500] text-white py-2 px-4 rounded hover:text-red-700 hover:bg-white hover:shadow-lg"
                                    title={!permissions.some(permission => permission.pagePath === '/cms/account/delete/' && permission.permissionLevel === 'read_write') && "Admin can not be deleted"}
                                    disabled={!permissions.some(permission => permission.pagePath === '/cms/account/delete/' && permission.permissionLevel === 'read_write')}
                                >
                                    Delete Account
                                </button>
                                {showConfirmationModal === 'delete' && (
                                    <div className="confirmation-dialog ">
                                        <div className="modal border-[1px] border-gray-400 " >
                                            <p className='py-[1vh] text-[1.2em]'>Are you sure you want to delete this user account?</p>
                                            <p className=' py-[1vh] text-red-600 flex flex-row gap-x-1 text-[1.1em] items-start'>
                                                <FaCircleInfo className='text-[1em]' /> Deleting this user will revoke your access to Matisoft's cloud platform.
                                            </p>
                                            <div className="modal-buttons gap-x-3 pt-[4vh]">
                                                <button className='px-[2vw] gap-x-4 mx-[1vw]' onClick={() => handleDeleteAccount()}>Yes</button>
                                                <button onClick={() => setShowConfirmationModal(null)}>No</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <ConfirmationDialog
                                    isOpen={showConfirmation}
                                    onClose={handleCloseDialog}
                                    onConfirm={handleConfirmCancel}
                                />
                            </form>
                        </TabPanel>
                    </Tabs>

                </div>
            </div>
        </div>
    );
};

export default Account1;