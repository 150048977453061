import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';

const OtpHandler = ({ email, setEmail, otp, setOtp, otpRequest, setOtpRequest, isOtpSent, setIsOtpSent, onOtpVerified }) => {
    const countdownRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const [errorMessageReq, setErrorMessageReq] = useState('');
    const [errorMessageVerify, setErrorMessageVerify] = useState('');

    const COUNTER_KEY = 'my-timer-counter-forgot'; // Key to store timer value in localStorage
    const START_TIME_KEY = 'my-timer-start-time-forgot'; // Key to store the start time
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedStartTime = localStorage.getItem(START_TIME_KEY);
        if (savedStartTime) {
            const elapsed = Math.floor(Date.now() / 1000) - parseInt(savedStartTime, 10);
            const initialTimeLeft = Math.max(180 - elapsed, 0); // Calculate remaining time
            return initialTimeLeft;
        }
        return 180; // Default to 180 seconds if no start time exists
    });

    const [isActive, setIsActive] = useState(() => {
        const startTime = localStorage.getItem(START_TIME_KEY);
        return !!startTime; // If there's a saved start time, consider the timer active
    });

    useEffect(() => {
        let timer;

        const calculateTimeLeft = () => {
            const startTime = parseInt(localStorage.getItem(START_TIME_KEY), 10);
            if (!startTime) return;

            const currentTime = Math.floor(Date.now() / 1000);
            const elapsedTime = currentTime - startTime;
            const remainingTime = 180 - elapsedTime; // Assuming timer was initially 120 seconds

            if (remainingTime > 0) {
                setTimeLeft(remainingTime);
            } else {
                // If time has expired, stop the timer and clean up localStorage
                setTimeLeft(0);
                localStorage.removeItem(COUNTER_KEY);
                localStorage.removeItem(START_TIME_KEY);
                setIsActive(false);
            }
        };

        if (isActive && timeLeft > 0) {
            timer = setInterval(() => {
                calculateTimeLeft();
            }, 1000);
        }

        // Clean up timer on component unmount or when the timer is paused
        return () => clearInterval(timer);
    }, [isActive, timeLeft]);

    useEffect(() => {
        if (timeLeft <= 0 && isActive) {
            localStorage.removeItem(COUNTER_KEY);
            localStorage.removeItem(START_TIME_KEY);
            setIsActive(false);
        }
    }, [timeLeft, isActive]);

    const startTimer = () => {
        const startTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        localStorage.setItem(START_TIME_KEY, startTime);
        localStorage.setItem(COUNTER_KEY, 180); // Save initial time (120 seconds)
        setIsActive(true);
        setTimeLeft(180); // Reset the timer    
    };

    // Format the time left for the OTP timer
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Update the URL based on the step
    const updateStepInURL = (step) => {
        const params = new URLSearchParams(location.search);
        params.set('step', step); // Add or update the query parameter
        navigate({ search: params.toString() }); // Use navigate instead of history.push
    };

    // Handle OTP generation
    const handleGenerateOTP = (e) => {
        e.preventDefault();
        if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error("Please input a valid Email ID");
            return;
        }
        setOtpRequest(true);
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/forgot-password/otp/generate`, { email })
            .then((response) => {
                toast.success(response?.data?.msg);
                setIsOtpSent(true); // Mark OTP as sent
                const otpTimerDuration = 180; // Set timer for 3 minutes
                localStorage.setItem('otpTimerForgot', otpTimerDuration); // Save the initial timer value in local storage
                localStorage.setItem('otpStartTimeForgot', Math.floor(Date.now() / 1000)); // Save the current time in seconds
                localStorage.setItem('emailForgot', email); // Save email in local storage
                setTimer(otpTimerDuration); // Initialize the timer state
                startTimer();
                setErrorMessageReq('')
                updateStepInURL(2); // Update the URL to indicate that the OTP verification step has started

            })
            .catch((err) => {
                console.error(err);
                // toast.error(err?.response?.data?.error || err?.response?.data || err?.message);
                setErrorMessageReq(err?.response?.data?.error || err?.response?.data || err?.message)
                if (window.innerWidth >= 768) {
                    toast.error(err?.response?.data?.error || err?.response?.data || err?.message);
                }
            })
            .finally(() => {
                setOtpRequest(false);
            });
    };

    // Handle OTP verification
    const handleVerifyOTP = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/forgot-password/otp/verify`, {
            email, otp: otp.toString()
        })
            .then((response) => {
                toast.success(response?.data?.msg);
                onOtpVerified(email); // Call onOtpVerified to trigger step change in ForgotPasswordNew
                localStorage.removeItem('otpTimerForgot');
                localStorage.removeItem('otpStartTimeForgot');
                localStorage.removeItem('emailForgot');
                localStorage.removeItem(COUNTER_KEY);
                localStorage.removeItem(START_TIME_KEY);
                setErrorMessageVerify('');
            })
            .catch((err) => {
                console.error(err);
                setErrorMessageVerify(err?.response?.data?.error || err?.message)
                if (window.innerWidth >= 768) {
                    toast.error(err?.response?.data?.error || err?.message);
                }
            });
    };

    // Handle Resend OTP
    const handleResendOTP = (e) => {
        e.preventDefault();
        handleGenerateOTP(e); // Call handleGenerateOTP to regenerate the OTP
    };

    // Timer update logic
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const storedEmail = localStorage.getItem('emailForgot');
        const storedTimer = parseInt(localStorage.getItem('otpTimerForgot'));
        const storedStartTime = parseInt(localStorage.getItem('otpStartTimeForgot'));

        // Parse step once when component mounts
        const queryParams = new URLSearchParams(location.search);
        const step = queryParams.get('step');

        if (storedEmail) {
            setEmail(storedEmail);
        }

        if (storedTimer && storedStartTime) {
            const elapsedTime = Math.floor(Date.now() / 1000) - storedStartTime;
            const remainingTime = storedTimer - elapsedTime;
            if (remainingTime > 0) {
                setTimer(remainingTime);
            } else {
                localStorage.removeItem('otpTimerForgot');
                localStorage.removeItem('otpStartTimeForgot');
                // setIsOtpSent(false); 
            }
        }

        // Check if step is 'verify' and set isOtpSent accordingly
        if (step === '2') {
            // console.log("Step is 2, setting isOtpSent to true");
            setIsOtpSent(true);
        }

        // Timer interval
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer > 0) {
                    const newTime = prevTimer - 1;
                    localStorage.setItem('otpTimerForgot', newTime); // Save updated time
                    return newTime;
                } else {
                    clearInterval(intervalId); // Stop the timer
                    localStorage.removeItem('otpTimerForgot');
                    localStorage.removeItem('otpStartTimeForgot');
                    return 0;
                }
            });
        }, 1000);

        // Cleanup interval on unmount
        return () => clearInterval(intervalId);

    }, [setEmail, setIsOtpSent]); // Removed location.search to avoid unnecessary re-renders


    return (
        <div className='py-[0.5vh]'>
            <h2 className='text-center text-[1.5em] font-[500] mb-[2vh] text-white bg-[#458EA2]'>Forgot Password</h2>            {/* OTP Generation Form */}
            {!isOtpSent ? (
                <>
                    <p className='py-[1vh] px-[0.5vw] text-gray-800 text-justify'>Enter the email address associated with your Matisoft account to reset your password.
                    </p>
                    {errorMessageReq && <div className='text-justify text-red-600 md:hidden'>{errorMessageReq}</div>}
                    <form onSubmit={handleGenerateOTP} className='flex flex-col gap-y-[5vh] px-[1vw] '>
                        <div className='my-2'>
                            <label className='block text-gray-800 py-[2vh] font-semibold'>Email Id</label>
                            <input
                                type='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter your email'
                                required
                                maxLength='254'
                                className='w-full px-2 py-2 mt-[1vh] border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                            />
                        </div>
                        <button
                            type='submit'
                            disabled={otpRequest}
                            className='w-full bg-blue-500 mb-[2vh] text-white py-2 rounded-md hover:bg-blue-600 transition duration-200'
                        >
                            {otpRequest ? (
                                <div className='flex flex-row items-center justify-center gap-x-1'>
                                    <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                    <span>Sending</span>
                                </div>
                            ) : (
                                'Send OTP'
                            )}
                        </button>
                    </form>
                </>
            ) : (
                // OTP Verification Form
                <form onSubmit={handleVerifyOTP} className='px-[1vw] '>
                    <h1 className='text-gray-800 text-justify'>
                        For your security, we have sent the OTP to your email
                        <span className="font-[600]">{` ${email}`}</span>.
                        <span
                            className="text-blue-600 cursor-pointer"
                            onClick={() => {
                                setIsOtpSent(false); // Reset to the first step
                                updateStepInURL(1); // Update URL to indicate the first step
                            }}
                        >
                            {` Change`}
                        </span>
                    </h1>
                    {errorMessageVerify && <div className='text-red-600 text-justify md:hidden'>{errorMessageVerify}</div>}
                    <div className='mb-[1vh]'>
                        <label className='block text-gray-800 mb-[1vh] py-[2vh] font-semibold'>OTP</label>
                        <input
                            type='number'
                            name='otp'
                            value={otp}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Restrict input to 6 digits and ensure it does not exceed 999999
                                if (value === '' || value.length <= 6 && parseInt(value, 10) <= 999999) {
                                    setOtp(value);
                                }
                            }}
                            placeholder='Enter OTP'
                            required
                            onKeyDown={(e) => {
                                // Prevent '+' or '-' keys
                                if (e.key === '+' || e.key === '-') {
                                    e.preventDefault();
                                }
                            }}
                            className='w-full px-2 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                        />
                    </div>
                    <div className='mb-2 flex items-center justify-between'>

                        {localStorage.getItem("my-timer-counter-forgot") ? <span className='text-xs'>
                            {`Resend OTP after ${formatTime(timeLeft)}`}
                        </span>
                            :
                            <div
                                onClick={timer > 0 || otpRequest ? null : handleResendOTP} // Only allow click if timer is 0
                                className={`pl-1 py-2 text-[0.9em]   rounded-md  text-blue-600 text-center ${timer > 0 || otpRequest ? 'cursor-not-allowed opacity-90' : '  cursor-pointer'
                                    }`}
                            >
                                {otpRequest ?
                                    (
                                        <div className='flex flex-row gap-x-1 items-center text-gray-800'>
                                            Generating <Oval height={15} width={15} strokeWidth={5} color="#64636b" ariaLabel="loading" />
                                        </div>
                                    )
                                    : 'Resend OTP'}
                            </div>
                        }
                    </div>
                    <button
                        type='submit'
                        className='w-full bg-blue-500 text-white py-2  my-[4vh] rounded-md hover:bg-blue-600 transition duration-200'
                    >
                        Verify OTP
                    </button>
                </form>
            )}
        </div>
    );
};

export default OtpHandler;
