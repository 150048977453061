import { combineReducers } from "redux";
import {companyidreducer} from "./companyid/companyid.reducer";
import { reducer as uuidreducer} from './uuid/uuid.reducer'
import {reducer as alertinstancereducer} from './alertInstances/alertInstances.reducer'

import {reducer as actionCenterreducer} from './actionCenter/actionCenter.reducer';
import {reducer as directoryServerreducer} from './directoryServer/directoryServer.reducer';
import {reducer as actionComputer} from './actionComputer/actionComputer.reducer';
import {reducer as workQueue} from './workQueue/workQueue.reducer';
import {reducer as linkingData} from './dashboard/dashboard.reducer';
import {reducer as infectedData} from './dashboard/dashboard.infected.reducer';
import {reducer as dashboardAlerts} from './dashboard/dashboard.alerts.reducer';
import {reducer as topquarantine} from './dashboard/dashboard.quarantine.reducer';

import {reducer as topwhitelist} from './dashboard/dashboard.whitelist.reducer';

import {reducer as topcustom} from './dashboard/dashboard.custom.reducer';

import {reducer as topdelete} from './dashboard/dashbaord.delete.reducer';

import {reducer as toptobeanalysed} from './dashboard/dashbaord.tobeanalysed.reducer';
import {reducer as topallalerts} from './dashboard/dashboard.all.reducer';
import {reducer as scanDevices} from './dashboard/dashboard.scan.reducer';
import {reducer as licence} from './licence/licenceCreate.reducer';
import {reducer as userLicence} from './userLicenceList/userLicence.reducer';
import {reducer as auth} from './auth1/auth.reducer';
import {reducer as companyReducer} from './companyDetail/companyDetail.reducer'
import {reducer as adminAuth} from './adminAuth/adminauth.reducer';
import {reducer as userAuth} from './userAuth/userAuth.reducer';
import {reducer as linkedDevices} from './licence/licenseLinkedDevice.reducer';
import {reducer as linkedDeviceDetail} from './linkedDeviceDetail/linkedDeviceDetail.reducer';
import {reducer as licenseHistory} from './licence/licenseHistoryUser.reducer';
import {reducer as userRoles} from './userRoles/userRoles.reducer';
import {reducer as verifyUser} from './verifyUser/verifyUser.reducer'
import {reducer as allOrg} from './allOrganisation/allOrg.reducer'
import {reducer as allCompanies} from './allCompany/allCompany.reducer'
import {reducer as release} from './release/release.reducer'
import {reducer as programLogs} from './logs/programLog.reducer'
import {reducer as systemLogs} from './logs/sytemLog.reducer'

const rootReducer=combineReducers({
companyid:companyidreducer,
uuid:uuidreducer,
alertinstance:alertinstancereducer,

linkingData:linkingData,
infectedData:infectedData,
workQueue:workQueue,
topcustom:topcustom,
auth:auth,
release:release,
programLogs:programLogs,
systemLogs:systemLogs,
topdelete:topdelete,
topallalerts:topallalerts,
topquarantine:topquarantine,
topcustom:topcustom,
licenseHistory:licenseHistory,
companyReducer:companyReducer,
userLicenceList:userLicence,
verifyUser:verifyUser,
allOrg:allOrg,
allCompanies:allCompanies,

userAuth:userAuth,
userRoles:userRoles,
linkedDevices:linkedDevices,
linkedDeviceDetail:linkedDeviceDetail,
licence:licence,
adminAuth:adminAuth,
topwhitelist:topwhitelist,
toptobeanalysed:toptobeanalysed,
dashboardAlerts:dashboardAlerts,
actionCenter:actionCenterreducer,
directoryServer:directoryServerreducer,
actionComputer:actionComputer,
scanDevices:scanDevices
});
export default rootReducer;