// PreSignUp1.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header1';
import ParticlesBackground from '../components/Particles';
import { Helmet } from 'react-helmet';
// Adjust the import path as necessary

const PreSignUp1 = () => {
    const navigate = useNavigate();
    const [product, setProduct] = useState('');

    const handleInputChange = (e) => {
        setProduct(e.target.value);
    };

    const productOptions = [
        'Data Leak Prevention(DLP)',
        'Endpoint Detection and Response(EDR)',
        'Data Leak Prevention(DLP) & Endpoint Detection and Response(EDR)',
        'Incident response services(IR)',
    ];

    const handleTrialSubmit = (e) => {
        e.preventDefault();
        const url = new URL(window.location.href);
        if (product === 'Incident response services(IR)') {
            window.location.href = '/contact'; // Redirect to server-side rendered EJS page
        } else {
            // console.log(product);
     // Encode the product option
     const encodedProduct = encodeURIComponent(product);
        
     // Navigate with the encoded product option as a query parameter
     navigate(`/signup?Products=${encodedProduct}`);
        }
    };

    return (
        <>
            <Helmet>
                <title>Matisoft | Choose Product  </title>
            </Helmet>
            <Header />
            <ParticlesBackground /> {/* Add the particle background */}
            <div className='flex justify-start items-center min-h-screen z-[50]'>
                <div className='  justify-center absolute top-[30vh] left-[5vw] md:left-[33vw] shadow-md py-[1vh] px-[1vw] text-secondary ' style={{ backgroundColor: 'rgba(56, 198, 233, 0.8)' }}>
                    <form onSubmit={handleTrialSubmit} className='mx-auto'>
                        <div className='w-[90vw] md:w-[30vw] pb-[1vh]'>
                            <label className='text-[1.7em] font-[500] text-primary'>Choose any products:</label>
                            <select
                                name='product'
                                className='input-style bg-blue-200 text-[0.9em] text-primary border-[1px] border-gray-100 custom-select'
                                value={product}
                                onChange={handleInputChange} required
                            >
                                <option disabled hidden value=''>Choose a product</option>
                                {productOptions.map((option, index) => (
                                    <option className='px-[1vw]' key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                        <button className='bg-primary text-[#4fd0ff] px-[1vw] py-[1vh] mt-[2vh] border-[1px] border-primary hover:bg-white hover:text-primary' style={{ fontSize: '1em', marginTop: '2vh', marginBottom: '2vh' }}>
                            Confirm
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PreSignUp1;
