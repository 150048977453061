
import { ERROR_DIRECTORY_SERVER, REQUEST_DIRECTORY_SERVER, SET_DIRECTORY_SERVER,SET_FILTER_DIRECTORY_SERVER,SET_SORT_OPTIONS_DIRECTORY_SERVER,SET_PAGE_NUMBER_DIRECTORY_SERVER,SET_PAGE_SIZE_DIRECTORY_SERVER } from "./directoryServer.action_type";
const intialState = {
    loading: false,
    action: null,
    directoryServer: [],
    dsMessage: null,
    error: null,
    filters: {},
    pageNumber: 1,
    pageSize: 100,
    sortBy: "createdAt",
    sortOrder: -1,
    totalRecords:0
}
// console.log(directoryServer,"diresctSErver.");
const reducer = (state = intialState, action) => {
    // console.log(state.directoryServer,"action taken");
    switch (action.type) {
        case REQUEST_DIRECTORY_SERVER:
            return {
                ...state,
                loading: true,

            }
        case SET_DIRECTORY_SERVER:
            return {
                ...state,
                loading: false,
                directoryServer: action.payload.data,
                dsMessage: action.payload.message,
                totalRecords:action.payload.totalRecords,
                error: null
            }
        case ERROR_DIRECTORY_SERVER:
            return {
                ...state,
                error: action.payload,
            }

        case SET_SORT_OPTIONS_DIRECTORY_SERVER:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_DIRECTORY_SERVER:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_DIRECTORY_SERVER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_DIRECTORY_SERVER: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state
    }


}
export { reducer };