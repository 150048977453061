import React from 'react';
import Header from '../Header1';
import { NavLink } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <>
      <Header />
      <div className="h-screen flex flex-col items-center bg-gray-100">
        <div className="text-center mt-[19vh]">
          <h1 className="text-4xl font-semibold text-gray-800">
            404 - Page Not Found
          </h1>
          <p className="text-lg text-gray-600 mt-4">
            We're sorry, the page you're looking for doesn't exist.
          </p>
          <p className="text-lg text-gray-600 mt-1">
            Please check the URL or return to the home page.
          </p>
          <NavLink
            to="/"
            className="inline-block mt-6 px-6 py-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 hover:shadow-lg transition-all duration-300"
          >
            Go to Home Page
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
