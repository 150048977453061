import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { passwordStrength } from 'check-password-strength';
import { FaUsers, FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoInformationCircle } from 'react-icons/io5';
import ErrorPage from '../../pages/ErrorPage';

const NewUserSignup = () => {
  // State for form inputs
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (name === 'password') {
      const passwordStrengthResult = passwordStrength(value);
      // console.log(passwordStrengthResult);
      setScore((prevScore) => ({
        ...prevScore,
        value: passwordStrengthResult.value,
        length: passwordStrengthResult.length,
        contains: passwordStrengthResult.contains
        // corrected 'lentgh' to 'length'
      }));
    }
  };
  const [score, setScore] = useState({
    length: 0,
    value: '',
    contains: []
  });
  const errorRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    const uuid = window.location.pathname.split('/').pop(); // Extract the last part of the URL
    axios.get(`${process.env.REACT_APP_HOST_NAME}/api/subuser/${uuid}`)
      .then((response) => {
        // Handle the response here
        if (response?.data?.password_reset) {
          navigate('/login');
        }
        setFormData(prevState => ({
          ...prevState,
          email: response.data.email
        }));
        setErrorMessage(null)

      })
      .catch((error) => {
        // Display error only once
        // if (!errorRef.current) {
        //   toast.error(error?.response?.data || error?.message, {
        //     position: "top-center", 
        //     autoClose: 5000, 
        //     hideProgressBar: true, 
        //     closeButton: false, 
        //     className: "custom-toast" 
        //   });
        //   errorRef.current = true;  
        // }
        // navigate('/error');
        setErrorMessage(error?.response?.data || error?.message)
        // Handle any errors here
        console.error(error);
      });
  }, []);
  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation (you can expand this based on your needs)
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/subuser/password/set`, {
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,

    })
      .then((response) => {
        // console.log('Signup successful:', response.data);
        toast.success(response?.data)
        navigate('/login');
      })
      .catch((error) => {
        // console.error('Error during signup:', error);
        toast.error(error?.response?.data || error?.message)
      });

  };
  if (errorMessage) {
    return <ErrorPage />;
  }
  return (
    <div className="min-h-screen flex flex-col lg:flex-row justify-between gap-x-[6vw] items-center bg-blue-100 p-2" style={{ fontFamily: !'Helvetica Neue", Helvetica, Arial, sans-serif' }}>
      {/* Left Section - Form Container */}
      <div className="bg-[#1b2741] shadow-md flex flex-col justify-center h-[94vh] items-center lg:w-[43%] py-[2vh] ml-[2vw] rounded-lg">
        {/* Logo */}
        <div className="mb-[1vh] text-white text-[2.1em]">
          <FaUsers />
        </div>

        {/* Heading */}
        <h2 className="text-xl font-semibold mb-[2vh] text-center text-white">Complete Account Creation</h2>

        {/* Signup Form */}
        <form onSubmit={handleSubmit} className="py-[1vh] px-[1vw] rounded-lg shadow-md w-full max-w-[32vw]">
          {/* Email Input */}
          {errorMessage && <div className={`text-red-600 2xl:text-[1.2em] text-center`}>{errorMessage}</div>}

          <div className="mb-[3vh]">
            <label htmlFor="email" className="block text-white">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              maxLength="40"
              onChange={handleChange}
              required
              disabled
              className="w-full px-4 py-2 border rounded-lg bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your email"
            />
          </div>

          {/* Password Input with Eye Icon */}
          <div className="mb-[3vh] relative">
            <label htmlFor="password" className="block text-white">Enter Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              maxLength="16"
              value={formData.password}
              required
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none bg-gray-200 focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your password"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer top-[40%] transform-y-[-50%]"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          {/* Confirm Password Input with Eye Icon */}
          <div className="mb-[3vh] relative">
            <label htmlFor="confirmPassword" className="block text-white">Confirm Password</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              maxLength="16"
              value={formData.confirmPassword}
              required
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none bg-gray-200 focus:ring-2 focus:ring-blue-400"
              placeholder="Confirm your password"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 top-[40%] cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {formData?.password?.length > 0 && formData?.confirmPassword?.length > 0 && (
            formData.password === formData.confirmPassword ? (
              <div className='text-green-600 flex flex-col items-end'>
                <div>Passwords match</div>
              </div>
            ) : (
              <div className='text-red-600 flex justify-end'>
                Passwords do not match
              </div>
            )
          )}


          {/* Password Requirements */}
          <div className="text-white text-[0.8em]">
            <p>Password must contain:</p>
            <div className="list-inside grid grid-cols-2">
              <div className={`flex flex-row gap-x-1 items-center ${score.length >= 8 ? 'text-secondary' : 'text-gray-500'}`}>
                <FaCheck /> At least 8 characters
              </div>
              <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("lowercase") ? 'text-secondary' : 'text-gray-500'}`}>
                <FaCheck /> Lowercase
              </div>
              <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("uppercase") ? 'text-secondary' : 'text-gray-500'}`}>
                <FaCheck /> Uppercase
              </div>
              <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("number") ? 'text-secondary' : 'text-gray-500'}`}>
                <FaCheck /> Numbers
              </div>
              <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("symbol") ? 'text-secondary' : 'text-gray-500'}`}>
                <FaCheck /> Special Characters
              </div>
            </div>
          </div>

          {/* Privacy Policy */}
          <p className="text-[0.8em] text-white text-justify py-[1vh]">
            By clicking submit, you acknowledge that your data will be handled as described in our&nbsp;
            <a href='/privacy-policy' target='_blank' className='text-[#4fd0ff] hover:underline'>
              Privacy Policy
            </a>.
          </p>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Sign Up
          </button>
        </form>
      </div>

      {/* Right Section */}
      <div className="lg:flex flex-col justify-between gap-y-[1vh]  px-[3vw] lg:w-1/2 h-[54vh]">
        {/* Top Section - Logo and Name */}
        <div className="flex flex-col items-center justify-start pb-[5vh]">
          <NavLink to='/'>
            <img
              // src="/matiLogo.jpg"
              src='/matisoft-horizontal.svg'
              alt="Logo"
              className="h-[21vh] object-contain"
            />
          </NavLink>
        </div>

        {/* Middle Section - Main Content */}
        <div className="flex-grow flex flex-col justify-center">
          <p className="py-[1vh] pb-[5vh] text-[1.2em] 2xl:text-[1.3em] text-justify">
            A user has added you to Matisoft's cloud platform to manage their products. Please complete your account creation by setting a password.
          </p>

        </div>

        {/* Bottom Section - Information Message */}
        <div className="flex items-start py-[1vh] text-[1.1em] gap-x-1">
          <IoInformationCircle className='text-[1.1em]' />
          <p className='text-[0.9em] 2xl:text-[1em]'>

            This page will expire 2 days after receiving the email.
          </p>
        </div>
      </div>

    </div>

  );
}

export default NewUserSignup;
