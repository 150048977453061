import React, { startTransition, useEffect, useState, useRef } from 'react'
import Sidebar from '../../common/Sidebar'
// import Loader from '../common/Loader'
import Loader from '../../common/Loader'
import '../../css/index.css'

import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";

import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import Sidebar1 from '../../common/Sidebar1';
import SidebarAdmin from '../../common/SidebarAdmin';
import { Helmet } from 'react-helmet';

import { checkAuthentication } from '../../../Redux/auth/auth.action'

import { RiComputerLine } from 'react-icons/ri'

import '../../css/license.css'
import PageNumber from '../../components/pagination/PageNumber';
import DataEntriesNo from '../../components/pagination/DataEntriesNo';
import FilterSection from '../../common/FilterSection';
import { fetchLicenceHISTORY_USER,setSortOptions } from '../../../Redux/licence/licenseHistoryUser.action';
const LicenseHistoryCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [openShowHide, setOpenShowHide] = useState(false);

  const [activeArrow, setActiveArrow] = useState({
    columnName: null,
    direction: null
  });

  const { userId, licenseHistoryList, pageNumber, totalRecords, sortOrder, sortBy, filters, loading } = useSelector((state) => ({
    userId: state.companyid.userId,
    licenseHistoryList: state.licenseHistory.licenseHistoryList,
    pageNumber: state.licenseHistory.pageNumber,
    totalRecords: state.licenseHistory.totalRecords,
    sortOrder: state.licenseHistory.sortOrder,
    sortBy: state.licenseHistory.sortBy,
    filters: state.licenseHistory.filters,
    loading: state.licenseHistory.loading,
  }));
  let newSize;
  const fieldParam = new URLSearchParams(location.search).get('field');
  const operandParam = new URLSearchParams(location.search).get('operand');
  const valueParam = new URLSearchParams(location.search).get('value');
  const excludeParam = new URLSearchParams(location.search).get('exclude');
  const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
  const pageNumberParam = new URLSearchParams(location.search).get('page');

  // Assuming the values are stored as JSON strings in the URL, parse them
  const field = fieldParam ? JSON.parse(fieldParam) : [];
  const operand = operandParam ? JSON.parse(operandParam) : [];
  const value = valueParam ? JSON.parse(valueParam) : [];
  const exclude = excludeParam ? JSON.parse(excludeParam) : [];
  const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
  const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];

  const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
  const [currPage, setCurrPage] = useState(1);
  const [filterChange, setFilterChange] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const handleFilter = () => {
    setShowFilter(!showFilter);
  }
  let filtersvalue = React.useMemo(() => {
    return {
      filters: {
        field,
        operand,
        value,
        exclude,
      },
    };
  }, [field, operand, value, exclude]);
  const filtersValueRef = React.createRef(filtersvalue);
  const pageSizeRef = React.createRef(pageSize);
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);

  useEffect(() => {
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
    setPageSizeFromURL(parsedSize);
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
    setCurrPage(parsedPage);
  }, [location.search]);
  const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time

  // Function to get current time
  function getCurrentTime() {
    const now = new Date();
    return now.toLocaleString();
  }

  // Function to add leading zero if needed
  function addZeroIfNeeded(value) {
    return value < 10 ? '0' + value : value;
  }

  // Function to update current time
  function updateTime() {
    setCurrentTime(getCurrentTime());
  }


  useEffect(() => {
    const currentURL = window.location.pathname;

    const parts = currentURL.split('/');
    const fileid = null;
    const userid = parts[parts?.length - 1]
    // const companyID = parts[parts.length - 2]

    dispatch(setUserId(userid))
    dispatch(setFileId(fileid));
    const { id, key } = location.state || {};
    // console.log(id, key, "redirected Prop");
    if (userid) {
      dispatch(fetchLicenceHISTORY_USER(userid, "", pageSizeFromURL, currPage, sortBy, sortOrder, filtersvalue));
    }
    dispatch(checkAuthentication())

    setCurrentTime(getCurrentTime());
    const frontendVariable = "60c23a4b7bcabfd6b7663cb5";
  }, [dispatch, pageNumber, filtersValueRef.current, pageSizeFromURL, currPage, filterChange, currentTime]);

  const columnsLicense = [
    { name: "Updated date", value: "updated_date", type: 'date' },
    { name: "Previous credits", value: "before_total_credits", type: 'number' },

    { name: "Updated credits", value: "updated_total_credits", type: 'number' },
    { name: "Total credits", value: "total_credits", type: 'number' },

    { name: "Key", value: "key", type: 'string' },

    { name: "Daily rate per computer", value: "total_daily_rate", type: 'number' },

    { name: "License linked date (DD/MM/YY)", value: 'license_linked_date_time', type: 'date' },
    { name: "Due date (DD/MM/YY)", value: "license_due_date", type: 'date' },
  ]

  const [checkedRows, setCheckedRows] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columnsLicense);
  const [checkedColumns, setCheckedColumns] = useState(columnsLicense.map(() => true));
  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    const column = columnsLicense[index];
    // console.log(column, "column");

    setCheckedColumns(prevState => {
      const updatedCheckedColumns = [...prevState];
      updatedCheckedColumns[index] = checked;
      return updatedCheckedColumns;
    });
    setVisibleColumns(prevState => {
      const updatedVisibleColumns = [...prevState];
      // console.log(updatedVisibleColumns, "visibleColumn");
      if (checked) {

        if (!updatedVisibleColumns.find(col => col.id === column.id)) {
          updatedVisibleColumns.push(column);
          updatedVisibleColumns.sort((a, b) => a.id - b.id);
        }
      } else {

        const filteredColumns = updatedVisibleColumns.filter(col => col.id !== column.id);
        // console.log(filteredColumns, "filteredcol");
        return filteredColumns;
      }

      return updatedVisibleColumns;
    });
  };
  const selectAllColumns = () => {
    setCheckedColumns(columnsLicense.map(() => true)); // Mark all columns as checked
    setVisibleColumns(columnsLicense);
  };

  // Function to deselect all columns
  const deselectAllColumns = () => {
    setCheckedColumns(columnsLicense.map(() => false)); // Mark all columns as unchecked
    setVisibleColumns([]); // Hide all columns
  };

  const handleSort = (columnName, newSortOrder) => {
    
      console.log(columnName,newSortOrder,"sortOrders");
    setActiveArrow({
      columnName: columnName,
      direction: newSortOrder
    })

    // dispatch(setSortOptions(columnName, newSortOrder));
    dispatch(setSortOptions(columnName, newSortOrder))
  };

  // let sortedData;
  // if (licenseHistoryList !== undefined) {
  //   sortedData = licenseHistoryList?.sort((a, b) => {
  //     if (sortBy) {
  //       const getValue = obj => obj[sortBy] ?? '';

  //       if (sortOrder === 1) {
  //         if (typeof getValue(a) === "number" || typeof getValue(b) === "number") {
  //           console.log("numberOrderup");
  //           console.log(getValue(a), getValue(b), "valuesSort");

  //           return getValue(a) - getValue(b);
  //         }
  //         return getValue(a).localeCompare(getValue(b));
  //       } else {
  //         if (typeof getValue(a) === "number" || typeof getValue(b) === "number") {
  //           console.log("numberOrderDown");
  //           console.log(getValue(a), getValue(b), "valuesSort");

  //           return getValue(b) - getValue(a);
  //         }

  //         return getValue(b).localeCompare(getValue(a));

  //       }
  //     }
  //     return 0;
  //   });
  // }

  // let sortedData;
  // if (licenseHistoryList !== undefined) {
  //   sortedData = licenseHistoryList?.sort((a, b) => {
  //     if (sortBy) {
  //       // Define a function to handle nested properties
  //       const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj) ?? '';

  //       // Determine the property path based on sortBy
  //       let propertyPath;
  //       switch (sortBy) {
  //         case 'company_name':
  //           propertyPath = 'linked_user_id.company_name';
  //           break;
  //         case 'email':
  //           propertyPath = 'linked_user_id.email';
  //           break;
  //         case 'created_by':
  //           propertyPath = 'created_by.first_name'; // Sort by first name as an example
  //           break;
  //         default:
  //           propertyPath = sortBy;
  //       }

  //       const getValue = obj => getNestedValue(obj, propertyPath);

  //       if (sortOrder === 1) {
  //         if (typeof getValue(a) === "number" && typeof getValue(b) === "number") {
  //           // Number comparison for ascending order
  //           return getValue(a) - getValue(b);
  //         }
  //         // String comparison for ascending order
  //         return getValue(a).toString().localeCompare(getValue(b).toString());
  //       } else {
  //         if (typeof getValue(a) === "number" && typeof getValue(b) === "number") {
  //           // Number comparison for descending order
  //           return getValue(b) - getValue(a);
  //         }
  //         // String comparison for descending order
  //         return getValue(b).toString().localeCompare(getValue(a).toString());
  //       }
  //     }
  //     return 0;
  //   });
  // }
  let sortedData;
  if (licenseHistoryList !== undefined && Array.isArray(licenseHistoryList)) {
    sortedData = [...licenseHistoryList].sort((a, b) => {
      if (sortBy) {
        // Get the values to be compared
        const valueA = a[sortBy];
        const valueB = b[sortBy];
  
        // Determine if the value should be sorted as a number or a string
        const isNumber = typeof valueA === 'number' && typeof valueB === 'number';
        const isDate = (new Date(valueA).getTime() && new Date(valueB).getTime());
  
        if (sortOrder === 1) {
          console.log("111111111");
          
          // Ascending order
          if (isNumber) {
            return valueA - valueB;
          }
          if (isDate) {
            return new Date(valueA) - new Date(valueB);
          }
          return (valueA ?? '').toString().localeCompare((valueB ?? '').toString());
        } else {
          console.log("---111111111");

          // Descending order
          if (isNumber) {
            return valueB - valueA;
          }
          if (isDate) {
            return new Date(valueB) - new Date(valueA);
          }
          return (valueB ?? '').toString().localeCompare((valueA ?? '').toString());
        }
      }
      return 0;
    });
  }
  
  
  const [isSidebarExpanded, setSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded);

  };
  const handlePageSizeChange = (event) => {
    event.preventDefault();
    newSize = parseInt(event.target.value);
    // console.log(newSize, "newsize");
    // dispatch(setPageSize(newSize))
    const existingParams = new URLSearchParams(location.search);
    existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

    const queryString = existingParams.toString();

    navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

  };

  const [isPercentage, setIsPercentage] = useState(true); // State to track percentage option
  const [maxNegativeCredits, setMaxNegativeCredits] = useState(0); // State for max_negative_credits

  const handleOptionChange = (value) => {
    setIsPercentage(value === 'percentage');
    if (value === 'percentage') {
      const percentageValue = 0.0833 * licenceInfo.total_credits; // Calculate 8.33% of total_credits
      setMaxNegativeCredits(percentageValue.toFixed(2)); // Set to fixed 2 decimal places
    } else {
      setMaxNegativeCredits(licenceInfo.max_negative_credits); // Use current absolute value
    }
  };

  // useEffect(() => {
  //   // Set initial value based on default or current state
  //   if (isPercentage) {
  //     const percentageValue = 0.0833 * licenceInfo.total_credits; // Calculate 8.33% of total_credits
  //     setMaxNegativeCredits(percentageValue.toFixed(2)); // Set to fixed 2 decimal places
  //   } else {
  //     setMaxNegativeCredits(licenceInfo.max_negative_credits); // Use current absolute value
  //   }
  // }, [isPercentage, licenceInfo.total_credits, licenceInfo.max_negative_credits]);

  const [licenceId, setLicenceId] = useState('');
  const [addNew, setAddNew] = useState([]);
  const [currentKey, setCurrentKey] = useState('');


  const [editWindow, setEditWindow] = useState(false);

  const handlePageNumberChange = (newPageNumber) => {
    setCurrPage(newPageNumber);
    const existingParams = new URLSearchParams(location.search);
    existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number

    const queryString = existingParams.toString();

    navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

  };
  const handleClick = () => {

  };

  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showMoreStates, setShowMoreStates] = useState({});
  // Function to toggle show more/less state for 'details'
  const toggleShowMoreDetails = (rowIndex) => {

    const key = `${rowIndex}-details`;
    setShowMoreStates(prevState => ({
      ...prevState,
      [key]: !prevState[key] // Toggle the show more/less state for the specific row and 'details' value
    }));
  };

  const onApplyFilters = (filters) => {

    // console.log(filters, "filternewVLUE");
    setFilterChange(filters);

  }

  const buttonRef = useRef(null);

  if (loading) return <Loader />;
  return (
    <div className="flex h-screen">
      <Helmet>
        <title>Matisoft | License User History</title>
      </Helmet>
      <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full fixed `}>
        {/* Sidebar Content Goes Here */}
        <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
      </div>

      {/* Main Content */}
      <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'} z-40 relative overflow-x-auto 2xl:font-[30px] licenseCreation`}>
        <div className="fixed top-0 bottom-[76vh] w-full bg-white z-50">
          <div className={`flex flex-row justify-end items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'}`}>
            {/* <a href={`/cms/reports/threat/${companyId}`} className='text-[2em] text-blue-400'>
              <IoIosArrowDropleftCircle />
            </a> */}
            {/* <a href="/cms/dashboard" className='text-[2em] text-green-600'>
              <IoMdHome />
            </a> */}

          </div>
          <div className={`flex flex-row items-center justify-between pl-[5vw] mx-auto  `}>
            <div className='text-[1.2em] py-[2vh] font-[500]'>User License Transaction History</div>
          </div>
          <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row gap-x-[1%]'>

            <div className=''>
              <div className='cursor-pointer hover:text-blue-500' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
              {openShowHide && <>
                <div
                  className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 "

                ></div>
                <div className='showhide-section-container z-40'>
                  <div className="modal-body filter-section-content  ">
                    <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                      <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                    </button>
                    <div className="row">
                      <div className="col-sm-6">
                        {columnsLicense.map((item, index) => (
                          <label className="cont flex flex-row gap-x-[1%] text-white">
                            <input
                              type="checkbox"
                              className="toggle-vis"
                              data-column={index + 1}
                              checked={checkedColumns[index]}
                              defaultChecked
                              onChange={(event) => handleCheckboxChange(event, index)}
                            />
                            <span className="checkmark"></span> <p>{item.name}</p>
                          </label>
                        ))}
                      </div>
                      <div></div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                      <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                    </div>
                  </div>
                </div>
              </>}
            </div>
            <div className={` ${showFilter ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  `} onClick={handleFilter}></div>
            <div className='flex flex-row items-center hover:text-blue-500' onClick={handleFilter}>
              <span>Filters &nbsp;</span>
              <FaFilter className={` ${filtersvalue?.filters?.field?.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer`} />
              {filtersvalue?.filters?.field.length > 0 && <span>&nbsp;{filtersvalue?.filters?.field?.length} applied</span>}
            </div>
            {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsLicense} initialFilters={filtersvalue} onApplyFilters={onApplyFilters} />}
            <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCcw /></div>
            <div>{`Last Updated at : ${currentTime}`}</div>
          </div>
          <div className={`flex flex-row justify-between items-center py-[1vh] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'} border-b-1 border-b-gray-500`}>
            <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
            <PageNumber
              pageSize={pageSizeFromURL}
              currPage={currPage}
              totalRecords={totalRecords}
              handlePageNumberChange={handlePageNumberChange}
            />
          </div>
        </div>

        {/* Main Content Goes Here */}

        <div style={{ marginTop: '24vh', width: '100%', height: '76vh', overflowY: 'auto' }}> {/* Adjust margin top according to your requirement */}

          <table id='datatable' className=' relative w-[100%]'>
            <thead className=' sticky z-40 top-[0vh] '>
              <div className="  py-[1vh] bg-blue-200 flex flex-row  ">
                <tr className='w-[100%] flex flex-row '>

                  {visibleColumns.map((column, index) => (
                    <th key={index} className='pl-[1vw]'> {/* Add key prop */}
                      <div className='flex flex-row  items-center text-[0.8em] text-gray-800 w-[280px] '>
                        <p>
                          <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                            <FaLongArrowAltUp onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                            <FaLongArrowAltDown onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                          </span>
                        </p>
                        <p>{column.name}</p>
                      </div>
                    </th>
                  ))}
                  {/* <div className='w-[100px] flex flex-row'>
                    <span className='text-[0.8em] font-semibold'>Delete</span></div> */}
                </tr>
              </div>
            </thead>
            <tbody className=' z-0' style={{ maxHeight: '72vh', overflowY: 'auto' }}>
              {sortedData?.length > 0 ?
                <tr className='  w-[100%]'>
                  {sortedData && sortedData.map((item, indexVal) => (
                    <>
                      <div className={`min-w-[80vw] flex flex-row text-[0.8em] gap-x-[1vw] pl-[1vw] text-gray-800  py-[1vh] ${indexVal % 2 === 1 ? 'bg-white' : 'bg-gray-200'} break-words`}>

                        {visibleColumns.map((column, index) => (
                          <React.Fragment key={`${indexVal}-${column.value}`}>
                            {/* Check if column.value exists */}
                            <td className='col'>
                              {/* Render value based on column.value */}
                              {column.value === 'updated_date' && item?.updated_date
                                ? new Date(item.updated_date).toLocaleDateString()
                                : ''}

                              {column.value === 'before_total_credits' && item?.before_total_credits
                                ? item.before_total_credits
                                : ''}

                              {column.value === 'updated_total_credits' && item?.updated_total_credits
                                ? item.updated_total_credits
                                : ''}

                              {column.value === 'total_credits' && item?.total_credits
                                ? item.total_credits
                                : ''}

                              {column.value === 'key' && item?.key
                                ? item.key
                                : ''}

                              {column.value === 'total_daily_rate' && item?.total_daily_rate
                                ? item.total_daily_rate
                                : ''}

                              {column.value === 'license_linked_date_time' && item?.license_linked_date_time
                                ? new Date(item.license_linked_date_time).toLocaleString()
                                : ''}

                              {column.value === 'license_due_date' && item?.license_due_date
                                ? new Date(item.license_due_date).toLocaleString()
                                : ''}
                            </td>

                            {/* Render an empty cell if column.value doesn't exist */}
                            {!column.value && <td></td>}
                          </React.Fragment>
                        ))}

                      </div>
                      {/* </tr> */}
                    </>

                  ))
                  }

                </tr> : <tr className='text-[0.8em] text-gray-800 py-[2vh] absolute left-[38vw] '>No matching data available</tr>}
              <hr className='w-[100%] ' />
            </tbody>
          </table>

        </div>

      </div>

    </div>
  )
}

export default LicenseHistoryCustomer;