
import axios from "axios";
import { toast } from 'react-toastify';
import { ERROR_ACTION_INSTANCE, REQUEST_ACTION_INSTANCE, SET_ACTION_INSTANCE } from "./actionCenter.action_type";

let eventSource = null; // Define eventSource outside the action creator

export const setInstanceActionStatus = (orgId, userId, alertId, instanceId, actionStatus, updatedData, setUpdateData) => {

  try {
    // console.log(actionStatus,'actionStatus');
    console.log(alertId, "alert");
    return async (dispatch) => {
      //   dispatch({ type: REQUEST_ACTION_INSTANCE });

      try {

        const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/action/take/on/instance`, {
          "orgId": orgId,
          "userId": userId,
          "alertId": alertId,
          "actionStatus": actionStatus,
          "instanceId": instanceId,

          //   "comment": comment
        });

       
        toast.success(response.data.message);
        // dispatch({ type: SET_PAGINATION_OPTIONS, payload: { pageNo, pageSize } });
        setUpdateData(!updatedData);
        console.log('Data from backend FOR AUTHENTICATE:', response.data);
        // Handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error(error?.error || error?.response?.data || error?.message);
    
        // dispatch({ type: ERROR_ACTION_INSTANCE, payload: error })
        // Handle errors
      }
    };

  } catch (error) {
    console.log(error, 'edrfdfdsfdsd');

  }

};

