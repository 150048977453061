import React from 'react';
import { styles } from '../styles';
import { BiSolidRightArrow, BiSolidShieldAlt2 } from 'react-icons/bi';

const DlpHero2 = () => {
    return (
        <div className='h-full  md:h-screen relative w-full mx-auto heroDlp'>
              {/* Streaming Text Line */}
           
            <div className='overlay-layer'></div>
            {/* <div className='streaming-text fixed z-[60]'>

            🚀 Matisoft DLP ensures DPDP compliance by identifying, classifying, and safeguarding sensitive data with enforceable policies. 🚀</div> */}
            <div className='text-layer absolute left-[10%] top-[10%] text-white w-[80%] margin-auto'>
                {/* Grid Container for Left and Right Sections */}
                <div className='grid grid-cols-1  md:grid-cols-[65%,35%] gap-x-[2vw] gap-y-[2vh]'>
                    {/* Left Section (65%) */}
                    <div>
                        <h2 className='text-[1.6em] sm:text-[1.9em] 2xl:text-[2.3em] font-[600]'>
                            Matisoft DLP prevents data leaks via:
                        </h2>
                        <ul className='text-[1.3em] sm:text-[1.5em] 2xl:text-[1.8em] pt-[2vh] flex flex-col gap-y-[1.4vh]'>
                            <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                                <span>Emails and Attachments</span>
                            </li>
                            <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                                <span>Cloud uploads</span>
                            </li>
                            <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                                <span>Website blocking</span>
                            </li>
                            <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                                <span>Application blocking</span>
                            </li>
                            <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                                <span>Pendrives, mobile phones, Bluetooth, and other devices.</span>
                            </li>
                            <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                                <span>
                                    Proprietary USB firewall ensures 100% secure data transfer without data leaks or viruses.
                                </span>
                            </li>
                            <li>
                                <a href="/contact" className="btn btn-default text-white font-[500] uppercase">
                                    Book your demo
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Right Section (35%) */}
                    <div className='p-[2vh] bg-transparent rounded-lg h-fit text-secondary border-2 border-blue-300 shadow-lg hover:shadow-xl transition-shadow duration-300 relative overflow-hidden'>
                        {/* Folded Corner */}
                        <div className='absolute top-0 right-0 w-0 h-0 border-l-[50px] border-l-transparent border-b-[50px] border-b-blue-300'></div>

                        {/* Icon for Visual Appeal */}
                        <div className='flex items-center gap-x-[1vw] mb-[1vh]'>
                            <h3 className='text-white  z-[20]  text-[1.2em] sm:text-[1.3em] 2xl:text-[1.5em] font-[600]'>
                                How does Matisoft DLP ensure DPDP compliance?
                            </h3>
                        </div>
                        <p className='text-[1em] sm:text-[1.1em] 2xl:text-[1.3em]'>
                            <span className='highlight-text'>
                                Matisoft DLP ensures DPDP compliance by discovering, classifying, monitoring, and protecting sensitive data with enforceable policies.
                            </span>
                        </p>
                    </div>    </div>
            </div>
        </div>
    );
};

export default DlpHero2;