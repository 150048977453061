import React, { useEffect, useState } from 'react';
import { ImCross } from 'react-icons/im';
import { calculateExpiryDays, calculateAddedCredits } from '../../utils/ExpiryDays';

const ChangeLicenseCredit = ({ changeCredit, setChangeCredit, totalCredits, product, productName, onConfirmChange, lastCroneDateTime, nextRunAt }) => {
    const [changedNumbers, setChangedNumbers] = useState({
        addedCredit: 0,
        finalAddedCredit: 0,
        subtractedCredit: 0,
        finalCredits: totalCredits,
        finalSubtractedCredits: 0
    });

    const [initialExpiryDays, setInitialExpiryDays] = useState('NA');
    const [updatedExpiryDays, setUpdatedExpiryDays] = useState('NA');
    const [changeExpiryDays, setChangeExpiryDays] = useState({
        addCredits: 0,
        newDays: 0
    });
    const [changeDailyRate, setChangeDailyRate] = useState({
        initialDailyRate: 0,
        newRate: 0,
        newExpiry: 'NA',
    });

    useEffect(() => {
        // console.log(totalCredits, product, "totalCedits & product");

        if (Array.isArray(product) && product.length > 0) {
            const initialDays = calculateExpiryDays({ totalCredit: totalCredits, product, lastCroneDateTime, nextRunAt });
            // console.log(initialDays, "initialDays");

            setInitialExpiryDays(initialDays)
            // console.log(initialExpiryDays, "initial expiry days");

        } else {
            setInitialExpiryDays('NA'); // Handle case where product is empty or not an array
        }
        // console.log(initialExpiryDays, "initial expiry days");

    }, [changedNumbers, totalCredits, product, initialExpiryDays, updatedExpiryDays]);

    useEffect(() => {
        if (Array.isArray(product) && product.length > 0) {
            const updatedDays = calculateExpiryDays({ totalCredit: changedNumbers.finalCredits, product, lastCroneDateTime, nextRunAt });
            setUpdatedExpiryDays(updatedDays);
        } else {
            setUpdatedExpiryDays('NA'); // Handle case where product is empty or not an array
        }
    }, [changedNumbers.finalCredits, product]);
    // console.log(initialExpiryDays, "initial expiry days");

    useEffect(() => {
        let foundProduct = null;

        if (Array.isArray(product) && product.length > 0) {
            foundProduct = product.find(item => item.name === productName);
        }

        if (foundProduct) {
            setChangeDailyRate((prevState) => ({
                ...prevState,
                initialDailyRate: foundProduct.daily_rate,
                newRate: foundProduct.daily_rate
            }));
        } else {
            setChangeDailyRate((prevState) => ({
                ...prevState,
                initialDailyRate: 'NA'
            }));
        }

        const updatedDays = foundProduct
            ? calculateExpiryDays({ totalCredit: totalCredits, product, lastCroneDateTime, nextRunAt })
            : 'NA';
        // console.log(updatedDays, "updatedDays");
        setInitialExpiryDays(updatedDays);
        setChangeDailyRate((prev) => ({
            ...prev,
            newExpiry: updatedDays
        }))
        setChangeCredit((prevState) => ({
            ...prevState,
            newExpiry: updatedDays
        }));
    }, [product, productName, totalCredits, setChangeCredit]);

    const handleInputChange = (e, type) => {
        const value = Math.abs(parseFloat(e.target.value, 10) || 0); // Convert to integer or default to 0
        if (type === 'add') {
            setChangedNumbers((prevState) => ({
                ...prevState,
                addedCredit: value,
                finalAddedCredit: prevState.finalAddedCredit + value,
                finalCredits: prevState.finalCredits + (value - prevState.addedCredit),
            }));
        } else if (type === 'subtract') {
            // Ensure final credits do not go below zero or the totalCredits value
            const newFinalCredits = Math.max(0, changedNumbers.finalCredits - (value - changedNumbers.subtractedCredit));
            setChangedNumbers((prevState) => ({
                ...prevState,
                subtractedCredit: value,
                finalCredits: newFinalCredits,
                finalSubtractedCredits: value + prevState.subtractedCredit
            }));
        } else if (type === 'addDays') {
            setChangeExpiryDays((prevState) => ({
                ...prevState,
                newDays: value,
            }));

            const addedCredits = calculateAddedCredits(
                { desiredExtraDays: value, totalCredits, product, prevExpiryDays: initialExpiryDays });
            setChangeExpiryDays((prevState) => ({
                ...prevState,
                addCredits: addedCredits,
            }));
        } else if (type === 'dailyRate') {
            setChangeDailyRate((prevState) => ({
                ...prevState,
                newRate: value,
            }));

            // Create a new product array with the updated daily rate for the specific product
            const changedProduct = product.map(item =>
                item.name === productName ? { ...item, daily_rate: value } : item
            );

            // Debugging

            // Calculate the new expiry days with the updated product array
            const newExpiry = calculateExpiryDays({ totalCredit: totalCredits, product: changedProduct, lastCroneDateTime: lastCroneDateTime, nextRunAt });


            // Update the state with the new expiry days and the updated product array
            setChangeDailyRate((prevState) => ({
                ...prevState,
                newExpiry: newExpiry,
                changedProduct: changedProduct
            }));
        }
    };
    const handleConfirm = (type) => {
        if (changeCredit.type === 'dailyRate') {
            // Use the current product array if no changes were made
            const productToUse = changeDailyRate.changedProduct || product;
            onConfirmChange(totalCredits, changedNumbers.addedCredit, changedNumbers.subtractedCredit, productToUse, type);
        } else if (changeCredit.type === 'addDays') {
            onConfirmChange(changeExpiryDays.addCredits, changedNumbers.addedCredit, changedNumbers.subtractedCredit, product, type);
        } else if (changeCredit.type === 'add' || changeCredit.type === 'subtract') {
            onConfirmChange(changedNumbers.finalCredits, changedNumbers.addedCredit, changedNumbers.subtractedCredit, product, type);
        }
        setChangeCredit({ ...changeCredit, window: false });
    };
    const handleCrossButtonClick = () => {
        setChangeCredit({ ...changeCredit, window: false });
    };

    return (
        <div className='fixed bg-white  opacity-[0.97] border-blue-500 border-[1px] rounded w-[50vw] left-[25vw] z-[100] top-[8vh] bottom-[30vh]'>
            <div className='relative'>
                <button
                    className='absolute right-[-5px] top-[-5px]'
                    onClick={handleCrossButtonClick}
                >
                    <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full cursor-pointer' />
                </button>
            </div>
            <div className='p-4'>
                <h1 className='text-center text-lg font-[500] mb-2 '>
                    {changeCredit.type === 'add' ? 'Add Credits' : changeCredit.type === 'subtract' ? 'Subtract Credits' : changeCredit.type === 'addDays' ? 'Add days' : `Change Daily Rate (${productName})`}
                </h1>
                <hr />
                {changeCredit.type === 'add' && (
                    <div className='text-[1.2em] pt-[2vh] flex flex-col gap-y-2'>
                        <div className='flex flex-row items-center text-[1.1em]'>
                            <div className='w-[12vw]'>Previous Credits:</div>
                            <div>{totalCredits}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw] '>New Credits:</div>
                            <div className='text-blue-700'>{changedNumbers.finalCredits}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>Previous Expiry:</div>
                            <div>
                                {initialExpiryDays && initialExpiryDays > 0 ? (
                                    <>
                                        {initialExpiryDays} <span className="text-[0.8em] text-gray-600">days</span>
                                    </>
                                ) : (
                                    initialExpiryDays // Or handle invalid numbers here if needed
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>New Expiry:</div>
                            <div>
                                {updatedExpiryDays && updatedExpiryDays > 0 ? (
                                    <>
                                        {updatedExpiryDays} <span className="text-[0.8em] text-gray-600">days</span>
                                    </>
                                ) : (
                                    updatedExpiryDays // Or handle invalid numbers here if needed
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-center  text-[1.1em]'>
                            <div className='w-[12vw]'>Add Credits:</div>
                            <input
                                type='number'
                                value={changedNumbers.addedCredit > 0 ? changedNumbers.addedCredit : ''}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    if (value <= 10000000000000) {
                                        handleInputChange(e, 'add');
                                    }
                                }} className=' px-[0.5vw] py-[0.5vh] w-[15vw] border border-1 border-blue-400'
                                placeholder='Add Credits'
                                max='10000000000000'
                                min='0'
                                onKeyDown={(e) => {
                                    if (e.key === '+' || e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>

                        <div className='flex flex-row justify-end mt-[2vh] mr-[1vw]'>
                            <button
                                type='button'
                                className='bg-green-600 text-[1.1em] font-[500] text-white px-[0.6vw] py-[0.5vh]
                                 hover:bg-green-700 w-fit mr-2' onClick={() => handleConfirm('add')}
                            >
                                Confirm
                            </button>
                            <button
                                type='button'
                                className='bg-green-600 text-[1.1em] font-semibold text-white 
                                px-[0.6vw] py-[0.5vh] hover:bg-green-700 w-fit'  onClick={handleCrossButtonClick}
                            >
                                Cancel</button>
                        </div>
                    </div>
                )}
                {changeCredit.type === 'subtract' && (
                    <div className='text-[1.2em] pt-[2vh] flex flex-col gap-y-2'>
                        <div className='flex flex-row items-center text-[1.1em]'>
                            <div className='w-[12vw]'>Previous Credits:</div>
                            <div>{totalCredits}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>New Credits:</div>
                            <div className='text-blue-700'>{changedNumbers.finalCredits}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>Previous Expiry:</div>
                            <div>
                                {initialExpiryDays && initialExpiryDays > 0 ? (
                                    <>
                                        {initialExpiryDays} <span className="text-[0.8em] text-gray-600">days</span>
                                    </>
                                ) : (
                                    initialExpiryDays // Or handle invalid numbers here if needed
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>New Expiry:</div>
                            <div className='text-blue-700'> {updatedExpiryDays && updatedExpiryDays > 0 ? (
                                <>
                                    {updatedExpiryDays} <span className="text-[0.8em] text-gray-600">days</span>
                                </>
                            ) : (
                                updatedExpiryDays // Or handle invalid numbers here if needed
                            )}</div>
                        </div>
                        <div className='flex flex-row items-center  text-[1.1em]'>
                            <div className='w-[12vw]'>Subtract Credits:</div>
                            <input
                                type='number'
                                value={changedNumbers.subtractedCredit > 0 ? changedNumbers.subtractedCredit : ''}
                                placeholder='Subtract Credits'
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    if (value <= totalCredits) { // Check if the typed value is within the totalCredits range
                                        handleInputChange(e, 'subtract');
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === '+' || e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                                min='1'
                                className='px-[0.5vw] py-[0.5vh] w-[12vw] border border-1 border-blue-400'
                                max={totalCredits} // Ensure max value is current totalCredits
                            />
                        </div>
                        <p className='ml-[12vw] text-[0.9em]'>(Subtracted credits should not be more than the total credits)</p>


                        <div className='flex flex-row justify-end mt-[2vh] mr-[1vw]'>                            <button
                            type='button'
                            className='bg-green-600 font-semibold text-[1.1em] text-white px-[0.6vw] py-[0.5vh]
                                 hover:bg-green-800 w-fit mr-2' onClick={() => handleConfirm('subtract')}
                        >
                            Confirm
                        </button>
                            <button
                                type='button'
                                className='bg-green-600 font-semibold text-[1.1em] text-white 
                                px-[0.6vw] py-[0.5vh] hover:bg-green-800 w-fit'  onClick={handleCrossButtonClick}
                            >
                                Cancel</button>
                        </div>
                    </div>
                )}

                {
                    changeCredit.type === 'addDays' &&
                    <div className='text-[1.2em] pt-[2vh] flex flex-col gap-y-2'>
                        <div className='flex flex-row items-center text-[1.1em]'>
                            <div className='w-[12vw]'>Previous Credits:</div>
                            <div>{totalCredits}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]'>
                            <div className='w-[12vw]'>Need to add credits:</div>
                            <div className='text-blue-700'>{changeExpiryDays.addCredits}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>Previous Expiry:</div>
                            <div>
                                {initialExpiryDays && initialExpiryDays > 0 ? (
                                    <>
                                        {initialExpiryDays} <span className="text-[0.8em] text-gray-600">days</span>
                                    </>
                                ) : (
                                    initialExpiryDays // Or handle invalid numbers here if needed
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>New Expiry:</div>
                            <div className="text-blue-700">
                                {
                                    !isNaN(Number(changeExpiryDays.newDays)) &&
                                        !isNaN(Number(initialExpiryDays)) ? (
                                        <>
                                            {Number(changeExpiryDays.newDays) + Number(initialExpiryDays)}
                                            <span className="text-[0.8em] text-gray-600"> days</span>
                                        </>
                                    ) : (
                                        "NA"
                                    )
                                }
                            </div>

                        </div>
                        <div className='flex flex-row items-center  text-[1.1em]'>
                            <div className='w-[12vw]'>Add Days:</div>
                            <input
                                type="number"
                                value={changeExpiryDays.newDays > 0 ? changeExpiryDays.newDays : ''}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10) || 0;
                                    if (value <= 3650) {
                                        handleInputChange(e, 'addDays');
                                    }
                                }}
                                className="px-[0.5vw] py-[0.5vh] w-[15vw] border border-1 border-blue-400"
                                placeholder="Add Days"
                                min="0"
                                max="3650"
                                step="1"
                                onKeyDown={(e) => {
                                    if (e.key === '+' || e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                            />

                        </div>


                        <div className='flex flex-row justify-end mt-[2vh] mr-[1vw]'>                            <button
                            type='button'
                            className='bg-green-600 font-semibold text-[1.1em] text-white px-[0.6vw] py-[0.5vh]
                                 hover:bg-green-800 w-fit mr-2' onClick={() => handleConfirm('addDays')}
                        >
                            Confirm
                        </button>
                            <button
                                type='button'
                                className='bg-green-600 font-semibold text-[1.1em] text-white 
                                px-[0.6vw] py-[0.5vh] hover:bg-green-800 w-fit'  onClick={handleCrossButtonClick}
                            >
                                Cancel</button>
                        </div>
                    </div>
                }

                {
                    changeCredit.type === 'dailyRate' &&
                    <div className='text-[1.2em] pt-[2vh] flex flex-col gap-y-2'>
                        <div className='flex flex-row items-center text-[1.1em]'>
                            <div className='w-[12vw]'>Previous Daily Rate:</div>
                            <div>{changeDailyRate.initialDailyRate}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>New Daily Rate:</div>
                            <div className='text-blue-700'>{changeDailyRate.newRate}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>Previous Expiry:</div>
                            <div> {initialExpiryDays && initialExpiryDays > 0 ? (
                                <>
                                    {initialExpiryDays} <span className="text-[0.8em] text-gray-600">days</span>
                                </>
                            ) : (
                                initialExpiryDays // Or handle invalid numbers here if needed
                            )}</div>
                        </div>
                        <div className='flex flex-row items-center text-[1.1em]' >
                            <div className='w-[12vw]'>New Expiry:</div>
                            <div className='text-blue-700'>{changeDailyRate.newExpiry && changeDailyRate.newExpiry > 0 ? (
                                <>
                                    {changeDailyRate.newExpiry} <span className="text-[0.8em] text-gray-600">days</span>
                                </>
                            ) : (
                                changeDailyRate.newExpiry // Or handle invalid numbers here if needed
                            )}</div>
                        </div>
                        <div className='flex flex-row items-center  text-[1.1em]'>
                            <div className='w-[12vw]'>New Daily Rate:</div>
                            <input
                                type="number"
                                value={changeDailyRate.newRate > 0 ? changeDailyRate.newRate : ''}
                                onChange={(e) => handleInputChange(e, 'dailyRate')}
                                className="px-[0.5vw] py-[0.5vh] w-[15vw] border border-1 border-blue-400"
                                placeholder="New Daily Rate"
                                max="100000"
                                onKeyDown={(e) => {
                                    // Prevent invalid characters
                                    if (['+', '-', 'e'].includes(e.key)) {
                                        e.preventDefault();
                                    }

                                    // Allow backspace, delete, arrow keys, and tab
                                    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                    if (allowedKeys.includes(e.key)) {
                                        return; // Allow these keys
                                    }

                                    // Prevent typing more than 3 decimal places
                                    const currentValue = e.target.value;
                                    const decimalIndex = currentValue.indexOf('.');
                                    if (decimalIndex !== -1 && currentValue.length - decimalIndex > 3) {
                                        e.preventDefault();
                                    }
                                }}
                                step="0.001" // Allow decimals up to 3 places
                                min="0"
                            />

                        </div>

                        <div className='flex flex-row justify-end mt-[2vh] mr-[1vw]'>                            <button
                            type='button'
                            className='bg-green-600 font-semibold text-[1.1em] text-white px-[0.6vw] py-[0.5vh]
                                 hover:bg-green-800 w-fit mr-2' onClick={() => handleConfirm('dailyRate')}
                        >
                            Confirm
                        </button>
                            <button
                                type='button'
                                className='bg-green-600 font-semibold text-[1.1em] text-white 
                                px-[0.6vw] py-[0.5vh] hover:bg-green-800 w-fit'  onClick={handleCrossButtonClick}
                            >
                                Cancel</button>
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default ChangeLicenseCredit;
