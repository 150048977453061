import React, { useEffect, useState } from 'react';
import { setCompanyId, setOrgId, setUserId } from '../../Redux/companyid/companyid.action';
import { useDispatch, useSelector } from 'react-redux';
// import Sidebar from '../common/Sidebar';
import { RiFocus2Fill } from "react-icons/ri";
import { ImCross, ImWindows8 } from "react-icons/im";
import axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { RiArrowRightSFill } from "react-icons/ri";
// import Sidebar3 from '../common/Sidebar3';
import SidebarInstaller from '../common/SidebarInstaller';
import { Helmet } from 'react-helmet';
// import { Helmet } from 'react-helmet';
import { FaChevronDown, FaChevronUp, FaCircleExclamation } from "react-icons/fa6";
import { TbExclamationCircle } from "react-icons/tb";
import Error from '../../pages/Error';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";

export const Installer1 = () => {
    const dispatch = useDispatch();
    const { companyId, userId, userAuthData, permissions, products, adminAuthData, companyAuthenticate, errorAdminAuth, errorUserAuth } = useSelector((state) => ({
        companyId: state.companyid.companyId,
        userId: state.companyid.userId,
        userAuthData: state.userAuth.userAuthData,
        permissions: state.companyid.permissions,
        products: state.companyid.products,
        adminAuthData: state.adminAuth.adminAuthData,
        companyAuthenticate: state.companyReducer.companyAuthenticate,
        errorAdminAuth: state.adminAuth.errorAdminAuth,
        errorUserAuth: state.userAuth.errorUserAuth

    }));
    const [fileVersions, setFileVersions] = useState({
        amsFileVersion: '',
        dlpFileVersion: '',
        amsFileName: '',
        dlpFileName: ''
    });
    const [showContact, setShowContact] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const currentURL = window.location.pathname;
        const parts = currentURL.split('?')[0].split('/');
        const userid = parts[parts.length - 1];
        // const companyID = parts[parts.length - 3];
        const companyID = parts[parts.length - 2]
        dispatch(setCompanyId(companyID));
        dispatch(setUserId(userid));
        // dispatch(setOrgId(organisationId));
    }, [dispatch]);

    useEffect(() => {
        const fetchFileVersions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_NAME}/api/company/installers/files/versions`);
                const Versions = response?.data?.data;

                setFileVersions({
                    amsFileVersion: Versions.amsFileVersion || '',
                    dlpFileVersion: Versions.dlpFileVersion || '',
                    amsFileName: Versions.amsFileName || '',
                    dlpFileName: Versions.dlpFileName || ''
                });

            } catch (error) {
                // console.error('Error fetching file versions:', error);
            }
        };

        fetchFileVersions();
    }, []);  // Empty dependency array ensures this runs only once on mount
    const [downloadingButton, setDownloadingButton] = useState(null);
    const [isSidebarExpanded, setSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);

    };
    const [isExpanded, setIsExpanded] = useState({
        "edr": false,
        "dlpConsole": false,
        "dlpAgent": false
    });

    const toggleDetails = (section) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [section]: !prevState[section], // Use bracket notation to dynamically access the key
        }));
    };


    const handleInstaller = async (installer, filename, buttonId) => {
        if (downloadingButton === buttonId) return; // Prevent multiple clicks on the same button
        localStorage.setItem(installer + userId, "pending");
        setDownloadingButton(buttonId);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_HOST_NAME}/api/company/get-Installer`,
                {
                    companyId: companyId,
                    userId: userId,
                    installer_name: installer,
                },
                {
                    responseType: 'blob', // Expecting binary data
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;

            let fileName = 'test.exe';
            link.setAttribute('download', filename);

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();
            console.log(filename);

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            console.error('Error:', error);

            if (error.response) {
                const blob = error.response.data; // Blob data from the response
                const contentType = error.response.headers['content-type'];

                try {
                    if (contentType && contentType.includes('application/json')) {
                        // Parse the Blob as JSON
                        const text = await blob.text().then(JSON.parse); // Convert Blob to text

                        // Remove surrounding quotes (if present)
                        setErrorMessage(text)
                        setShowContact(true);
                    } else {
                        // If not JSON, treat as plain text
                        const errorText = await blob.text();
                        console.log(errorText);

                        toast.error(errorText || 'An unexpected error occurred.');
                    }
                } catch (blobError) {
                    console.error('Failed to parse error Blob:', blobError);
                    toast.error('An unexpected error occurred while processing the error response.');
                }
            } else {
                // Handle non-API errors (e.g., network issues)
                toast.error(error.message || 'An unexpected error occurred.');
            }
        }

        finally {
            setDownloadingButton(null);
            localStorage.removeItem(installer + userId);
        }
    };

    if (errorUserAuth && errorAdminAuth) {
        return <Error />;
    }
    return (
        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | Download Installer</title>
            </Helmet>
            {/* Sidebar */}
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/14'} bg-gray-200 z-[70] h-full fixed `}>
                {/* Sidebar Content Goes Here */}
                <SidebarInstaller isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            </div>

            <div className={`"flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[6%]'} z-20 relative overflow-auto`}>
                <div className='px-[1vw]'>
                    <h1 className='py-[2vh] text-[1.3em] pl-[2vw] pt-[3vh] text-primary font-[500]'> Download installer
                        {/* for <span className='text-blue-700'>{companyAuthenticate?.name}</span> */}
                    </h1>
                    {showContact && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                            <div className="bg-white border-white border-1 rounded-lg shadow-lg w-[42vw] max-h-[95vh] p-4 overflow-y-auto">
                                <div className="relative py-[1vh]">
                                    <button className="absolute right-[-10px] top-[-10px]" onClick={() => setShowContact(false)}>
                                        <ImCross className="text-white bg-red-600 p-1 text-xl font-[200] rounded-full" />
                                    </button>
                                    <div className='flex flex-row gap-x-2  py-[2vh]'>
                                        <TbExclamationCircle className='text-[1.3em] flex-shrink-0' />
                                        <div>
                                            <p className="text-[1.2em] font-[500] text-justify mb-[4vh]">
                                                {errorMessage.code === 1 ? (
                                                    <>
                                                        You do not have an active license to access this product. Kindly <a href="/contact" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 underline">Contact us</a> to request a license. If you already have a license key, please visit the <a href={`/cms/licenseinfo/${userId}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline hover:text-blue-700">License Information</a> to link your key.
                                                    </>
                                                ) : errorMessage.code === 2 ? (
                                                    <>
                                                        Your license has expired. To renew it, kindly <a href="/contact" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline hover:text-blue-700">Contact us</a>.
                                                    </>
                                                ) : (
                                                    <>
                                                        This product is not included in your current license. If you wish to include it, kindly <a href="/contact" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline hover:text-blue-700">contact us</a>.
                                                    </>
                                                )}
                                            </p>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`border-[1px] border-gray-300 mt-[3vh] ${isSidebarExpanded ? '' : 'w-[90vw]'}`}>
                        <div className='flex flex-row px-[1vw] py-[2vh]'>
                            <span>
                                <ImWindows8 className='text-secondary text-[1.5em] mr-[1vw]' />
                            </span>

                            <div className='flex flex-row items-start justify-between text-gray-700 text-[0.9em] gap-x-4'>
                                <div className='w-[60vw] text-gray-800 '>
                                    <h1 className='text-[1.3em] mb-[1vh]'>
                                        Matisoft Advanced Malware Scanner(EDR) for Windows
                                    </h1>
                                    <span className='text-gray-500'>
                                        <span className='text-gray-600'>Minimum OS Requirements:</span> Windows 7 SP1 or later, Windows Server 2008 R2 SP1 or later
                                    </span>

                                    <div className='text-gray-500 py-[1vh]'>
                                        <div
                                            className='flex items-center cursor-pointer'
                                            onClick={() => toggleDetails("edr")}
                                        >
                                            <span className='text-gray-600 mr-2'>Additional Requirements:</span>
                                            {isExpanded.edr === true ? <FaChevronUp /> : <FaChevronDown />}
                                        </div>
                                        {isExpanded.edr === true && (
                                            <div className='mt-2'>
                                                <a
                                                    className='underline hover:text-blue-600'
                                                    href='https://dotnet.microsoft.com/en-us/download/dotnet-framework'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    .Net Framework 4.5.2 or later*
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    {/* <br /> <span className='text-gray-500'>  Installer version - {fileVersions.amsFileVersion}</span> */}
                                </div>
                                <div className='flex flex-col items-center justify-center '>    <button
                                    onClick={() => {
                                        if (
                                            permissions.some(
                                                (permission) =>
                                                    permission.pagePath === '/cms/installer/' &&
                                                    permission.permissionLevel === 'read_write'
                                            )
                                        ) {
                                            handleInstaller("AMS", fileVersions.amsFileName, 1);
                                        }
                                    }} title={!permissions.some(permission => permission.pagePath === '/cms/installer/' && permission.permissionLevel === 'read_write') && "You don't have permissions"}
                                    className={`  ${!permissions.some(
                                        (permission) =>
                                            permission.pagePath === '/cms/installer/' &&
                                            permission.permissionLevel === 'read_write'
                                    )
                                        ? 'opacity-50 cursor-not-allowed'
                                        : 'cursor-pointer'} text-white bg-blue-600 font-[500] shadow-md border-[1px] border-blue-600 py-[1vh] px-[0.5vw] button-no-padding hover:text-blue-800 hover:bg-white hover:border-[1px] `}
                                >
                                    {localStorage.getItem(`AMS${userId}`) === "pending" ? 'Fetching Installer...' : 'Download Installer'}
                                </button>
                                    <span className='text-gray-500 text-center'>  Installer version - {fileVersions.amsFileVersion}</span>
                                </div>
                            </div>
                        </div>
                        <div className='pl-[3vw]  text-[0.9em] text-gray-700 py-[2.3vh] bg-gray-100'>
                            This is an online installer and requires active internet connection while installing
                        </div>
                    </div>


                    <div className='border-[1px] border-gray-300 mt-[3vh]'>
                        <div className='flex flex-row px-[1vw] py-[2vh]'>
                            <ImWindows8 className='text-secondary text-[1.5em] mr-[1vw]' />

                            <div className='flex flex-row justify-between items-start text-gray-700 text-[0.9em] gap-x-4'>
                                <div className={` w-[60vw]`}>
                                    <h1 className='text-gray-800 text-[1.3em] mb-[1vh]'>
                                        Matisoft Data Leak Prevention(DLP) Management Console for Windows
                                    </h1>
                                    <div className=' flex flex-row gap-x-[1vw] py-[1vh]'>
                                        <div className='px-[1vw] text-justify py-[1vh] bg-blue-100 border border-1 border-blue-200 rounded-sm'>
                                            <h1 className='text-[1.2em] font-semibold pb-[1vh]  text-blue-900'>Management Console</h1>
                                            <span className='text-gray-500'>
                                                <span className='text-gray-600'>Minimum OS Requirements:</span>  Windows 10 1607 or later, Windows Server 2012 or later
                                            </span>
                                            <div className='text-gray-500 py-[1vh]'>
                                                <div
                                                    className='flex items-center cursor-pointer'
                                                    onClick={() => toggleDetails("dlpConsole")}
                                                >
                                                    <span className='text-gray-600 mr-2'>Additional Requirements:</span>
                                                    {isExpanded.dlpConsole === true ? <FaChevronUp /> : <FaChevronDown />}
                                                </div>
                                                {isExpanded.dlpConsole === true && (
                                                    <div className='mt-2'>
                                                        <a className='underline hover:text-blue-600' href='https://www.mongodb.com/try/download/community' target='_blank'>MongoDB Server*</a>,&nbsp;
                                                        {/* <a target='_blank' className='underline hover:text-blue-700' href='https://dotnet.microsoft.com/en-us/download/dotnet/6.0'>.NET Runtime 6 or later*</a>,  */}
                                                        <a className='underline hover:text-blue-700' href='https://dotnet.microsoft.com/en-us/download/dotnet/6.0' target='_blank'>ASP.NET Core Runtime 6 or later*</a>, <a className='underline hover:text-blue-700' href='https://dotnet.microsoft.com/en-us/download/dotnet/6.0' target='_blank'>.NET Desktop Runtime 6 or later*</a>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <div className='px-[1vw] py-[1vh] bg-blue-100 border border-1 border-blue-200 rounded-sm'>
                                            <h1 className='text-[1.2em] font-semibold pb-[1vh]  text-blue-900'>Endpoint Agent</h1>
                                            <span className='text-gray-500'>
                                                <span className='text-gray-600'>Minimum OS Requirements:</span> Windows 7 SP1 or later, Windows Server 2008 R2 SP1 or later
                                            </span>
                                            <div className='text-gray-500 py-[1vh]'>
                                                <div
                                                    className='flex items-center cursor-pointer'
                                                    onClick={() => toggleDetails("dlpAgent")}
                                                >
                                                    <span className='text-gray-600 mr-2'>Additional Requirements:</span>
                                                    {isExpanded.dlpAgent ? <FaChevronUp /> : <FaChevronDown />}
                                                </div>
                                                {isExpanded.dlpAgent && (
                                                    <div className='mt-2'>
                                                        <a
                                                            className='underline hover:text-blue-600'
                                                            href='https://dotnet.microsoft.com/en-us/download/dotnet-framework'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            .Net Framework 4.5.2 or later*
                                                        </a>
                                                    </div>
                                                )}
                                            </div>

                                            {/* <br /> <span className='text-gray-500'>  Installer version - {fileVersions.dlpFileVersion}</span> */}
                                        </div>
                                    </div>

                                </div>
                                {/* <button onClick={() => handleInstaller("DLP", fileVersions.dlpFileName, 2)} className='text-blue-700 shadow-md bg-blue-100 py-[1vh] px-[0.5vw] button-no-padding hover:text-blue-800 hover:bg-white hover:border-[1px]'
                                    disabled={downloadingButton === 2}

                                >
                                    {downloadingButton === 2 ? 'Downloading...' : 'Download Installer'}
                                </button> */}
                                <div className='flex flex-col items-center text-center'>
                                    <button
                                        onClick={() => 
                                        {
                                            
                                            if (
                                                permissions.some(
                                                    (permission) =>
                                                        permission.pagePath === '/cms/installer/' &&
                                                        permission.permissionLevel === 'read_write'
                                                )
                                            ) {
                                            
                                            handleInstaller("DLP", fileVersions.dlpFileName, 2)}
                                        }}
                                        title={!permissions.some(permission => permission.pagePath === '/cms/installer/' && permission.permissionLevel === 'read_write') && "You don't have permissions"}
                                        className={`  ${!permissions.some(
                                            (permission) =>
                                                permission.pagePath === '/cms/installer/' &&
                                                permission.permissionLevel === 'read_write'
                                        )
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'cursor-pointer'} text-white bg-blue-600 font-[500] shadow-md border-[1px] border-blue-600 py-[1vh] px-[0.5vw] button-no-padding hover:text-blue-800 hover:bg-white hover:border-[1px] `}                                    // disabled={
                                    //     localStorage.getItem(`DLP${userId}`) === "pending" || // Local storage condition
                                    //     (!adminAuthData?.role?.includes("ADMINISTRATOR") && ( // If not "ADMINISTRATOR", check userAuthData conditions
                                    //         !(userAuthData?.permissions?.length > 0 && userAuthData.permissions[0]?.permissionLevel === 'read_write') ||
                                    //         !userAuthData.products.some(product => product.name.includes('DLP') && product.approve === true)
                                    //     ))
                                    // }
                                    // title={
                                    //     adminAuthData?.role?.includes("ADMINISTRATOR") ? '' : // No title if "ADMINISTRATOR"
                                    //         (userAuthData?.permissions?.length > 0 && userAuthData.permissions[0]?.permissionLevel === 'read_write')
                                    //             ? '' 
                                    //             : "You don't have permissions"
                                    // }
                                    >
                                        {localStorage.getItem(`DLP${userId}`) === "pending" ? 'Fetching Installer...' : 'Download Installer'}
                                    </button>
                                    <span className='text-gray-500'>  Installer version - {fileVersions.dlpFileVersion}</span>
                                </div>
                            </div>
                        </div>
                        <div className='pl-[3vw] text-[0.9em] text-gray-700 py-[2.3vh] bg-gray-100'>
                            This is an online installer and requires active internet connection while installing
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-row items-center gap-x-1 py-[0.6vh]'> <IoMdArrowDropright className='text-[1.2em] text-gray-800' />   <p className=' text-gray-800 text-[0.9em]'>Disclaimer: Hyperlinks marked with an asterisk (*) will redirect you outside the Matisoft website.</p>
                        </div>
                        {userAuthData?.role?.includes('WEBSITE_USER') && (<div className='flex flex-row items-center gap-x-1 text-gray-800 '><IoMdArrowDropright className='text-[1.2em] text-gray-800' />

                            <p className=' text-[0.9em]'>
                                To check daily rates, visit the <NavLink className='text-blue-700 hover:text-blue-900' to={`/cms/licenseInfo/${userId}`} target="_blank"> License information </NavLink> section. You can uninstall at any time to stop billing immediately.
                            </p>

                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
};
