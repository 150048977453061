import React from 'react';

const PageNumber = ({ pageSize, currPage, totalRecords, handlePageNumberChange }) => {
  // Calculate the total number of pages
  const totalPages = Math.ceil(totalRecords / pageSize);

  // Generate an array of page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination flex flex-row items-center justify-between whitespace-nowrap pr-[1%] ">
      <div className='text-[0.8em]'> Showing {((pageSize*(currPage-1))+1)} to {((pageSize*(currPage-1)))+Math.min(pageSize,totalRecords-(pageSize*(currPage-1)))} of {totalRecords} entries  &nbsp;</div>
      <div>
      <button onClick={() => handlePageNumberChange(currPage - 1)} disabled={currPage <= 1} className='dataentry text-gray-600 hover:text-gray-800 bg-blue-200 px-[0.5vw] py-[0.4vh]'>Prev</button>
      {currPage > 1 && <button onClick={() => handlePageNumberChange(1)} className='dataentry bg-blue-200 hover:text-gray-800 text-gray-600 px-[0.5vw] py-[0.4vh]'>1</button>}
      {currPage > 2 && <span>...</span>}

      <button onClick={() => handlePageNumberChange(currPage)} className="text-blue-700 dataentry bg-blue-200 px-[0.5vw] py-[0.4vh]">{currPage}</button>

      {currPage < totalPages - 1 && <span>...</span>}
      {currPage <= totalPages - 1 && <button onClick={() => handlePageNumberChange(totalPages)} disabled={currPage === totalPages} className='dataentry bg-blue-200 text-gray-600 hover:text-gray-800 px-[0.5vw] py-[0.4vh]'>{totalPages}</button>}

      <button onClick={() => handlePageNumberChange(currPage + 1)} disabled={currPage >= totalPages} className='dataentry text-gray-600 hover:text-gray-800 bg-blue-200 px-[0.5vw] py-[0.4vh]'>Next</button>
      </div>
    </div>
  );  
};

export default PageNumber;
