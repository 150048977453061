import React from 'react';

const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[60]" style={{ fontSize: '1rem' }}>
      <div className="bg-white px-[1vw] py-[2vh] rounded-md shadow-md w-[80vw] md:w-[35vw] h-[23vh] max-h-[50vh] overflow-y-auto relative">
        <h2 className="text-[1.2em] md:text-[1.3em] mb-4 pt-[1vh] text-justify">
          Are you sure you want to discard the changes?
        </h2>


        {/* Content container */}
        <div className="absolute bottom-0 right-0 py-[3vh] px-[2vw] flex justify-end gap-x-1 w-full">
          <button
            onClick={onConfirm}
            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
            >
            Yes
          </button>
          <button
            onClick={onClose}
            className="px-[1.2vw] py-[0.8vh] gap-x-4 bg-blue-600 text-white border border-blue-600 rounded shadow-md hover:bg-white hover:text-blue-700"
            >
            No
          </button>
        </div>
      </div>
    </div>

  );
};

export default ConfirmationDialog;
