
import { ERROR_COMPANY_AUTHENTICATE,REQUEST_COMPANY_AUTHENTICATE,SET_COMPANY_AUTHENTICATE } from "./companyDetail.action_type";
import axios from "axios";

export const checkCompanyAuthentication = (companyId) => {

    try {
      // console.log(pageSize,'pagesize');
      return async (dispatch) => {
        dispatch({ type: REQUEST_COMPANY_AUTHENTICATE });
  
        try {
        //   console.log(pageSize, "pageSize inside try");
        const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/company/details`, {
            companyId: companyId
          });
          
           
          dispatch({ type: SET_COMPANY_AUTHENTICATE, payload: response.data });
          // dispatch({ type: SET_SORT_OPTIONS, payload: { sortBy, sortOrder } });
  
    
          // Handle the response data
        } catch (error) {
          // console.error('Error fetching data:', error);
          dispatch({ type: ERROR_COMPANY_AUTHENTICATE, payload: error })
          // Handle errors
        }
      };
  
    } catch (error) {
      console.log(error, 'edrfdfdsfdsd');
  
    }
  
  };