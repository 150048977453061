
import { REQUEST_LICENCE_LIST, SET_PAGE_SIZE, SET_PAGE_NUMBER, SET_FILTERS, SET_SORT_OPTIONS, SET_FILTER_LICENCE_LIST, SET_PAGE_NUMBER_LICENCE_LIST, SET_PAGE_SIZE_LICENCE_LIST, SET_SORT_OPTIONS_LICENCE_LIST, RECEIVE_LICENCE_LIST, REQUEST_FAILED_LICENCE_LIST, SET_UPDATE_ROW_LICENSE } from "./licence.action_type"
const initialState = {
    loading: false,

    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loading: false,
    licenceList: [],
    totalRecords: 0,
    error: null,
    filters: {},
    actionList: [],
    pageSize: 100,
    pageNumber: 1,
    error: false,
    lastCroneDateTime: '',
    nextRunAt: ''

}
const reducer = (state = initialState, action) => {
// console.log(state,"list");

    switch (action.type) {
        case REQUEST_LICENCE_LIST:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_LICENCE_LIST:
            return {
                ...state,
                loading: false,
                actionList: action.payload.statusList,
                licenceList: action.payload.data,
                totalRecords: action.payload.totalRecords,
                lastCroneDateTime: action.payload.lastCronRunDateTime,
                nextRunAt: action.payload.nextRunAtUTC
            }
            case SET_UPDATE_ROW_LICENSE:
                return {
                    ...state,
                    licenceList: state.licenceList.map((item) => 
                        item._id === action.payload._id ? action.payload : item
                    ),
                };
            
        case REQUEST_FAILED_LICENCE_LIST:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_LICENCE_LIST:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_LICENCE_LIST:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_LICENCE_LIST:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_LICENCE_LIST: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state;
    }
}
export { reducer };