import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { passwordStrength } from 'check-password-strength';
import { FaCheck } from 'react-icons/fa6';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons
import { useNavigate } from 'react-router-dom';

const ResetPassword = ({ email }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessagePass, setErrorMessagePass] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle new password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
    const navigate = useNavigate();
    const [passwordValues, setPasswordValues] = useState({
        password: '',
        confirmPassword: ''
    })
    const [score, setScore] = useState({
        length: 0,
        value: '',
        contains: []
    });
    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };
    const handleSetPassword = (e) => {
        const { name, value } = e.target; // Extract name and value from the input
        setPasswordValues((prevState) => ({
            ...prevState,
            [name]: value // Dynamically update the correct field based on the name attribute
        }));
        if (name === 'password') {
            const passwordStrengthResult = passwordStrength(value);
            // console.log(passwordStrengthResult);
            setScore((prevScore) => ({
                ...prevScore,
                value: passwordStrengthResult.value,
                length: passwordStrengthResult.length,
                contains: passwordStrengthResult.contains
                // corrected 'lentgh' to 'length'
            }));
        }
    };

    const handleResetPassword = (e) => {
        e.preventDefault();

        if (passwordValues.password !== passwordValues.confirmPassword) {
            setErrorMessagePass("Passwords do not match. Please ensure both fields contain the same password!");

            // Show toast error only on larger screens
            if (window.innerWidth >= 768) {
                toast.error("Passwords do not match. Please ensure both fields contain the same password!");
            }

            return; // Exit the function early
        }


        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/forgot-password/reset`, {
            email,
            newPassword: passwordValues.password,
            confirmPassword: passwordValues.confirmPassword,
        })
            .then((response) => {
                toast.success(response?.data?.msg);
                setErrorMessagePass('');
                navigate('/login');
            })
            .catch((err) => {
                setErrorMessagePass(err?.response?.data?.error || err?.message)
                if (window.innerWidth >= 768) {
                    toast.error(err?.response?.data?.error || err?.message);
                }
            })

    };

    return (
        <div className='p-2'>
            <h2 className='text-center text-[1.5em] font-[500] mb-[2vh] text-white bg-[#458EA2]'>Reset Password</h2>

            <form onSubmit={handleResetPassword} className='p-4 flex flex-col '>
                {errorMessagePass && <div className='text-red-600 text-justify md:hidden'>{errorMessagePass}</div>}
                <div className='mb-4 relative'>
                    <label className='block text-gray-800 py-[1vh]'>New Password</label>
                    <input
                        type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                        name='password'
                        value={passwordValues.password}
                        onChange={handleSetPassword}
                        required
                        maxLength='16'
                        placeholder='Enter your new password'
                        className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                    />
                    <span
                        className='absolute inset-y-0 right-3 top-[44%] flex items-center cursor-pointer'
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icons for toggling */}
                    </span>
                </div>
                <div className='mb-2 relative'>
                    <label className='block text-gray-800 py-[1vh]'>Confirm Password</label>
                    <input
                        type={showConfirmPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                        name='confirmPassword'
                        value={passwordValues.confirmPassword}
                        maxLength='16'
                        onChange={handleSetPassword}
                        required
                        placeholder='Confirm your new password'
                        className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
                    />
                    <span
                        className='absolute inset-y-0 top-[44%]  right-3 flex items-center cursor-pointer'
                        onClick={toggleConfirmPasswordVisibility}
                    >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icons for toggling */}
                    </span>
                </div>
                {passwordValues.password?.length > 0 && passwordValues.confirmPassword?.length > 0 && (
                    passwordValues.password === passwordValues.confirmPassword ? (
                        <div className='text-green-700 flex flex-col text-[0.9em] items-end'>
                            <div>Passwords match</div>
                        </div>
                    ) : (
                        <div className='text-red-600 flex justify-end text-[0.9em]'>
                            Passwords do not match
                        </div>
                    )
                )}
                <div className='text-blue-700 text-[0.8em] py-[0.5vh]'>(Your password should contain at least 8 characters and the properties listed below)</div>

                <div className="text-[0.8em] py-[1vh]">
                    <p>Password must contain:</p>
                    <div className="list-inside grid grid-cols-2">
                        <div className={`flex flex-row gap-x-1 items-center ${score.length >= 8 ? 'text-blue-600' : 'text-gray-500'}`}>
                            <FaCheck /> At least 8 characters
                        </div>
                        <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("lowercase") ? 'text-blue-600' : 'text-gray-500'}`}>
                            <FaCheck /> Lowercase
                        </div>
                        <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("uppercase") ? 'text-blue-600' : 'text-gray-500'}`}>
                            <FaCheck /> Uppercase
                        </div>
                        <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("number") ? 'text-blue-600' : 'text-gray-500'}`}>
                            <FaCheck /> Numbers
                        </div>
                        <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("symbol") ? 'text-blue-600' : 'text-gray-500'}`}>
                            <FaCheck /> Special Characters
                        </div>
                    </div>
                </div>
                <button type='submit'
                    className='w-full mt-1 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200'
                >
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default ResetPassword;
