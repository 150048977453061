
import {
    REQUEST_PROGRAM_LOG, SET_FILTER_PROGRAM_LOG,
    SET_PAGE_NUMBER_PROGRAM_LOG, SET_PAGE_SIZE_PROGRAM_LOG, SET_SORT_OPTIONS_PROGRAM_LOG,
    RECEIVE_PROGRAM_LOG, REQUEST_FAILED_PROGRAM_LOG
} from "./log.action_type"
const initialState = {
    loading: false,

    sortBy: 'date_time', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loading: false,
    programLogs: [],
    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case REQUEST_PROGRAM_LOG:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_PROGRAM_LOG:
            return {
                ...state,
                loading: false,
                programLogs: action.payload.data,
                totalRecords: action.payload.totalRecords,
            }
        case REQUEST_FAILED_PROGRAM_LOG:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_PROGRAM_LOG:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_PROGRAM_LOG:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_PROGRAM_LOG:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_PROGRAM_LOG: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state;
    }
}
export { reducer };