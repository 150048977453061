import React, { useRef } from 'react';
import ClipboardJS from 'clipboard';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegCopy } from "react-icons/fa";

const CopyButton = ({ text }) => {
  const buttonRef = useRef(null);
  let clipboard = null; // Declare clipboard variable outside the component

  const handleCopy = () => {
    if (buttonRef.current && text) {
      if (clipboard) {
        clipboard.destroy();
        clipboard = null;
      }

      clipboard = new ClipboardJS(buttonRef.current, {
        text: () => text
      });

      clipboard.on('success', (e) => {
        console.log('Text copied successfully:', text);
        toast.success('Key copied successfully!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
        e.clearSelection();
        clipboard.destroy(); // Clean up the clipboard instance
      });

      clipboard.on('error', (e) => {
        console.error('Failed to copy text: ', e);
        toast.error('Failed to copy text. Please try again.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
        clipboard.destroy(); // Clean up the clipboard instance
      });

      // Manually trigger the copy action on the button
      clipboard.onClick({ delegateTarget: buttonRef.current });
    }
  };

  // Clean up ClipboardJS instance on component unmount
  React.useEffect(() => {
    return () => {
      if (clipboard) {
        clipboard.destroy();
        clipboard = null;
      }
    };
  }, []);

  return (
    <button
      ref={buttonRef}
      title='Copy'
      onClick={handleCopy}
      className='text-[#48696F] hover:text-blue-700 pr-[1vw]'
    >
      <FaRegCopy className='font-[1.3em]' />
    </button>
  );
};

export default CopyButton;
