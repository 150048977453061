import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IoIosMenu, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { MdDashboardCustomize } from "react-icons/md";
import { FaLayerGroup, FaRegUser } from "react-icons/fa";
import '../css/sidebar.css'
import { TbCloudDownload } from "react-icons/tb";
import { checkUserAuth } from '../../Redux/userAuth/userAuth.action';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { MdAdminPanelSettings } from "react-icons/md";
import { setOrgId, setPermissions, setProducts } from '../../Redux/companyid/companyid.action';
import { checkAdminAuth } from '../../Redux/adminAuth/adminAuth.action';
import { GoOrganization } from 'react-icons/go';
import { HiOutlineBuildingOffice } from 'react-icons/hi2';
import { FaUserShield } from "react-icons/fa6";

const Sidebar1 = ({ isExpanded, toggleSidebar }) => {
    const dispatch = useDispatch();
    const { companyId, userId, orgId, userAuthData, permissions, adminAuthData } = useSelector((state) => ({
        companyId: state.companyid.companyId,
        userId: state.companyid.userId,
        orgId: state.companyid.orgId,
        userAuthData: state.userAuth.userAuthData,
        permissions: state.companyid.permissions,
        adminAuthData: state.adminAuth.adminAuthData
        // credentials: state.auth.credentials
    }));

    const [showSetting, setShowSetting] = useState(false);
    const [lowerWindow, setLowerWindow] = useState(false);
    const [data, setData] = useState(null);
    // const [isExpanded, setIsExpanded] = useState(true);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [activeItem, setActiveItem] = useState(null);

    // const toggleSidebar = () => {
    //     setIsExpanded(!isExpanded);
    // };

    const handleItemClick = (item) => {
        setActiveItem(activeItem === item ? null : item);
    };

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };
    const [activeItemCollapse, setActiveItemCollapse] = useState(null);

    const handleMouseEnterCollapse = (id) => {
        setActiveItemCollapse(id);
    };

    const handleMouseLeaveCollapse = (event) => {
        setActiveItemCollapse(null);
    };
    useEffect(() => {

        if (userId) {
            dispatch(checkUserAuth(userId));
            dispatch(checkAdminAuth(userId));
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (userAuthData?.permissions) {
            dispatch(setPermissions(userAuthData.permissions));
        }
        if (userAuthData?.orgId) {
            dispatch(setOrgId(userAuthData?.orgId));
        }
        if (userAuthData?.products) {
            dispatch(setProducts(userAuthData.products));
        }
    }, [dispatch, userAuthData]);


    return (
        <>{isExpanded ?
            <div className={`sidebar-container bg-[#0d1321] h-screen ${isExpanded ? 'w-1/5' : 'w-1/12'}`}>
                <div className='px-[1vw] pt-[1vh]'>
                    <IoIosMenu className='text-white text-[25px]' onClick={toggleSidebar} />
                </div>

                <div className='flex flex-row items-center justify-center h-[10%] mx-auto'>
                    <img src='/small-logo.png' className={`'flex h-[60%] px-[3%] ${isExpanded ? 'h-[60%]' : 'h-[40%]'}`} alt='company-logo' />
                    {isExpanded && (<div className='flex flex-col items-start'>
                        <span className='text-[0.9em] text-white'>Matisoft Cloud Platform</span>
                        <span className='text-secondary text-[0.7em]'>
                            {userAuthData && userAuthData?.company_name ? (
                                <a href={`/cms/dashboard`} target='_blank'>
                                    {userAuthData?.company_name}
                                </a>
                            ) : (
                                <a href={`/cms/dashboard`} target='_blank'>
                                    <span>Matisoft Cyber Security Labs</span></a>
                            )}

                        </span>
                    </div>)}
                </div>

                <div className='flex flex-col items-center text-gray-200 pt-[3vh]'     style={{ maxHeight: '75vh', marginBottom: '10vh'}}>
                    {userAuthData?.role?.includes('WEBSITE_USER') && <ul className='w-full mx-auto'>
                       
                        {/* <li
                            className={`nav-item ${hoveredItem === 'dashboard' ? 'bg-[#62c1e4] text-black' : ''}`}
                            onMouseEnter={() => handleMouseEnter('dashboard')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('dashboard')}>
                                <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                    <MdDashboardCustomize className='font-thin text-[0.9em]' />
                                    {isExpanded && <span className="title text-[0.9em]">Dashboard</span>}
                                </span>
                                {isExpanded && <IoIosArrowForward />}
                            </div>
                            {(activeItem === 'dashboard' || hoveredItem === 'dashboard') && (
                                <ul className='ml-[2vw]'>
                                    <li className='text-[0.9em]   '>
                                        <NavLink to={`/cms/overview/${userId}`} className='pl-[2vw]'>
                                            Dashboard
                                        </NavLink>
                                    </li>

                                </ul>
                            )}
                        </li> */}

                        <li
                            className={`nav-item ${hoveredItem === 'company' ? 'bg-[#62c1e4] text-black' : ''}`}
                            onMouseEnter={() => handleMouseEnter('company')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('company')}>
                                <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                    <HiOutlineBuildingOffice className={` font-thin text-[0.9em] ${hoveredItem !== 'company' && 'text-gray-300'}`} />
                                    {isExpanded && <span className="title text-[0.9em]">Company</span>}
                                </span>
                                {isExpanded && <IoIosArrowForward />}
                            </div>
                            {(activeItem === 'company' || hoveredItem === 'company') && (
                                <ul className='ml-[2vw]'>
                                    <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  '>
                                        <a href={`/cms/company/list`} className='pl-[2vw]'>
                                            View Companies
                                        </a>
                                    </li>
                                    <li className='text-[0.9em] '>
                                        <a href={`/cms/company/create`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                            Add Company
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li
                            className={`nav-item ${hoveredItem === 'administrator' ? 'bg-[#62c1e4] text-black' : ''}`}
                            onMouseEnter={() => handleMouseEnter('administrator')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('administrator')}>
                                <span className='flex flex-row justify-start items-start gap-x-[0.9vw] text-yellow'>
                                    <FaUserShield className={` font-thin text-[1.1em] ml-[-1px] ${hoveredItem !== 'administrator' && 'text-gray-400'} `} />
                                    {isExpanded && <span className="title text-[0.9em]">Administration</span>}
                                </span>
                                {isExpanded && <IoIosArrowForward />}
                            </div>
                            {(activeItem === 'administrator' || hoveredItem === 'administrator') && (
                                <ul className='ml-[2vw]'>
                                    <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  '>
                                        <NavLink to={`/cms/admin/notifications/${userId}`} className='pl-[2vw]'>
                                            Notifications
                                        </NavLink>
                                    </li>
                                    <li className='text-[0.9em]  border-b-blue-400 border-b-[0.1em] '>
                                        <NavLink to={`/cms/userroles/${userId}`} rel="noopener noreferrer" className='pl-[2vw]'>
                                            View/Edit New Users
                                        </NavLink>
                                    </li>
                                    <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em] '>
                                        <NavLink to={`/cms/licenseInfo/${userId}`} className='pl-[2vw]'>
                                            License Information
                                        </NavLink>
                                    </li>
                                    <li className='text-[0.9em] '>
                                        <NavLink to={`/cms/admin/organisation/${userId}`} rel="noopener noreferrer" className='pl-[2vw]'>
                                            View/Edit Account
                                        </NavLink>
                                    </li>
                                   
                                </ul>
                            )}
                        </li>

                    </ul>}
                </div>
                {lowerWindow && isExpanded && userAuthData?.role?.includes('WEBSITE_USER') && (
                    <div className=' w-full h-[16vh] px-[2vw] pt-[2vh] absolute bottom-[10vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850]  flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                      
                        <div className='pb-[0.5vh]'>
                            <span className='text-secondary'>User Type: </span> {
                                (() => {
                                    const generalAccess = userAuthData?.role1;

                                    if (generalAccess === 'read_write') {
                                        return 'Read & Write';
                                    } else if (generalAccess === 'read_only') {
                                        return 'Read Only';
                                    } else if (generalAccess === 'no_access') {
                                        return 'No Access';
                                    } else {
                                        return generalAccess;  // Default case, if any other value exists
                                    }
                                })()
                            }
                        </div>
                        <div className='hover:text-white'>
                            <NavLink to={`/cms/account/${userId}`}>View/Edit User Account</NavLink>
                        </div>
                        <div className='hover:text-white'>
                            <a href='/cms/logout'>Sign Out</a>
                        </div>
                    </div>
                )}
                <div className='h-[10vh] px-[0.5vw] pt-[2vh] absolute bottom-0 bg-[#2C3850] w-full flex flex-row justify-between text-white items-start' onClick={() => setLowerWindow(!lowerWindow)}>
                    <div className='flex flex-row items-start'>
                        <FaRegUser className='text-[0.9em]' />
                        {isExpanded && (
                            <div className='flex flex-col ml-[0.6vw]'>
                                <div className='text-[0.9em]'>{`${userAuthData?.email ? userAuthData?.email : ''}`}</div>
                                <div className='text-[0.8em] font-[300] text-gray-300'>
                                    <span className='text-secondary font-[400]'>Page Access:</span> {
                                        (() => {
                                            const permission = permissions.find((path) => window.location.href.includes(path.pagePath))?.permissionLevel
                                                || userAuthData?.role1?.toLowerCase();

                                            if (permission === 'read_write') {
                                                return 'Read & Write';
                                            } else if (permission === 'read_only') {
                                                return 'Read Only';
                                            } else if (permission === 'no_access') {
                                                return 'No Access';
                                            } else {
                                                return permission?.toUpperCase();  // Default case, if any other value exists
                                            }
                                        })()
                                    }
                                </div>

                            </div>
                        )}
                    </div>
                    {isExpanded && <IoIosArrowUp />}
                </div>
            </div>
            :
            <div className='collapsed-view bg-[#0d1321] h-screen w-[4vw] fixed z-50 flex flex-col justify-between '>
                <div>
                    <div className='px-[1vw] pt-[1vh]'>
                        <IoIosMenu className='text-[26px] text-white cursor-pointer' onClick={toggleSidebar} />
                    </div>

                    {/* Logo at the top */}
                    <div className="flex justify-center py-[2vh]">
                        <a href='/cms/dashboard'>
                            <img
                                src='/small-logo.png'
                                className={`h-[60px] ${isExpanded ? 'w-[80%]' : 'w-[26px] object-contain'}`}
                                alt='company-logo'
                            />
                        </a>
                    </div>

                    {/* Sidebar Items */}
                    {userAuthData?.role?.includes('WEBSITE_USER') && <div className="flex flex-col text-white items-center pt-[5vh] gap-y-[2vh] ">

                        {/* <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                            <div
                                className={`p-3 ${activeItemCollapse === 1 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                onMouseEnter={() => handleMouseEnterCollapse(1)}
                            >
                                <MdDashboardCustomize className='font-thin text-[0.9em] hover:cursor-pointer' />
                            </div>
                            {activeItemCollapse === 1 && (
                                <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                    <a href="#" className="block p-2 text-[#0d1321] transition-colors">Dashboard</a>
                                    <a href={`/cms/overview/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Dashboard</a>

                                </div>
                            )}
                        </div> */}

                        <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                            <div
                                className={`p-3 ${activeItemCollapse === 2 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                onMouseEnter={() => handleMouseEnterCollapse(2)}
                            >
                                <HiOutlineBuildingOffice className='font-thin text-[0.9em] hover:cursor-pointer' />
                            </div>
                            {activeItemCollapse === 2 && (
                                <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                    <a href="#" className="block p-2 text-[#0d1321] transition-colors">Companies</a>
                                    <a href="/cms/company/list" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View Companies</a>
                                    <a href="/cms/company/create" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Add Company</a>
                                </div>
                            )}
                        </div>

                        <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                            <div
                                className={`p-3 ${activeItemCollapse === 3 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                onMouseEnter={() => handleMouseEnterCollapse(3)}
                            >
                                <FaUserShield className='font-thin text-[1.1em] hover:cursor-pointer' />
                            </div>
                            {activeItemCollapse === 3 && (
                                <div className="absolute top-0 left-full w-[15vw] bg-[#62c1e4] transition-opacity z-[101]">
                                    <a href="#" className="block p-2 text-[#0d1321] transition-colors">Administration</a>
                                    <a href={`/cms/admin/notifications/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Notifications</a>
                                    <a href={`/cms/userroles/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View/Edit New Users</a>
                                    <a href={`/cms/licenseinfo/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">License Information</a>
                                    <a href={`/cms/admin/organisation/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View/Edit Account</a>
                                    {/* <a href={`/cms/account/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View/Edit User Account</a> */}
                                    {/* <a href={`/cms/company/list`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View/Edit Companies</a> */}
                                </div>
                            )}
                        </div>

                    </div>}
                </div>

                {/* Bottom User Icon */}
                <div className='relative'>
                    <div className='absolute bottom-0 left-0 h-[9vh]  w-full flex justify-center py-[2vh] bg-[#2C3850]' onClick={() => setLowerWindow(!lowerWindow)}>
                        <MdKeyboardArrowUp className='text-white' />
                        <FaRegUser className='text-white cursor-pointer' />
                    </div>
                    {lowerWindow && userAuthData?.role?.includes('WEBSITE_USER') && (
                        <div className='h-[12vh] px-[2vw] pt-[2vh] w-[15vw] z-50 absolute left-[4vw] bottom-[9vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                          
                            <div className='hover:text-white'>
                                <a href={`/cms/account/${userId}`}>Manage User Account</a>
                            </div>
                            <div className='hover:text-white'>
                                <a href='/cms/logout'>Sign Out</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        }
        </>
    );
};

export default Sidebar1;
