import { REQUEST_RELEASE, 
    ERROR_RELEASE, SET_RELEASE } from './release.action_type'; 

const initialState = {
    loading: false,
    releaseData: {},
    error: null,
};

const reducer = (state = initialState, action) => {
    // Correctly log the releaseData from the state

    switch (action.type) {
        case REQUEST_RELEASE:
            return {
                ...state,
                loading: true,
            };
            
        case SET_RELEASE:
            return {
                ...state,
                loading: false,
                releaseData: action.payload.data,
                error: null,
            };
            
        case ERROR_RELEASE:
            return {
                ...state,
                loading: false,  // Ensure loading is false on error as well
                error: action.payload,
            };
            
        default:
            return state;
    }
};

export { reducer };
