import React, { useEffect, useState } from 'react'
import { styles } from '../styles';
import { BiSolidRightArrow } from "react-icons/bi";
import DPDP from './DPDP';
import { IoShieldCheckmark } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

const DlpHero1 = () => {
    const [showMore, setShowMore] = useState(false); // State to toggle component visibility
    const handleKnowMoreClick = () => {
        setShowMore(!showMore); // Toggle the state
    };
    const [showBar, setShowBar] = useState(() => {
        const storedValue = sessionStorage.getItem('showBar');
        return storedValue ? JSON.parse(storedValue) : true;
    });

    // Update session storage whenever showBar changes
    useEffect(() => {
        sessionStorage.setItem('showBar', JSON.stringify(showBar));
    }, [showBar]);

    const toggleShowBar = () => {
        setShowBar((prev) => !prev); // Toggle showBar state
    };
    return (
        <>

            {showBar && <div className="streaming-text z-[30] flex items-center justify-center mx-auto">
                <IoShieldCheckmark />&nbsp;How does Matisoft DLP ensure Digital Personal Data Protection Act (DPDP Act) compliance?
                <span
                    className='underline text-gray-300 cursor-pointer hover:text-blue-700 ml-2 font-semibold'
                    onClick={handleKnowMoreClick}
                >
                    Know more
                </span>
                <IoMdClose className='absolute right-1 text-gray-200 cursor-pointer hover:scale-125 transition-transform duration-200' onClick={toggleShowBar} />
            </div>}

            {showMore && (
                <>

                    {/* DPDP Content */}
                    <DPDP showMore={showMore} setShowMore={setShowMore} />
                </>

            )}
            <div className='h-screen relative w-full mx-auto heroDlp'>
                <div className='overlay-layer'></div>
                <div className='text-layer absolute left-[10%] top-[10%] text-white w-[80%] margin-auto'>
                    <h2 className='text-[1.6em] sm:text-[1.8em] 2xl:text-[2.3em] font-[600]'>
                    Matisoft DLP ensures DPDP Act compliance by preventing data leaks through:
                    </h2>                    <ul className='text-[1.3em] sm:text-[1.5em] 2xl:text-[1.8em] pt-[2vh] flex flex-col gap-y-[1.4vh]'>
                        <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                            <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                            <span>Emails and Attachments</span>
                        </li>
                        <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                            <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                            <span>Cloud uploads</span>
                        </li>
                        <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                            <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                            <span>Website blocking</span>
                        </li>
                        <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                            <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                            <span>Application blocking</span>
                        </li>
                        <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                            <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                            <span>Pendrives, mobile phones, Bluetooth, and other devices.</span>
                        </li>
                        <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                            <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                            <span>Proprietary USB firewall ensures 100% secure data transfer without data leaks or viruses.</span>
                        </li>
                        <li>  <NavLink to="/presignup" className=" btn btn-default text-white font-[500] uppercase">Book your demo</NavLink>
                        </li>
                    </ul>

                </div>
            </div></>

    )
}

export default DlpHero1