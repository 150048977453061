import axios from 'axios'; // Import Axios
import { toast } from 'react-toastify';
import {
  REQUEST_LICENCE_HISTORY_USER,
  REQUEST_FAILED_LICENCE_HISTORY_USER,
  RECEIVE_LICENCE_HISTORY_USER,
  SET_PAGE_SIZE_LICENCE_HISTORY_USER,
  SET_SORT_OPTIONS_LICENCE_HISTORY_USER,
  SET_PAGE_NUMBER_LICENCE_HISTORY_USER,
  SET_FILTER_LICENCE_HISTORY_USER
} from './licence.action_type';

export const fetchLicenceHISTORY_USER = (UserId, key, pageSize, page, sortBy, sortOrder, filtersvalue) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_LICENCE_HISTORY_USER });

    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/transaction/history`, {
        ...filtersvalue,
        pageSize
        , page
        , sortBy,
        sortOrder,
        userId: UserId,
        key
      });
      dispatch({ type: RECEIVE_LICENCE_HISTORY_USER, payload: response.data });
      // console.log('Data from backend:', response.data);
      // toast.success("Licence List retrieved successfully");
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: REQUEST_FAILED_LICENCE_HISTORY_USER, payload: error });
      toast.error(error?.response?.data || error?.message);
    }
  };
};

export const setSortOptions = (sortBy, sortOrder) => ({
  type: SET_SORT_OPTIONS_LICENCE_HISTORY_USER,
  payload: { sortBy, sortOrder }
});

export const setPageSize = (pageSize) => ({
  type: SET_PAGE_SIZE_LICENCE_HISTORY_USER,
  payload: pageSize,
});

export const setPageNumber = (pageNumber) => ({
  type: SET_PAGE_NUMBER_LICENCE_HISTORY_USER,
  payload: pageNumber,
});

export const setFilters = (filters) => ({
  type: SET_FILTER_LICENCE_HISTORY_USER,
  payload: filters,
});
