import React, { useEffect, useState } from 'react';
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUuidDataFromAPI, setFilters } from '../../Redux/uuid/uuid.action';
import { FaMinusCircle } from "react-icons/fa";
import { withRouter, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
const FilterSection = ({ showFilter, setShowFilter, columns, onApplyFilters }) => {
  const [numFilters, setNumFilters] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [field, setField] = useState([]);
  const [operand, setOperand] = useState([]);
  const [value, setValue] = useState([]);
  const [exclude, setExclude] = useState([]);

  useEffect(() => {
    const fieldParam = new URLSearchParams(location.search).get('field');
    const operandParam = new URLSearchParams(location.search).get('operand');
    const valueParam = new URLSearchParams(location.search).get('value');
    const excludeParam = new URLSearchParams(location.search).get('exclude');

    // Parse JSON strings from query parameters
    const parsedField = fieldParam ? JSON.parse(fieldParam) : [];
    const parsedOperand = operandParam ? JSON.parse(operandParam) : [];
    const parsedValue = valueParam ? JSON.parse(valueParam) : [];
    const parsedExclude = excludeParam ? JSON.parse(excludeParam) : [];

    // Update state with parsed values
    setField(parsedField);
    setOperand(parsedOperand);
    setValue(parsedValue);
    setExclude(parsedExclude);
  }, [searchParams]); // Watch for changes in searchParams

  // Recreate filtersvalue whenever field, operand, value, or exclude changes
  const initialFilters = React.useMemo(() => {
    return {
      filters: {
        field,
        operand,
        value,
        exclude,
      },
    };
  }, [field, operand, value, exclude]);

  const [filterState, setFilterState] = useState({
    field: [],
    operand: [],
    value: [],
    exclude: []
  });
  const queryParams = new URLSearchParams({
    Filtersvalues: JSON.stringify(initialFilters.filters)
  });
  useEffect(() => {
    if (initialFilters && initialFilters.filters) {
      const appliedFiltersCount = initialFilters.filters.field.length;
      setNumFilters(appliedFiltersCount > 0 ? appliedFiltersCount : 1);
    }
  }, [initialFilters]);
  const getOperandOptionsBasedOnType = (fieldType) => {
    const allOptions = [
      { value: "Equal To", label: "Equal To" },
      { value: "Starts With", label: "Starts With" },
      { value: "Ends With", label: "Ends With" },
      { value: "Contains", label: "Contains" },
      { value: "Greater Than", label: "Greater Than" },
      { value: "Less Than", label: "Less Than" },
      { value: "UNIQUE", label: "Unique" },
    ];

    // Filter options based on field type
    switch (fieldType) {
      case 'string':
        return allOptions;
      case 'number':
        return allOptions.filter((option) => option.value !== 'Starts With' && option.value !== 'Ends With' && option.value !== 'Contains');
      case 'date':
        return allOptions.filter((option) => option.value !== 'UNIQUE');
      default:
        return allOptions;
    }
  };
  const getInputTypeBasedOnType = (fieldType) => {
    switch (fieldType) {
      case 'string':
        return 'text';
      case 'number':
        return 'number';
      case 'date':
        return 'date';
      default:
        return 'text';
    }
  };

  const handleChange = (index, key, value) => {
    const newState = { ...filterState };
    newState[key][index] = value;
    if (key === "field") {
      newState.value[index] = ''; // Clear value
      newState.operand[index] = ''; // Clear operand
    }
    if (key === "operand") {
      newState.value[index] = '';
    }
    if (key === "value") {
      const columnType = columns.find((col) => col.value === filterState.field[index])?.type;
      if (columnType === 'number' && !isNaN(value)) {
        newState.value[index] = Number(value);
      } else if (columnType === 'date') {
        newState.value[index] = new Date(value).toISOString(); // Assuming conversion to ISO date string
      } else {
        newState.value[index] = value;
      }
    }

    setFilterState(newState);
  }
  useEffect(() => {
    setFilterState({
      field: initialFilters?.filters?.field || [],
      operand: initialFilters?.filters?.operand || [],
      value: initialFilters?.filters?.value || [],
      exclude: initialFilters?.filters?.exclude || [], // Set default to "false" if not provided
    });
  }, [initialFilters]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const allFiltersFilled = filterState.field.every((field) => field && filterState.operand.every((operand) => operand) && filterState.value.every((value) => value)) && filterState.exclude.every((value) => value);

    // if (!allFiltersFilled) {
    //   alert('Please select all values for each filter before applying.');
    //   return;
    // }
    const updatedFilterState = { ...filterState };

    // Check if the selected field is "file_size" and perform the conversion
    updatedFilterState.value = updatedFilterState.value.map((value, index) => {
      if (updatedFilterState.field[index] === "file_size") {
        return Number(value) * 1024;
      }
      return value;
    });
    onApplyFilters(filterState);
    dispatch(setFilters(filterState));

    const filtersQueryString = Object.entries(updatedFilterState)
      .map(([key, value]) => `${key}=${encodeURIComponent(JSON.stringify(value))}`)
      .join('&');
    const existingParams = new URLSearchParams(location.search);
    const filterParams = ['field', 'operand', 'value', 'exclude'];
    filterParams.forEach(param => existingParams.delete(param));
    setSearchParams(existingParams.toString());
    const newUrl = `${window.location.pathname}?${existingParams.toString()}${filtersQueryString ? '&' + filtersQueryString : ''}`;

    navigate(newUrl);
    setShowFilter(!showFilter);
  };

  const addMoreFilters = () => {
    // console.log(initialFilters, "initialFilter");
    setNumFilters(numFilters + 1);
    setFilterState(prevState => ({
      ...prevState,
      field: [...prevState.field, ""],
      operand: [...prevState.operand, ""],
      value: [...prevState.value, ""],
      exclude: [...prevState.exclude, ""]
    }));
  };

  const removeFilter = (index) => {
    setNumFilters(numFilters - 1);
    setFilterState(prevState => {
      const newState = { ...prevState };
      newState.field.splice(index, 1);
      newState.operand.splice(index, 1);
      newState.value.splice(index, 1);
      newState.exclude.splice(index, 1);
      return newState;
    });
  };

  const clearFilters = () => {
    const existingParams = new URLSearchParams(location.search);
    const filterParams = ['field', 'operand', 'value', 'exclude'];
    filterParams.forEach(param => existingParams.delete(param));
    setSearchParams(existingParams.toString());
    setFilterState({
      field: [],
      operand: [],
      value: [],
      exclude: []
    });
    onApplyFilters(filterState);
    setShowFilter(!showFilter);
  };

  return (
    <form onSubmit={handleSubmit} className='filter-section-container z-[45]'>
      <div className='filter-section-content'>
        <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setShowFilter(!showFilter)}>
          <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
        </button>
        {[...Array(numFilters)].map((_, index) => (
          <div key={index} className='filter-section-row'>
            <div>
              <select
                required
                onChange={(e) => handleChange(index, "field", e.target.value)}
                className='filter-section-select'
                value={filterState.field[index] || ""} // Ensure value is handled correctly
              >
                <option value="" disabled hidden>Select Column Name</option>
                {columns.map((item, optionIndex) => (
                  <option key={optionIndex} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select
                required
                value={filterState.operand[index] || ""} // Handle case when value is undefined
                onChange={(e) => handleChange(index, "operand", e.target.value)}
                className='filter-section-select'
              >
                <option value="" disabled hidden>Select Operand</option>
                {getOperandOptionsBasedOnType(
                  columns.find(col => col.value === filterState.field[index])?.type
                ).map((option, optionIndex) => (
                  <option key={optionIndex} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              {columns.find((col) => col.value === filterState.field[index])?.type !== 'date' ?
                <input
                  type={getInputTypeBasedOnType(columns.find(col => col.value === filterState.field[index])?.type)}
                  name="value"
                  placeholder={filterState.field[index] === "file_size" ? 'in (KB)' : "Value"}
                  className="filter-section-input"
                  required={filterState.operand[index] !== "Equal To"}
                  value={filterState.value[index] ? filterState.value[index] : ''}
                  onChange={(e) => handleChange(index, "value", e.target.value)}
                  disabled={filterState.operand[index] === "UNIQUE"}
                  maxLength={(filterState.field[index]) === "file_size" || (filterState.field[index]) === "instance_count" || (filterState.field[index]) === "no_of_systems" ? "" : "2000"}
                  max={(filterState.field[index] === "file_size") ? 18446744073709551615 : ((filterState.field[index] === "instance_count" || filterState.field[index] === "no_of_systems") ? 4294967295 : null)} />
                :
                <DateTimePicker
                value={filterState.value[index] ? new Date(filterState.value[index]) : null}
                onChange={(date) => handleChange(index, "value", date)}
                format="dd/MM/yyyy HH:mm" // Use format to specify 24-hour time format
                calendarAriaLabel="Toggle calendar"
                showLeadingZeros
                clearIcon={null} // Remove clear icon
                nativeInputAriaLabel="Date and time"
                clearAriaLabel="Clear value"
                secondPlaceholder="time"
                disabled={filterState.operand[index] === "UNIQUE"}
                openWidgetsOnFocus={false}
                inputProps={{
                  placeholder: 'DD/MM/YYYY HH:mm', // Ensure correct placeholder
                  style: { border: 'none' } // Override input style to remove border
                }}
                placeholderText="Select date and time"
                className="filter-section-input bg-white"
              />
              

              }
            </div>
            <div>
              <select
                className="filter-section-select"
                name="exclude"
                value={filterState.exclude[index] || ""} // Handle case when value is undefined
                disabled={filterState.operand[index] === "UNIQUE"}
                onChange={(e) => handleChange(index, "exclude", e.target.value)}
                required
              >
                <option value="" disabled hidden>Include/Exclude</option>

                <option value="false">Include</option>
                <option value="true">Exclude</option>
              </select>
            </div>

            {index > 0 && (
              <button className='absolute right-[1vw] ' onClick={() => removeFilter(index)}><FaMinusCircle className='text-red-500' /></button>
            )}
          </div>
        ))}
      </div>
      <div className='filter-section-buttons'>
        <button type='button' onClick={addMoreFilters}
    className='  add-more-filters-button hover:bg-blue-100'
    disabled={numFilters > 4}>Add more filters</button>
        <button type='button' onClick={clearFilters} className='apply-button hover:bg-blue-100'>Clear Filters</button>
        <button type='submit' className='apply-button hover:bg-blue-100'>Apply</button>
      </div>
    </form>
  );
}

export default FilterSection;
