import axios from 'axios'; // Import Axios
import { toast } from 'react-toastify';
import { setPermissions } from '../companyid/companyid.action';
import {
  REQUEST_USER_LICENCE_LIST,
  REQUEST_FAILED_USER_LICENCE_LIST,
  RECEIVE_USER_LICENCE_LIST,
} from './userLicence.action_type';

export const fetchUSERLicenceList = (id) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_USER_LICENCE_LIST });

    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/list/user`, {
        "userId": id
      });

      dispatch({ type: RECEIVE_USER_LICENCE_LIST, payload: response.data });
      if (response?.data?.permissions) {
        dispatch(setPermissions(response.data.permissions));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: REQUEST_FAILED_USER_LICENCE_LIST, payload: error });
      toast.error(error?.response?.data || error?.message)
    }
  };
};


