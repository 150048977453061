import React, { startTransition, useEffect, useState, useRef } from 'react'
import Sidebar from '../../common/Sidebar'
// import Loader from '../common/Loader'
import Loader from '../../common/Loader'
import '../../css/index.css'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { IoMdHome } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import FilterSection from '../common/FilterSection'
import { FaCheckDouble, FaFilter, FaInfoCircle, FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setFileId, setCompanyId, setUserId, setCurrentKey } from '../../../Redux/companyid/companyid.action';
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";

import { ImCross } from 'react-icons/im'
import { LuRefreshCcw } from 'react-icons/lu'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import Sidebar1 from '../../common/Sidebar1';
import SidebarAdmin from '../../common/SidebarAdmin';
import { Helmet } from 'react-helmet';
import { checkAuthentication } from '../../../Redux/auth/auth.action'
import { RiComputerLine } from 'react-icons/ri'
import '../../css/license.css'
import PageNumber from '../../components/pagination/PageNumber';
import DataEntriesNo from '../../components/pagination/DataEntriesNo';
import FilterSection from '../../common/FilterSection';
import SidebarLicense from '../../common/SidebarLicense';
import { fetchLinkedDevices, setSortOptions, setFilters, setPageNumber, setPageSize } from '../../../Redux/licence/licenseLinkedDevice.action'; import { checkAdminAuth } from '../../../Redux/adminAuth/adminAuth.action';
import Error from '../../../pages/Error';
import { FaCircleInfo } from 'react-icons/fa6';
import { useSortedData } from '../../../utils/sortUtils';
const LinkedDevices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openShowHide, setOpenShowHide] = useState(false);

  const { userId, linkedDevices,
    pageNumber, totalRecords, sortOrder, sortBy, filters, loading, adminAuthData, errorAdminAuth, errorUserAuth } = useSelector((state) => ({
      //   companyId: state.companyid.companyId,
      userId: state.companyid.userId,
      linkedDevices: state.linkedDevices.linkedDevices,
      sortOrder: state.linkedDevices.sortOrder,
      pageNumber: state.linkedDevices.pageNumber,
      totalRecords: state.linkedDevices.totalRecords,
      sortBy: state.linkedDevices.sortBy,
      sortOrder: state.linkedDevices.sortOrder,
      filters: state.linkedDevices.filters,
      loading: state.linkedDevices.loading,
      adminAuthData: state.adminAuth.adminAuthData,
      permissions: state.companyid.permissions,
      errorAdminAuth: state.adminAuth.errorAdminAuth,
      errorUserAuth: state.userAuth.errorUserAuth

      // productCode: state.linkedDeviceDetail.productCode
      // actionList: state.licence.actionList
    }));

  let newSize;
  const fieldParam = new URLSearchParams(location.search).get('field');
  const operandParam = new URLSearchParams(location.search).get('operand');
  const valueParam = new URLSearchParams(location.search).get('value');
  const excludeParam = new URLSearchParams(location.search).get('exclude');
  const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
  const pageNumberParam = new URLSearchParams(location.search).get('page');
  const sortByParam = new URLSearchParams(location.search).get('sortBy');
  const sortOrderParam = new URLSearchParams(location.search).get('sortOrder');


  // Assuming the values are stored as JSON strings in the URL, parse them
  const field = fieldParam ? JSON.parse(fieldParam) : [];
  const operand = operandParam ? JSON.parse(operandParam) : [];
  const value = valueParam ? JSON.parse(valueParam) : [];
  const exclude = excludeParam ? JSON.parse(excludeParam) : [];
  const pageSize = pageSizeParam ? JSON.parse(pageSizeParam) : [];
  const page = pageNumberParam ? JSON.parse(pageNumberParam) : [];
  const sortByCol = sortByParam ? JSON.parse(sortByParam) : [];
  const sortOrderCol = sortOrderParam ? JSON.parse(sortOrderParam) : [];
  // console.log(sortByCol,sortOrderCol,"clm and order");

  const [activeArrow, setActiveArrow] = useState({
    columnName: sortByCol.length > 0 ? sortByCol[0] : "updated_date", // Extract first element or use default
    direction: sortOrderCol.length > 0 ? sortOrderCol[0] : -1, // Extract first element or use default
  });
  // console.log(activeArrow);

  const [pageSizeFromURL, setPageSizeFromURL] = useState(100);
  const [currPage, setCurrPage] = useState(1);
  const [filterChange, setFilterChange] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const handleFilter = () => {
    setShowFilter(!showFilter);
  }
  let filtersvalue = React.useMemo(() => {
    return {
      filters: {
        field,
        operand,
        value,
        exclude,
      },
    };
  }, [field, operand, value, exclude]);
  const filtersValueRef = React.createRef(filtersvalue);
  const pageSizeRef = React.createRef(pageSize);
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const [updatePage, setUpdatePage] = useState(false);
  useEffect(() => {
    const pageSizeParam = new URLSearchParams(location.search).get('pageSize');
    const parsedSize = pageSizeParam ? parseInt(pageSizeParam) : pageSizeFromURL; // Set default if not provided
    setPageSizeFromURL(parsedSize);
    const pageNumberParam = new URLSearchParams(location.search).get('page');
    const parsedPage = pageNumberParam ? parseInt(pageNumberParam) : currPage;
    setCurrPage(parsedPage);
  }, [location.search]);
  const [currentTime, setCurrentTime] = useState(getCurrentTime()); // Initial state with current time

  // Function to get current time
  function getCurrentTime() {
    const now = new Date();
    return now.toLocaleString();
  }

  // Function to add leading zero if needed
  function addZeroIfNeeded(value) {
    return value < 10 ? '0' + value : value;
  }

  // Function to update current time
  function updateTime() {
    setCurrentTime(getCurrentTime());
  }
  // const location = useLocation();

  // Access the key from the state
  const { key } = location.state || {};
  const [linkedDeviceData, setLinkedDeviceData] = useState(null);
  useEffect(() => {
    const currentURL = window.location.pathname;
    const parts = currentURL.split('/');
    const userId = parts[parts?.length - 1]; // Extract userId from URL
    const fileId = null; // Set fileId to null as intended
    const localProductName = parts[parts?.length - 2];
    // Retrieve data from localStorage
    const queryParams = new URLSearchParams(window.location.search);

    const linkedDeviceData = {
      productCode: queryParams.get('productCode'),
      deviceCount: queryParams.get('deviceCount'),
      productName: queryParams.get('productName'),
      dueDate: queryParams.get('dueDate'),
      LinkedUserId: queryParams.get('LinkedUserId'),
      licenseId: queryParams.get('licenseId'),
      orgId: queryParams.get('orgId'),
    };

    // Check if linkedDeviceData has all required fields
    if (linkedDeviceData.productCode) {
      setLinkedDeviceData(linkedDeviceData); // Store in state
    }

    // dispatch(checkAuthentication())
    // Dispatch actions
    dispatch(setUserId(userId));
    // dispatch(setFileId(fileId));

    if (userId) {
      // console.log(filtersvalue, "filters");

      dispatch(fetchLinkedDevices(
        userId,
        linkedDeviceData.orgId,
        linkedDeviceData.productCode,
        linkedDeviceData.licenseId,
        pageSizeFromURL,
        currPage,
        sortBy,
        sortOrder,
        filtersvalue
      ));
      dispatch(checkAdminAuth(userId))
    }

    // Perform authentication check
    // dispatch(checkAuthentication());

    // Set current time
    setCurrentTime(getCurrentTime());
  }, [

    pageNumber,

    pageSizeFromURL,
    currPage,
    filterChange,
    currentTime,
    updatePage
  ]);

  const columnsLicense = [

    { id: 0, name: "Computer name", value: "computer_name", type: 'string' },

    { id: 1, name: "License linked date", value: 'license_usedate', type: 'date' },
    // { id: 2, name: "Due date (DD/MM/YY)", value: "Due date", type: 'date' },

  ]

  // if (key) {
  //     columnsLicense = columnsLicense.filter(column => column.value !== "key");
  // }

  const [checkedRows, setCheckedRows] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columnsLicense);
  const [checkedColumns, setCheckedColumns] = useState(columnsLicense.map(() => true));
  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    const column = columnsLicense[index];
    // console.log(column, "column");

    setCheckedColumns(prevState => {
      const updatedCheckedColumns = [...prevState];
      updatedCheckedColumns[index] = checked;
      return updatedCheckedColumns;
    });
    setVisibleColumns(prevState => {
      const updatedVisibleColumns = [...prevState];
      // console.log(updatedVisibleColumns, "visibleColumn");
      if (checked) {

        if (!updatedVisibleColumns.find(col => col.id === column.id)) {
          updatedVisibleColumns.push(column);
          updatedVisibleColumns.sort((a, b) => a.id - b.id);
        }
      } else {

        const filteredColumns = updatedVisibleColumns.filter(col => col.id !== column.id);
        // console.log(filteredColumns, "filteredcol");
        return filteredColumns;
      }

      return updatedVisibleColumns;
    });
  };
  const selectAllColumns = () => {
    setCheckedColumns(columnsLicense.map(() => true)); // Mark all columns as checked
    setVisibleColumns(columnsLicense);
  };

  // Function to deselect all columns
  const deselectAllColumns = () => {
    setCheckedColumns(columnsLicense.map(() => false)); // Mark all columns as unchecked
    setVisibleColumns([]); // Hide all columns
  };

  const handleSort = (columnName, newSortOrder) => {
    // Set active arrow state
    setActiveArrow({
      columnName: columnName,
      direction: newSortOrder,
    });

    // Dispatch the sort options (optional, if you're using redux or state management)
    dispatch(setSortOptions(columnName, newSortOrder));

    // Get the current query string and update the sort parameters
    const searchParams = new URLSearchParams(location.search);

    // Update the sortBy and sortOrder parameters
    searchParams.set('sortBy', JSON.stringify([columnName])); // Save the sorted column
    searchParams.set('sortOrder', JSON.stringify([newSortOrder])); // Save the sort order

    // Use navigate to update the URL with the new query parameters
    navigate({
      pathname: location.pathname, // Keep the current pathname
      search: searchParams.toString(), // Apply the updated search parameters
    });
  };

  const handleRemoveLicenseFromLinkedDevice = (installerId) => {
    // console.log(installerId, "installeridddddddddddd");

    axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/remove/link/device`, {
      "userId": linkedDeviceData.userId, "product_code": linkedDeviceData.productCode,
      "licenseId": linkedDeviceData.licenseId, "installerId": installerId
    })
      .then(response => {
        if (response.headers['content-type'].includes('application/json')) {
          // If the response is JSON, parse it and log the data
          // console.log(response.data);
          setShowConfirmationModal(false);
          // toast.success("deleted");
        } else {
          // If the response is not JSON, log the response as text
          setShowConfirmationModal(false);
          // console.log(response.data);

        }
        // setShowDeleteError(response.data);
        toast.success(response.data);
        // console.log(response.data, "delete message");
        // setChangeLicence(!changeLicense);
        setUpdatePage(!updatePage)
      })
      .catch(error => {
        setShowConfirmationModal(false);
        console.error('Error:', error.message);
        // setShowDeleteError(error.message);
        toast.error(error.message);
      });
    // setChangeLicence(!changeLicense);
    // setExpiryDays(0);
  }


  const sortedData = useSortedData(linkedDevices, sortBy, sortOrder);

  const [isSidebarExpanded, setSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setSidebarExpanded(!isSidebarExpanded);

  };
  const handlePageSizeChange = (event) => {
    event.preventDefault();
    newSize = parseInt(event.target.value);
    // console.log(newSize, "newsize");
    // dispatch(setPageSize(newSize))
    const existingParams = new URLSearchParams(location.search);
    existingParams.set('pageSize', JSON.stringify(newSize)); // Update or add page size

    const queryString = existingParams.toString();

    navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

  };

  const [isPercentage, setIsPercentage] = useState(true); // State to track percentage option
  const [maxNegativeCredits, setMaxNegativeCredits] = useState(0); // State for max_negative_credits

  const handleOptionChange = (value) => {
    setIsPercentage(value === 'percentage');
    if (value === 'percentage') {
      const percentageValue = 0.0833 * licenceInfo.total_credits; // Calculate 8.33% of total_credits
      setMaxNegativeCredits(percentageValue.toFixed(2)); // Set to fixed 2 decimal places
    } else {
      setMaxNegativeCredits(licenceInfo.max_negative_credits); // Use current absolute value
    }
  };

  const [licenceId, setLicenceId] = useState('');
  const [addNew, setAddNew] = useState([]);
  const [currentKey, setCurrentKey] = useState('');

  const [editWindow, setEditWindow] = useState(false);

  const handlePageNumberChange = (newPageNumber) => {
    setCurrPage(newPageNumber);
    const existingParams = new URLSearchParams(location.search);
    existingParams.set('page', JSON.stringify(newPageNumber)); // Update or add page number

    const queryString = existingParams.toString();

    navigate(`${window.location.pathname}${queryString ? '?' + queryString : ''}`);

  };
  const handleClick = () => {

  };
  const [dailyRateCriteria, setDailyRateCriteria] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showMoreStates, setShowMoreStates] = useState({});
  // Function to toggle show more/less state for 'details'
  const toggleShowMoreDetails = (rowIndex) => {

    const key = `${rowIndex}-details`;
    setShowMoreStates(prevState => ({
      ...prevState,
      [key]: !prevState[key] // Toggle the show more/less state for the specific row and 'details' value
    }));
  };
  const [expandedStates, setExpandedStates] = useState({});

  // Function to toggle show more/less state for 'details'
  const toggleExpand = (rowIndex, colIndex) => {
    setExpandedStates((prev) => ({
      ...prev,
      [`${rowIndex}-${colIndex}`]: !prev[`${rowIndex}-${colIndex}`],
    }));
  };
  const onApplyFilters = (filters) => {

    // console.log(filters, "filternewVLUE");
    setFilterChange(filters);

  }

  const buttonRef = useRef(null);

  if (loading) return <Loader />;
  if (errorAdminAuth && errorUserAuth) {
    return <Error />;
  }
  return (
    <div className="flex h-screen">
      <Helmet>
        <title>Matisoft | Linked Device</title>
      </Helmet>
      <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25 z-50'} bg-gray-200 h-full fixed `}>
        {/* Sidebar Content Goes Here */}
        {adminAuthData?.role?.includes('ADMINISTRATOR') ? (
          <SidebarLicense isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
        ) : (
          <Sidebar1 isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
        )}

      </div>

      {/* Main Content */}
      <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%] w-[80%]' : 'ml-[4%]'} z-40 relative overflow-x-auto 2xl:font-[30px] licenseCreation`}>
        <div className="fixed top-0 bottom-[76vh] w-full bg-white z-50">
          <div className={`flex flex-row justify-end items-center px-[2%] pt-[1%] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'}`}>

          </div>
          <div className={`flex flex-row items-center justify-between pt-[2vh]  mx-auto  `}>

            <div className='text-[1em] pt-[1vh]  font-[500]'>
              {/* Conditional rendering with highlighted key */}

              <div className={`px-[2vw] flex flex-row items-center  justify-between  py-[1vh]  w-[80vw]`}>

                <div className='text-[1.2em]'>Linked Product - <span className='font-[500] text-blue-600'>{linkedDeviceData?.productName}</span></div>

                <div className='text-[1.2em] font-500 '>
                  Linked Devices - <span className='font-[500] text-blue-600'>{linkedDeviceData?.deviceCount}</span>
                </div>

                <div className='text-[1.2em] font-500 '>
                  Due Date - <span className='font-[500] text-blue-600'>{linkedDeviceData?.dueDate ? new Date(linkedDeviceData?.dueDate).toLocaleString() : ''}</span>

                </div>
              </div>

            </div>
          </div>
          <div className='bg-[#bbe3f55e] text-gray-900 text-[0.9em] py-[1vh] relative w-[3000px] pl-[1%] flex flex-row gap-x-[1%]'>

            <div className=''>
              <div className='cursor-pointer hover:text-blue-500' onClick={() => setOpenShowHide(true)} >Show/Hide</div>
              {openShowHide && <>
                <div
                  className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50 "

                ></div>
                <div className='showhide-section-container z-40'>
                  <div className="modal-body filter-section-content  ">
                    <button className=' absolute right-[-5px] top-[-5px]' onClick={() => setOpenShowHide(!openShowHide)}>
                      <ImCross className='text-white bg-red-600 p-1 text-xl font-[200] rounded-full' />
                    </button>
                    <div className="row">
                      <div className="col-sm-6">
                        {columnsLicense.map((item, index) => (
                          <label className="cont flex flex-row gap-x-[1%] text-white">
                            <input
                              type="checkbox"
                              className="toggle-vis"
                              data-column={index + 1}
                              checked={checkedColumns[index]}
                              defaultChecked
                              onChange={(event) => handleCheckboxChange(event, index)}
                            />
                            <span className="checkmark"></span> <p>{item.name}</p>
                          </label>
                        ))}
                      </div>
                      <div></div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <button className="btn btn-primary apply-button" style={{ marginRight: "10px", marginLeft: "15px" }} onClick={selectAllColumns}>Select All</button>
                      <button className="btn btn-primary apply-button" onClick={deselectAllColumns}>Deselect All</button>
                    </div>
                  </div>
                </div>
              </>}
            </div>
            <div className={` ${showFilter ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full bg-gray-600 opacity-50  `} onClick={handleFilter}></div>
            <div className='flex flex-row items-center hover:text-blue-500' onClick={handleFilter}>
              <span>Filters &nbsp;</span>
              <FaFilter className={` ${filtersvalue?.filters?.field?.length > 0 ? "text-green-500" : "text-yellow-400"} cursor-pointer`} />
              {filtersvalue?.filters?.field.length > 0 && <span>&nbsp;{filtersvalue?.filters?.field?.length} applied</span>}
            </div>
            {showFilter && <FilterSection showFilter={showFilter} setShowFilter={setShowFilter} columns={columnsLicense} initialFilters={filtersvalue} onApplyFilters={onApplyFilters} />}
            <div className='cursor-pointer flex flex-row items-center gap-x-[0.5vw] hover:text-blue-500' onClick={updateTime}>Refresh <LuRefreshCcw /></div>
            <div>{`Last page updated at : ${currentTime}`}</div>
          </div>
          <div className={`flex flex-row justify-between items-center py-[1vh] ${isSidebarExpanded ? 'w-[80vw]' : 'w-[95vw]'} border-b-1 border-b-gray-500`}>
            <DataEntriesNo pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
            <PageNumber
              pageSize={pageSizeFromURL}
              currPage={currPage}
              totalRecords={totalRecords}
              handlePageNumberChange={handlePageNumberChange}
            />
          </div>
        </div>

        {/* Main Content Goes Here */}

        <div className="overflow-auto" style={{ marginTop: '24vh', width: '100%', height: '76vh' }}>
          <table className="w-full ">
            {/* Table Head */}
            <thead className="sticky top-0 bg-blue-200 text-[0.9em] w-full">
              <tr className="text-sm font-semibold text-gray-800">

                {visibleColumns.map((column, index) => (
                  <th key={index} className="py-2 px-3  w-[280px] text-center">
                    <div
                      className="flex items-center justify-center gap-x-1 cursor-pointer"
                      onClick={() => handleSort(column.value, activeArrow.columnName === column.value ? -activeArrow.direction : 1)}
                    >
                      <span>{column.name}</span>
                      <p>
                        <span className='font-thin text-[0.5em] text-blue-400 flex flex-row items-center arrow'>
                          <FaLongArrowAltUp title='Ascending' onClick={() => handleSort(column.value, 1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === 1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] px-0 h-[2vh] cursor-pointer arrow`} />
                          <FaLongArrowAltDown title='Descending' onClick={() => handleSort(column.value, -1)} className={`hover:text-gray-800 ${activeArrow.columnName === column.value && activeArrow.direction === -1 ? 'text-gray-800' : 'text-gray-500'} text-[1.2em] h-[2vh] px-0 h-[1vh]cursor-pointer`} />
                        </span>
                      </p>
                    </div>
                  </th>
                ))}

                <th className="py-2 px-3 min-w-[200px] w-[200px] text-center">Remove license</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="text-sm text-gray-800 border border-b-1 border-b-gray-300">
              {sortedData.length > 0 ? (
                sortedData.map((item, indexVal) => (
                  <tr key={indexVal} className={`${indexVal % 2 === 1 ? 'bg-gray-100' : ''}`}>
                    {/* Action Column */}


                    {/* Dynamic Columns */}

                    {visibleColumns.map((column, colIndex) => (
                      <td
                        key={`${indexVal}-${colIndex}`}
                        className="py-2 px-3  w-[280px] text-center text-[0.9em] break-words"
                      >
                        {(() => {
                          const value = item[column.value];

                          if (column.value === "file_size" && value) {
                            // Convert file size to KB and format it to two decimal places
                            return <span>{(value / 1024).toFixed(2)}</span>;
                          }
                          if (column.value === "instance_count" && value) {
                            // Convert file size to KB and format it to two decimal places
                            return <NavLink className="hover:text-blue-600 text-blue-700 underline"
                              to={`/cms/allinstances/${companyId}/${userId}?uniqueId=${(item.unique_ID)}&tab=5`}
                              target="_blank"
                            >{value}</NavLink>;
                          }
                          if (column.type === "date" && value) {
                            // Format dates using toLocaleString()
                            return <span>{new Date(value).toLocaleString()}</span>;
                          }

                          if (value && typeof value === "string" && value.length > 200) {
                            // Handle long text with "Show More/Show Less"
                            return (
                              <div className="text-container">
                                {expandedStates[`${indexVal}-${colIndex}`] ? (
                                  <>
                                    <span>{value}</span>
                                    <button
                                      className="show-more-btn text-blue-600"
                                      onClick={() => toggleExpand(indexVal, colIndex)}
                                    >
                                      ...Show Less
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <span className="text-truncated">{value.substring(0, 200)}...</span>
                                    <button
                                      className="show-more-btn text-blue-600"
                                      onClick={() => toggleExpand(indexVal, colIndex)}
                                    >
                                      ...Show More
                                    </button>
                                  </>
                                )}
                              </div>
                            );
                          }

                          // Default case for plain text or other values
                          return <span>{value}</span>;
                        })()}
                      </td>

                    ))}

                    <td className='w-[200px] mx-auto text-center'> <IoMdRemoveCircleOutline
                      className={`mx-auto ${showConfirmationModal === indexVal ? 'text-red-600' : ''} hover:text-red-600`}
                      onClick={() => setShowConfirmationModal(indexVal)}

                      aria-disabled={!permissions.some(permission => permission.pagePath === '/cms/userroles/' && permission.permissionLevel === 'read_write')}

                    /></td>
                     {showConfirmationModal === indexVal && (
                          <div className="confirmation-dialog ">
                            <div className="modal border-[1px] border-gray-400   ">
                              <p className='text-[1.1em]'>Are you sure you want to remove license from this device?</p>
                              <div className="modal-buttons gap-x-3 pt-[2vh]">
                                <button className='px-[2vw] gap-x-4 mx-[1vw]' onClick={() => handleRemoveLicenseFromLinkedDevice(item?.installerId)}>Yes</button>
                                <button onClick={() => setShowConfirmationModal(null)}>No</button>
                              </div>
                            </div>
                          </div>
                        )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={visibleColumns.length + 3} className="py-2 pl-2">
                    No matching data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}

export default LinkedDevices;