import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUuidDataFromAPI, setPageSize } from '../../../Redux/uuid/uuid.action';
import PageNumber from './PageNumber';


const DataEntriesNo = ({pageSize,handlePageSizeChange}) => {
 

  return (
    <div className=' w-full'>
      <span className='px-[1%] text-[0.8em]'>Show</span>
      <select value={String(pageSize)}  onChange={handlePageSizeChange} className='bg-blue-200  py-[0.4vh] px-[0.5%] mx-auto dataentry text-gray-900' >

     
{/*        
        <option value={3}>3</option>    
        <option value ={4}>4</option>   */}
        {/* <option value={1}>1</option> */}
        {/* <option value ={4}>4</option>  */}
        <option value={100}>100</option>  
        <option value={250}>250</option>
        <option value={500}>500</option>
        <option value={1000}>1000</option>
      </select><span className='px-[1%]  text-[0.8em]'>Entries</span>
      {/* Render your data here */}
    </div>
  );
};

export default DataEntriesNo;