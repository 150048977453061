

import { useMemo } from 'react';

export const useSortedData = (data, sortBy, sortOrder) => {
    return useMemo(() => {
        if (!Array.isArray(data)) return [];
        return [...data].sort((a, b) => {
            if (!sortBy) return 0;

            const valueA = a[sortBy] ?? '';
            const valueB = b[sortBy] ?? '';
            const isNumber = !isNaN(Number(valueA)) && !isNaN(Number(valueB));
            const isDate = !isNaN(new Date(valueA).getTime()) && !isNaN(new Date(valueB).getTime());

            if (sortOrder === 1) {
                if (isNumber) return Number(valueA) - Number(valueB);
                if (isDate) return new Date(valueA) - new Date(valueB);
                return valueA.toString().localeCompare(valueB.toString());
            } else {
                if (isNumber) return Number(valueB) - Number(valueA);
                if (isDate) return new Date(valueB) - new Date(valueA);
                return valueB.toString().localeCompare(valueA.toString());
            }
        });
    }, [data, sortBy, sortOrder]);
};
