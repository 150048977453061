import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import OtpHandler from './OtpHandler';
import ResetPassword from './ResetPassword';
import Header from '../../Header1';
import Footer from '../../Footer';

const ForgotPasswordNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(0);
    const [otpRequest, setOtpRequest] = useState(false);

    const [step, setStep] = useState(Number(searchParams.get("step")) || 1); // Initialize step from query
    const [email, setEmail] = useState(localStorage.getItem('emailForgot') || ''); // Load email from local storage
    const [isOtpSent, setIsOtpSent] = useState(false);

    useEffect(() => {
        navigate(`?step=${step}`); // Update query param whenever step changes
    }, [step, navigate]);

    const handleOtpVerified = (verifiedEmail) => {
        setEmail(verifiedEmail);
        setStep(3); // Move to reset password step
    };

    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <Header />

            <div className='login_Container h-screen flex items-center justify-center p-4 sm:p-8'>
                <div className='bg-white p-3 rounded-md shadow-md w-full max-w-md sm:max-w-lg md:max-w-md'>
                    {step < 3 ? (
                        <OtpHandler 
                            email={email}
                            setEmail={setEmail}
                            otp={otp}
                            setOtp={setOtp}
                            timer={timer}
                            setTimer={setTimer}
                            otpRequest={otpRequest}
                            setOtpRequest={setOtpRequest}
                            isOtpSent={isOtpSent}
                            setIsOtpSent={setIsOtpSent}
                            onOtpVerified={handleOtpVerified} // Pass handleOtpVerified here
                        />
                    ) : (
                        <ResetPassword email={email} />
                    )}
                </div>
            </div>
            <Footer/>

        </>
    );
};

export default ForgotPasswordNew;
