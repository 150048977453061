import React, { useEffect, useState, useRef } from 'react';
import { GiCheckMark } from "react-icons/gi";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../Header1';
import { Helmet } from 'react-helmet';
import { ImCross } from 'react-icons/im';
import axios from 'axios';
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { toast } from 'react-toastify';
import { antivirusList, dlpList, jobList, companySize, industryList } from '../../utils/ConstantOption';
const ThankYouSign = () => {
    const validProducts = ['Data Leak Prevention(DLP)', 'Endpoint Detection and Response(EDR)', 'Data Leak Prevention(DLP) & Endpoint Detection and Response(EDR)']; // Replace with your valid products

    const location = useLocation();
    const navigate = useNavigate();
    const [showOtherInput, setShowOtherInput] = useState({
        antivirus: false,
        dlp: false,
        jobRole: false,
        industry: false
    });
    // const [showOtherInput1, setShowOtherInput1] = useState(false);
    const { myProp } = location.state || {};
    const [secondSignUp, setSecondSignUp] = useState(false);
    const [checkFirstPart, setCheckFirstPart] = useState(false);
    const [secondPartForm, setSecondPartForm] = useState(false);
    const [products, setProducts] = useState('');
    const [customerType, setCustomerType] = useState('');
    const [secondSignupData, setSecondSignupData] = useState({
        company_size: "",
        industry: "",
        job_title: "",
        _id: '',
        current_antivirus: [],

        current_dlp_provider: [],
        other_antivirus: "",
        other_dlp: "",
        other_industry: "",
        other_jobRole: "",
    })
    const toggleSignUp = () => {
        setSecondSignUp(!secondSignUp);
    }

    // Handle click outside to close the dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (dlpDropdownRef.current && !dlpDropdownRef.current.contains(event.target))
            ) {
                setIsDLPDropdownOpen(false); // Close DLP dropdown

            }
            if ((antivirusDropdownRef.current && !antivirusDropdownRef.current.contains(event.target))) {
                setIsAntivirusDropdownOpen(false); // Close Antivirus dropdown

            }

        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const userId = query.get('userId'); // Extract the userId from the query string
        const firstPartDone = query.get('firstPart');
        setCheckFirstPart(firstPartDone);
        const secondPart = query.get('secondPart');
        const productValue = query.get('Products');
        setProducts(productValue || '');
        if (!validProducts.includes(productValue)) {
            navigate('/error'); // Redirect to an error page
        }
        const customer = query.get('customerType');
        setCustomerType(customer);

        setSecondSignUp(secondPart || false);
        setSecondSignupData((prevState) => ({
            ...prevState,
            _id: userId,  // Set the extracted userId in your state
        }));
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(secondSignupData);

        setSecondSignupData((prevState) => ({
            ...prevState,
            [name]: value, // Dynamically update the fields based on their name
        }));
    };
    const [isDLPDropdownOpen, setIsDLPDropdownOpen] = useState(false);
    const [selectedDLPItems, setSelectedDLPItems] = useState([]);
    const [isAntivirusDropdownOpen, setIsAntivirusDropdownOpen] = useState(false);
    const [selectedAntivirusItems, setSelectedAntivirusItems] = useState([]);
    const handleProviderChange = (e, field) => {
        const selectedValue = e.target.value;
        setSecondSignupData((prevData) => {
            let updatedField = [];

            if (selectedValue === "Others") {
                // If "Others" is selected, set it as the only value in the array
                updatedField = [selectedValue];
            } else {
                // Otherwise, just replace with the selected value
                updatedField = [selectedValue];
            }

            return {
                ...prevData,
                [field]: updatedField, // Update the specific field with the selected value
            };
        });
    };

    // Function to handle checkbox change for DLP
    const handleDLPCheckboxChange = (item) => {
        setSecondSignupData((prevState) => {
            let updatedDLPs;

            if (item === "None") {
                // If "None" is selected, deselect all others
                updatedDLPs = ["None"];
            } else {
                // Remove "None" if any other option is selected
                updatedDLPs = prevState.current_dlp_provider.includes(item)
                    ? prevState.current_dlp_provider.filter((i) => i !== item) // Uncheck the item
                    : [...prevState.current_dlp_provider.filter((i) => i !== "None"), item]; // Add item, remove "None"
            }

            return {
                ...prevState,
                current_dlp_provider: updatedDLPs,
            };
        });
    };

    // Function to handle checkbox change for Antivirus
    const handleAntivirusCheckboxChange = (item) => {
        setSecondSignupData((prevState) => {
            let updatedAntivirus;

            if (item === "None") {
                // If "None" is selected, deselect all other options and only keep "None"
                updatedAntivirus = ["None"];
            } else {
                // If any other option is selected:
                // 1. Remove "None" if it's selected
                // 2. Toggle the current item in the list
                updatedAntivirus = prevState.current_antivirus.includes(item)
                    ? prevState.current_antivirus.filter((i) => i !== item) // Unselect the item
                    : [...prevState.current_antivirus.filter((i) => i !== "None"), item]; // Add the item and remove "None"
            }

            return {
                ...prevState,
                current_antivirus: updatedAntivirus,
            };
        });
    };

    // Refs for dropdown containers
    const dlpDropdownRef = useRef(null);
    const antivirusDropdownRef = useRef(null);

    const [formRequest, setFormRequest] = useState(false);
    const handleSecondPartSignup = (e) => {
        e.preventDefault();

        // Create a payload object dynamically with only the fields that have values
        const payload = {};

        // Company Size
        if (secondSignupData.company_size) {
            payload.company_size = secondSignupData.company_size;
        }

        // Current DLP Provider (Array)
        if (secondSignupData.current_dlp_provider && secondSignupData.current_dlp_provider.length > 0) {
            if (secondSignupData.current_dlp_provider.includes("Others") && secondSignupData.other_dlp !== "") {
                // Replace "Others" with the value from other_dlp
                payload.current_dlp_provider = secondSignupData.current_dlp_provider.map(provider =>
                    provider === "Others" ? secondSignupData.other_dlp : provider
                );
            } else {
                // Store the array directly
                payload.current_dlp_provider = secondSignupData.current_dlp_provider;
            }
        }

        // Industry
        if (secondSignupData.industry) {
            if (secondSignupData.industry === "Others" && secondSignupData.other_industry !== "") {
                payload.industry = secondSignupData.other_industry;
            } else {
                payload.industry = secondSignupData.industry;
            }
        }

        // Job Title
        if (secondSignupData.job_title) {
            if (secondSignupData.job_title === "Others" && secondSignupData.other_jobRole !== "") {
                payload.job_title = secondSignupData.other_jobRole;
            } else {
                payload.job_title = secondSignupData.job_title;
            }
        }

        // Current Antivirus (Array)
        if (secondSignupData.current_antivirus && secondSignupData.current_antivirus.length > 0) {
            if (secondSignupData.current_antivirus.includes("Others") && secondSignupData.other_antivirus !== "") {
                // Replace "Others" with the value from other_antivirus
                payload.current_antivirus = secondSignupData.current_antivirus.map(provider =>
                    provider === "Others" ? secondSignupData.other_antivirus : provider
                );
            } else {
                // Store the array directly
                payload.current_antivirus = secondSignupData.current_antivirus;
            }
        }

        payload._id = secondSignupData._id;
        setFormRequest(true);
        // Log the payload for debugging
        // console.log(payload);
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/register2`, payload)
            .then((response) => {
                // toast.success(response?.data);
                toggleSignUp();

                // Handle the successful response here (e.g., redirect, display a message)
                // Get the current URL parameters
                const query = new URLSearchParams(window.location.search);

                // Add 'secondPart' parameter
                query.set('secondPart', 'true');
                setFormRequest(false);
                // Update the URL with the new query parameter without reloading the page
                navigate({
                    pathname: window.location.pathname,
                    search: query.toString()
                }, { replace: true });
            })
            .catch((err) => {
                console.error('Error during registration:', err);
                toast.error(err?.response?.data || err?.message);
                // Handle the error here (e.g., display an error message)
                setFormRequest(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>Matisoft | ThankYou Signup</title>
            </Helmet>
            <Header />
            <div className='thankYou_container relative' >
                {checkFirstPart && secondSignUp === false && <div className={` ${secondSignUp === false ? 'block' : "hidden"} fixed top-0 left-0 w-full h-full z-[50] bg-gray-600 opacity-50  `} ></div>}
                {checkFirstPart && secondSignUp === false && (
                    <div className='fixed bg-blue-100 opacity-[0.98] border-white-100 border-[1px] rounded w-[80vw] left-[10vw] z-[100] top-[5vh] bottom-[5vh] text-[0.9em] ' style={{ zIndex: '120' }}>
                        <div className='filter-section-content relative z-[100]'>
                            <button className="absolute right-[-5px] top-[-5px]">
                                <ImCross className="text-white bg-red-600 p-1 text-xl font-[200] rounded-full" onClick={toggleSignUp} />
                            </button>
                            <div className='overflow-y-auto'>
                                <h1 className="font-semibold text-blue-900 pt-[2vh] pb-[2vh] text-center border-b border-[#4fd0ff]" >
                                    WANT YOUR TRIAL REQUEST REVIEWED ASAP?
                                </h1>
                                <p className="text-center text-[0.7em]">Please answer the questions below.</p>
                                {customerType === "CHANNEL_PARTNER" ?
                                    <form onSubmit={handleSecondPartSignup} className="h-[70vh] py-[3vh]">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2vw] gap-y-[3vh] text-[0.7em]">
                                            {/* DLP Dropdown */}

                                            {products.includes("DLP") && (
                                                <div ref={dlpDropdownRef}>
                                                    <label className="text-[1.1em]">Which DLP are you selling?</label>
                                                    <div
                                                        className="mt-2 border p-2 rounded bg-white cursor-pointer flex flex-row justify-between items-center"
                                                        onClick={() => setIsDLPDropdownOpen((prev) => !prev)} // Toggle dropdown
                                                    >
                                                        {secondSignupData.current_dlp_provider.length > 0
                                                            ? secondSignupData.current_dlp_provider
                                                                .slice(0, 2) // Get the first two names
                                                                .join(", ") + (secondSignupData.current_dlp_provider.length > 2 ? ", ..." : "") // Add "..." if more than two
                                                            : "Select DLPs..."}

                                                        <MdOutlineKeyboardArrowDown />
                                                    </div>
                                                    <div className="relative">
                                                        {isDLPDropdownOpen && (
                                                            <div className="absolute left-0 top-full w-full border p-2 rounded bg-white shadow-lg max-h-64 overflow-y-auto z-10">
                                                                {dlpList.map((item, index) => (
                                                                    <div key={index} className="flex items-center mb-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`dlp-${index}`}
                                                                            value={item}
                                                                            checked={secondSignupData.current_dlp_provider.includes(item)}
                                                                            onChange={() => handleDLPCheckboxChange(item)} // Update state directly
                                                                            className="mr-2"
                                                                        />
                                                                        <label htmlFor={`dlp-${index}`} className="text-gray-700">
                                                                            {item}
                                                                        </label>
                                                                        {item === "Others" && secondSignupData.current_dlp_provider.includes("Others") && (
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Specify..."
                                                                                name='other_dlp'
                                                                                value={secondSignupData.other_dlp}
                                                                                onChange={handleChange}
                                                                                className="ml-2 border p-1 rounded"
                                                                                maxLength='50'
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Antivirus Dropdown */}
                                            {products.includes("EDR") && (
                                                <div ref={antivirusDropdownRef}>
                                                    <label className="text-[1.1em]">Which antivirus are you selling?</label>
                                                    <div
                                                        className="mt-2 border p-2 rounded bg-white cursor-pointer flex flex-row justify-between items-center"
                                                        onClick={() => setIsAntivirusDropdownOpen((prev) => !prev)} // Toggle Antivirus dropdown
                                                    >
                                                        {secondSignupData.current_antivirus.length > 0
                                                            ? secondSignupData.current_antivirus
                                                                .slice(0, 2) // Get the first two names
                                                                .join(", ") + (secondSignupData.current_antivirus.length > 2 ? ", ..." : "") // Add "..." if more than two
                                                            : "Select Antivirus..."}
                                                        <MdOutlineKeyboardArrowDown />
                                                    </div>
                                                    <div className="relative">
                                                        {isAntivirusDropdownOpen && (
                                                            <div className="absolute left-0 top-full w-full border p-2 rounded bg-white shadow-lg max-h-64 overflow-y-auto z-10">
                                                                {antivirusList.map((item, index) => (
                                                                    <div key={index} className="flex items-center mb-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`antivirus-${index}`}
                                                                            value={item}
                                                                            checked={secondSignupData.current_antivirus.includes(item)}
                                                                            onChange={() => handleAntivirusCheckboxChange(item)} // Update directly in secondSignupData
                                                                            className="mr-2"
                                                                        />
                                                                        <label htmlFor={`antivirus-${index}`} className="text-gray-700">
                                                                            {item}
                                                                        </label>
                                                                        {item === "Others" && secondSignupData.current_antivirus.includes("Others") && (
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Specify..."
                                                                                name="other_antivirus"
                                                                                value={secondSignupData.other_antivirus}
                                                                                onChange={handleChange}
                                                                                className="ml-2 border p-1 rounded"
                                                                                maxLength='50'
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Company Size */}
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-[1.1em]">Company size*</label>
                                                <select
                                                    name="company_size"
                                                    value={secondSignupData.company_size}
                                                    onChange={handleChange}
                                                    className="border p-2 bg-white h-[2.5em]"
                                                    required
                                                >
                                                    <option disabled value="">Select...</option>
                                                    {companySize.map((size, index) => (
                                                        <option key={index} value={size}>
                                                            {size}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="pt-[3vh]">
                                            <button type="submit" className="button-new text-[0.8em] w-[30vh] mx-auto mt-[5vh]">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    :
                                    <form onSubmit={handleSecondPartSignup} className="py-[5vh] max-h-[70vh] overflow-y-auto">
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[2vw] gap-y-[3vh] text-[0.7em]">
                                            {/* Company Size */}
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-[1.1em]">Company size*</label>
                                                <select
                                                    name="company_size"
                                                    value={secondSignupData.company_size}
                                                    onChange={handleChange}
                                                    className="border p-2 rounded bg-white"
                                                    required
                                                >
                                                    <option disabled value="">Select...</option>
                                                    {companySize.map((size, index) => (
                                                        <option key={index} value={size}>
                                                            {size}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Industry Name */}
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-[1.1em]">Industry Name*</label>
                                                <select
                                                    name="industry"
                                                    value={secondSignupData.industry}
                                                    onChange={handleChange}
                                                    required
                                                    className="border p-2 rounded bg-white"
                                                >
                                                    <option disabled value="">Select...</option>
                                                    {industryList.map((list, index) => (
                                                        <option key={index} value={list}>{list}</option>
                                                    ))}

                                                </select>
                                                {/* Check if the selected value is "Others" */}
                                                {secondSignupData.industry === "Others" && (
                                                    <input
                                                        type="text"
                                                        name="other_industry"
                                                        value={secondSignupData.other_industry || ""}
                                                        onChange={handleChange}  // Update state for 'other_antivirus'
                                                        placeholder="Please specify"
                                                        className="mt-1 border p-2 rounded bg-white"
                                                        maxLength='50'
                                                    />
                                                )}
                                            </div>

                                            {/* Antivirus Provider */}
                                            {products.includes("EDR") && (
                                                <div className="flex flex-col">
                                                    <label className="mb-2 text-[1.1em]">Who is your current antivirus provider?*</label>
                                                    <select
                                                        name="current_antivirus"
                                                        onChange={(e) => handleProviderChange(e, "current_antivirus")}
                                                        value={secondSignupData.current_antivirus.length > 0 ? secondSignupData.current_antivirus[0] : ""}
                                                        required
                                                        className="border p-2 rounded bg-white"
                                                    >
                                                        <option disabled value="">Select...</option>
                                                        {antivirusList.map((provider, index) => (
                                                            <option key={index} value={provider}>
                                                                {provider}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    {/* Check if the selected value is "Others" */}
                                                    {secondSignupData.current_antivirus.includes("Others") && (
                                                        <input
                                                            type="text"
                                                            name="other_antivirus"
                                                            value={secondSignupData.other_antivirus || ""}
                                                            onChange={handleChange}  // Update state for 'other_antivirus'
                                                            placeholder="Please specify"
                                                            className="mt-1 border p-2 rounded bg-white"
                                                            maxLength='50'
                                                        />
                                                    )}
                                                </div>
                                            )}



                                            {/* DLP Provider */}
                                            {products.includes("DLP") && (
                                                <div className="flex flex-col">
                                                    <label className="mb-2 text-[1.1em]">Who is your current DLP provider?*</label>
                                                    <select
                                                        name="current_dlp_provider"
                                                        onChange={(e) => handleProviderChange(e, "current_dlp_provider")}
                                                        value={secondSignupData.current_dlp_provider}
                                                        required
                                                        className="border p-2 rounded bg-white"
                                                    >
                                                        <option value="" disabled>Select...</option>
                                                        {dlpList.map((provider, index) => (
                                                            <option key={index} value={provider}>
                                                                {provider}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    {secondSignupData.current_dlp_provider.includes("Others") && (
                                                        <input
                                                            type="text"
                                                            name="other_dlp"
                                                            value={secondSignupData.other_dlp || ""}
                                                            onChange={handleChange}  // Update state for 'other_antivirus'
                                                            placeholder="Please specify"
                                                            className="mt-1 border p-2 rounded bg-white"
                                                            maxLength='50'
                                                        />
                                                    )}
                                                </div>
                                            )}

                                            {/* Job Role */}
                                            <div className="flex flex-col">
                                                <label className="mb-2 text-[1.1em]">Job Role*</label>
                                                <select
                                                    name="job_title"
                                                    value={secondSignupData.job_title}
                                                    onChange={handleChange}
                                                    required
                                                    className="border p-2 rounded bg-white"
                                                >
                                                    <option value="" disabled>Select...</option>
                                                    {jobList.map((job, index) => (
                                                        <option key={index} value={job}>
                                                            {job}
                                                        </option>
                                                    ))}
                                                </select>
                                                {secondSignupData.job_title === "Others" && (
                                                    <input
                                                        type="text"
                                                        name="other_jobRole"
                                                        value={secondSignupData.other_jobRole || ""}
                                                        onChange={handleChange}  // Update state for 'other_antivirus'
                                                        placeholder="Please specify"
                                                        className="mt-1 border p-2 rounded bg-white"
                                                        maxLength='50'
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="pt-[3vh]">
                                            <button type="submit" className="button-new text-[0.8em] w-[30vh] mx-auto mt-[5vh]">
                                                Submit
                                            </button>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                )}

                <div className=''>
                    <div className='text-center w-full h-[60vh] mx-auto bg-gray-200 flex justify-center flex-col thankYou_top'>
                        <h1 className='text-[2em] text-black font-semibold z-10'>Thank You</h1>
                        <p className=' text-black z-10 text-[0.9em]'>If you have any questions, please reach out to us at <a href='/contact' className='underline text-blue-800 hover:text-blue-900'>Contact us</a> </p>
                        <p className='text-[0.9em] font-semibold pt-[5vh] text-primary'>Your request is under review. You will be notified by email once it is approved.</p>
                    </div>
                    <div className='py-8'>
                        <h1 className='text-center text-[1.5em] font-semibold'>Next steps</h1>
                        <div className='flex flex-col md:flex-row justify-between items-center w-5/6 md:w-1/2 mx-auto h-auto md:h-[20vh]'>
                            {myProp === 'trialRequested' ? (
                                <div className='flex justify-center w-full md:w-auto'>
                                    <GiCheckMark className='bg-secondary rounded-full p-2 w-[8vh] h-[8vh]' />
                                </div>
                            ) : (
                                <div className='flex justify-center w-full md:w-auto'>
                                    <img src='/favicon.png' className='w-[8vh] h-[8vh] rounded-full bg-secondary' alt='icon' />
                                </div>
                            )}
                            <div className='w-full md:w-20 h-1 bg-black rounded-md my-4 md:my-0'></div>
                            <div className='flex justify-center w-full md:w-auto'>
                                <img src='/favicon.png' className='w-[8vh] h-[8vh] rounded-full bg-secondary' alt='icon' />
                            </div>
                            <div className='w-full md:w-20 h-1 bg-black rounded-md my-4 md:my-0'></div>
                            <div className='flex justify-center w-full md:w-auto'>
                                <img src='/favicon.png' className='w-[8vh] h-[8vh] rounded-full bg-secondary' alt='icon' />
                            </div>
                            <div className='w-full md:w-20 h-1 bg-black rounded-md my-4 md:my-0'></div>
                            <div className='flex justify-center w-full md:w-auto'>
                                <img src='/favicon.png' className='w-[8vh] h-[8vh] rounded-full bg-secondary' alt='icon' />
                            </div>
                        </div>
                        <div className='h-auto  w-5/6 md:w-5/6 mx-auto flex flex-col md:flex-row justify-between mt-4 py-4'>
                            <div className='shadow-lg shadow-gray-400 border-t-gray-300 border-1 w-full px-[0.5vw] py-[1vh] md:w-[23%] flex flex-col items-center mb-4 md:mb-0'>
                                <h1 className='text-center text-[0.9em] font-semibold py-4 text-[#32A1C9]'>Request Submitted</h1>
                                <div className='w-full flex flex-col items-center'>
                                    <img src='/images/access-card.png' className='w-1/3 py-2' alt='request' />
                                    <p className='text-sm py-[2vh] text-justify text-gray-700 font-normal'>
                                        We have received your request and will review it within 24 hours.                                    </p>
                                </div>
                            </div>
                            <div className="shadow-lg shadow-gray-400 w-full px-[0.5vw] py-[1vh] md:w-[23%] flex flex-col items-center mb-4 md:mb-0">
                                <h1 className="text-center text-[0.9em] font-semibold py-4 text-[#32A1C9]">Pending Approval</h1>
                                <div className="w-full flex flex-col items-center">
                                    <img src="/images/monitoring.png" className="w-1/3 py-2" alt="View detections" />
                                    <p className="text-sm py-[2vh] text-justify text-gray-700 font-normal">
                                        Your request is under review. You will be notified by email once it is approved.                                    </p>
                                </div>
                            </div>

                            <div className="shadow-lg shadow-gray-400 w-full px-[0.5vw] py-[1vh] md:w-[23%] flex flex-col items-center mb-4 md:mb-0">
                                <h1 className="text-center text-[0.9em] font-semibold py-4 text-[#32A1C9]">Finish Setting Up</h1>
                                <div className="w-full flex flex-col items-center">
                                    <img src="/images/start-button.png" className="w-1/3 py-2" alt="Activate account" />
                                    <p className="text-sm py-[2vh] text-justify text-gray-700 font-normal">
                                        Follow the login instructions found in your welcome email. Activate your account and access the Matisoft platform.    </p>                            </div>
                            </div>
                            <div className="shadow-lg shadow-gray-400 w-full px-[0.5vw] py-[1vh] md:w-[23%] flex flex-col items-center mb-4 md:mb-0">
                                <h1 className="text-center text-[0.9em] font-semibold py-4 text-[#32A1C9]">Install the Product(s)</h1>
                                <div className="w-full flex flex-col items-center">
                                    <img src="/images/service.png" className="w-1/3 py-2" alt="Install sensor" />
                                    <p className="text-sm py-[2vh] text-justify text-gray-700 font-normal">
                                        Download the product installer to begin the installation process and start using the software.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThankYouSign;
