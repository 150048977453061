

import axios from "axios";
import { RECEIVE_USER_ROLE, REQUEST_FAILED_USER_ROLE, REQUEST_USER_ROLE, SET_FILTER_USER_ROLE, SET_PAGE_NUMBER_USER_ROLE, SET_PAGE_SIZE_USER_ROLE, SET_SORT_OPTIONS_USER_ROLE } from "./userRoles.action_type";
import { setPermissions } from "../companyid/companyid.action";

export const userRoles = (userId,pageSize,page,sortBy,sortOrder,filtersvalue) => {

  try {
    // console.log(pageSize,'pagesize');
    return async (dispatch) => {
      dispatch({ type: REQUEST_USER_ROLE });

      try {

        const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/subuser/list`, {
         ...filtersvalue,
         "page": page,
         "pageSize": pageSize,
         "sortBy": sortBy,
         "sortOrder": sortOrder,
          userId: userId
        });

        dispatch({ type: RECEIVE_USER_ROLE, payload: response.data });
        if (response?.data?.permissions) {
          dispatch(setPermissions(response.data.permissions));
        }
        // console.log('Data from backend FOR USER_AUTH:', response);
        // Handle the response data
      } catch (error) {
        // console.error('Error fetching data:', error);
        dispatch({ type: REQUEST_FAILED_USER_ROLE, payload: error })
        // Handle errors
      }
    };

  } catch (error) {
    // console.log(error, 'edrfdfdsfdsd');

  }

};
export const setSortOptions = (sortBy, sortOrder) => ({
  type: SET_SORT_OPTIONS_USER_ROLE,
  payload: { sortBy, sortOrder }
});

export const setPageSize = (pageSize) => ({
  type: SET_PAGE_SIZE_USER_ROLE,
  payload: pageSize,
});

export const setPageNumber = (pageNumber) => ({
  type: SET_PAGE_NUMBER_USER_ROLE,
  payload: pageNumber,
});

export const setFilters = (filters) => ({
  type: SET_FILTER_USER_ROLE,
  payload: filters,
});